import useMount from 'hooks/useMount';
import PosInfo from './PosInfo';

const ManualWithdraw = ({
  control,
  watch,
  setValue,
  getInfoProperties,
  paymentMethod,
  changeActionButtonAvailability,
  changeActionButtonVisibility,
  changeAmountFieldVisibility,
}) => {
  useMount(() => {
    changeActionButtonVisibility(true);
    changeAmountFieldVisibility(true);
  });

  if (!watch('amount')) return null;

  return (
    <PosInfo
      watch={watch}
      isDeposit={false}
      control={control}
      setValue={setValue}
      getInfoProperties={getInfoProperties}
      paymentMethod={paymentMethod}
      changeActionButtonAvailability={changeActionButtonAvailability}
    />
  );
};

export default ManualWithdraw;
