import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Account = (props?: SvgIconProps) => (
  <SvgIcon width="100%" height="100%" viewBox="0 0 24 28" {...props}>
    <path d="M2.25261 9.33597C1.32502 9.33597 0.586914 10.0683 0.586914 10.9888V17.8733C0.586914 18.7937 1.32502 19.526 2.25261 19.526C3.18021 19.526 3.91831 18.7937 3.91831 17.8733V10.9888C3.91831 10.0683 3.18021 9.33597 2.25261 9.33597ZM21.6798 9.33597C20.7522 9.33597 20.0141 10.0683 20.0141 10.9888V17.8733C20.0141 18.7937 20.7522 19.526 21.6798 19.526C22.6074 19.526 23.3455 18.7937 23.3455 17.8733V10.9888C23.3455 10.0683 22.6074 9.33597 21.6798 9.33597Z" />
    <path d="M9.19109 17.1924C8.26349 17.1924 7.52539 17.9248 7.52539 18.8452V25.7297C7.52539 26.6501 8.26349 27.3825 9.19109 27.3825C10.1187 27.3825 10.8568 26.6501 10.8568 25.7297V18.8452C10.8568 17.9248 10.1187 17.1924 9.19109 17.1924ZM14.7417 17.1924C13.8141 17.1924 13.076 17.9248 13.076 18.8452V25.7297C13.076 26.6501 13.8141 27.3825 14.7417 27.3825C15.6693 27.3825 16.4074 26.6501 16.4074 25.7297V18.8452C16.4074 17.9248 15.6693 17.1924 14.7417 17.1924Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.24198 0.692371C7.27815 0.672478 7.31484 0.662398 7.35421 0.661999C7.43851 0.661196 7.51841 0.702983 7.56338 0.78349L8.75207 2.90959C9.72447 2.48161 10.8152 2.24138 11.9661 2.24138C13.1171 2.24138 14.2078 2.48161 15.1802 2.90959L16.3689 0.78349C16.4139 0.702983 16.4938 0.661192 16.5781 0.661999C16.6174 0.662371 16.6541 0.672478 16.6903 0.692371C16.8091 0.7577 16.8481 0.893132 16.7821 1.01129L15.6087 3.1146C17.8663 4.28016 19.3911 6.49518 19.3942 9.0373H4.53809C4.54114 6.49518 6.06599 4.28016 8.32353 3.1146L7.15015 1.01129C7.08414 0.893132 7.1232 0.7577 7.24198 0.692371ZM4.53809 20.3031V9.61944H19.3942V20.3031C19.3942 21.2902 18.5933 22.0849 17.5984 22.0849H6.33388C5.33901 22.0849 4.53809 21.2902 4.53809 20.3031ZM7.91793 5.72411C7.91793 5.3876 8.19864 5.10906 8.53778 5.10906C8.87692 5.10906 9.15763 5.3876 9.15763 5.72411C9.15763 6.06062 8.87692 6.33916 8.53778 6.33916C8.19864 6.33916 7.91793 6.06062 7.91793 5.72411ZM14.7746 5.72411C14.7746 5.3876 15.0553 5.10906 15.3944 5.10906C15.7336 5.10906 16.0143 5.3876 16.0143 5.72411C16.0143 6.06062 15.7336 6.33916 15.3944 6.33916C15.0553 6.33916 14.7746 6.06062 14.7746 5.72411Z"
    />
  </SvgIcon>
);

export default Account;
