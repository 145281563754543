import useSettings, { IWebsiteConfigs } from 'store/settings';
import LiveChat from './LiveChat';
import ZendeskChat from './Zendesk';

const Chat = () => {
  const [
    {
      data: { meta = {} as IWebsiteConfigs },
    },
  ] = useSettings();

  if (meta.zendeskKey) return <ZendeskChat secretKey={meta.zendeskKey} />;

  if (meta.liveChatKey) return <LiveChat secretKey={meta.liveChatKey} />;

  return null;
};

export default Chat;
