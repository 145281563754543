import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState, Status } from 'store/types';

import { ICountry, SettingsService } from 'api/settings';

type CountriesStateType = {
  data: ICountry[];
  websiteContent: [];
  status: Status;
};

type CountriesActionsType = {
  read: () => void;
};

const initialState: CountriesStateType = {
  data: [],
  websiteContent: [],
  status: 'idle',
};

const readCountriesApplyThunk = createAsyncThunk('countries/read', async () => SettingsService.getAllCountries());
const readWebsiteContentApplyThunk = createAsyncThunk('website-content/read', async () =>
  SettingsService.websiteContent()
);

const countriesSlice = createSlice({
  initialState,
  name: 'countries',
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(readCountriesApplyThunk.pending.type, (state: CountriesStateType): void => {
        state.status = 'loading';
      })
      .addCase(
        readCountriesApplyThunk.fulfilled.type,
        (state: CountriesStateType, action: PayloadAction<ICountry[]>): void => {
          state.status = 'success';
          state.data = action.payload || initialState.data;
        }
      )
      .addCase(readCountriesApplyThunk.rejected.type, (state: CountriesStateType): void => {
        state.status = 'failed';
        state.data = initialState.data;
      }),
});

const useCountries = (): [CountriesStateType, CountriesActionsType] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.countries);

  const actions = {
    read: () => {
      dispatch(readCountriesApplyThunk());
      dispatch(readWebsiteContentApplyThunk());
    },
  };

  return [state, actions];
};

export { countriesSlice };
export default useCountries;
