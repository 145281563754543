import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SportLive: FC<SvgIconProps> = (props) => (
  <SvgIcon width="100%" height="100%" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" {...props}>
    <g>
      <path d="m256 48c-114.7 0-208 93.3-208 208s93.3 208 208 208 208-93.3 208-208-93.3-208-208-208zm0 384c-97 0-176-79-176-176s79-176 176-176 176 79 176 176-79 176-176 176z" />
      <circle cx={256} cy={256} r={144} />
    </g>
  </SvgIcon>
);

export default SportLive;
