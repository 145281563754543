import _Request from '../Request';

import { ITournament } from './types';

class TournamentsService extends _Request {
  constructor() {
    super('/');
  }

  getTournaments() {
    return this.send<ITournament>({ method: 'get' }, `/tournaments/players`);
  }
}

export * from './types';

export default new TournamentsService();
