import { currencyStorage } from 'store/storage';

export const CURRENCY_SYMBOLS = {
  AMD: '֏',
  USD: '$',
  EUR: '€',
  RUB: '₽',
};
export const CURRENCY_COUNTRY_CODES = {
  AMD: 'hy-AM',
  USD: 'en-US',
  EUR: 'de-DE',
  RUB: 'ru-RU',
};

export const DEFAULT_CURRENCY_CODE = 'USD';

export const priceFormatter = (price?: string | number, code?: string): string => {
  try {
    const currency = code || currencyStorage.get() || DEFAULT_CURRENCY_CODE;

    const amount = Math.floor((Number(price) || 0) * 100) / 100;

    return new Intl.NumberFormat(CURRENCY_COUNTRY_CODES[currency], {
      style: 'currency',
      currency,
    })
      .format(amount)
      .replace(currency, CURRENCY_SYMBOLS[code] || currency);
  } catch (_) {
    return String(price);
  }
};
