import { Grid } from '@mui/material';
import FormInput from 'components/common/Form/Input';
import { useEffect } from 'react';
import useTranslates from 'utils/translate';
import useMount from 'hooks/useMount';

const AutomaticWithdraw = ({
  control,
  watch,
  changeActionButtonAvailability,
  changeActionButtonVisibility,
  changeAmountFieldVisibility,
}) => {
  const { translate } = useTranslates();
  const account = watch('account');

  useEffect(() => {
    changeActionButtonAvailability(!!account);
  }, [account]);

  useMount(() => {
    changeActionButtonVisibility(true);
    changeAmountFieldVisibility(true);
  });

  return (
    <Grid item xs={12}>
      <FormInput label={translate('account_number')} name="account" required control={control} />
    </Grid>
  );
};

export default AutomaticWithdraw;
