import { FC, useState } from 'react';

import { Box } from '@mui/material';

import { ACCEPTED_FILE_TYPES, UPLOAD_URL } from 'config';

import { axiosInstance } from 'api/Request';
import { accessStorage } from 'store/storage';

import useToaster from 'hooks/useToaster';
import useTranslates from 'utils/translate';

const sx = {
  root: {
    position: 'relative',
  },
  over: {
    position: 'absolute',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    '& input[type="file"]': {
      cursor: 'pointer',
      zIndex: 100,
      position: 'absolute',
      top: '-50%',
      left: '-50%',
      width: '200%',
      height: '200%',
      opacity: 0,
    },
  },
};

interface AttachFileProps {
  children?: JSX.Element;
  onChange?: (url: string) => void;
}

const AttachFile: FC<AttachFileProps> = ({ children, onChange }) => {
  const notify = useToaster();
  const { translate } = useTranslates();

  const [value, setValue] = useState();

  const handleChange = (event) => {
    setValue(null);

    const file = event.target.files[0];

    if (ACCEPTED_FILE_TYPES.includes(file.type)) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileName', file.name);

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: accessStorage.get(),
        },
      };

      axiosInstance.post(UPLOAD_URL, formData, config).then((response) => {
        const url = response?.data?.data?.[0];

        if (url) {
          onChange(url);
        }
      });
    } else {
      notify({
        type: 'error',
        message: `${translate('invalid_file_type')} ${ACCEPTED_FILE_TYPES.join(', ')}`,
      });
    }
  };

  return (
    <Box sx={sx.root}>
      <Box sx={sx.over}>
        <input type="file" accept={ACCEPTED_FILE_TYPES.join()} value={value} onChange={handleChange} />
      </Box>
      {children}
    </Box>
  );
};

export default AttachFile;
