import { useSearchParams } from 'react-router-dom';

import useDialog from 'store/dialog';
import { useEffect } from 'react';

const useSearchParamsTriggers = () => {
  const [params] = useSearchParams();
  const [, dialogActions] = useDialog();

  useEffect(() => {
    if (params.get('resetPassword')) {
      if (params.get('token')) {
        dialogActions.set('resetPassword');
      }
      return;
    }
    if (params.get('emailConfirmation')) {
      if (params.get('token')) {
        dialogActions.set('emailConfirmation');
      }
      return;
    }
  }, [params]);
};

export default useSearchParamsTriggers;
