export default {
  image: ({ src }) => ({
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${src})`,
  }),
  removeFileIconButton: {
    width: 32,
    height: 32,
    position: 'absolute',
    right: -10,
    top: -10,
    '&, &:hover': { bgcolor: 'background.default' },
  },
};
