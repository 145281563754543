import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

import FormDatePicker from 'components/common/Form/DatePicker';
import Form from 'components/common/Form/Form';
import FormInput from 'components/common/Form/Input';
import FormPasswordField from 'components/common/Form/Password';
import FormPhoneInput from 'components/common/Form/PhoneInput';
import FormSelect from 'components/common/Form/Select';
import CloseIcon from 'components/common/svg/close';

import useCurrentUser from 'store/currentUser';
import useDialog from 'store/dialog';
import useGlobalSettings from 'store/globalSettings';
import useSelectedLanguage from 'store/language';
import useSettings, { IWebsiteConfigs } from 'store/settings';

import AuthService from 'api/auth/AuthService';

import TelegramLogin from 'components/common/TelegramLogin';
import { signUpSchema, telegramSignupSchema } from 'config/resolvers';
import useDomainSettings from 'store/domains';
import useTranslates from 'utils/translate';
import useSockets from 'hooks/useSockets';
import useAnalytics from 'hooks/useAnalytics';
import useMount from 'hooks/useMount';
import { AnalyticsKey, AnalyticsPageViewEvents } from 'hooks/useAnalytics/enum';

interface SignUpPopupProps {
  open: boolean;
  onClose: () => void;
}

const SignUpPopup: FC<SignUpPopupProps> = ({ open, onClose }) => {
  const socket = useSockets();
  const [telegramUser, setTelegramUser] = useState(null);
  const { translate } = useTranslates();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isSignedAgreement, setIsSignedAgreement] = useState(false);
  const [language] = useSelectedLanguage();
  const [
    {
      data: { meta = {} as IWebsiteConfigs },
    },
  ] = useSettings();
  const [globalSettings] = useGlobalSettings();
  const [, dialogActions] = useDialog();
  const [
    {
      data: { telegramBotName },
    },
  ] = useDomainSettings();
  const [, currentUserActions] = useCurrentUser();
  const { sendEvent } = useAnalytics();
  const { control, reset, handleSubmit } = useForm({
    resolver: signUpSchema({
      playerMinAge: globalSettings?.data?.playerMinAge,
      isPhoneNumberRequired: meta.isPhoneNumberRequired,
    }),
  });

  const { control: telegramControl, handleSubmit: handleTelegramSubmit } = useForm({
    resolver: telegramSignupSchema,
  });

  useEffect(() => {
    reset({});
  }, [open]);

  useMount(() => {
    sendEvent({ [AnalyticsKey.pageView]: AnalyticsPageViewEvents.registrationStart });
  });

  const onSignUp = async (data) => {
    setIsLoading(true);
    try {
      await AuthService.signUp({
        ...data,
        email: data.email.toLowerCase(),
        username: data.username.toLowerCase(),
        birthDate: new Date(data.birthDate).toISOString().split('T')[0],
        languageId: language.id,
        query: searchParams.toString(),
      });

      sendEvent({ [AnalyticsKey.pageView]: AnalyticsPageViewEvents.registrationSuccess });

      if (meta.autoVerifyEmail) {
        const response = await AuthService.signIn({ email: data.email, password: data.password });
        if (response.id) {
          currentUserActions.set(response);
          socket.connect();
        }
        onClose();
      } else {
        dialogActions.set('verifyEmail');
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onSuccess = (user) => {
    setTelegramUser(user);
  };

  const onTelegramSignUp = (data) => {
    setIsLoading(true);
    AuthService.telegramAuth({
      ...telegramUser,
      languageId: language.id,
      currencyId: data.currencyId,
    }).then((res) => {
      setIsLoading(false);
      onClose();
      currentUserActions.set(res);
      socket.connect();
    });
  };

  if (telegramUser) {
    return (
      <Dialog keepMounted open={open} onClose={onClose}>
        <DialogTitle>
          <Typography fontSize={18} fontWeight={600}>
            {translate('last_step')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Form onSubmit={handleTelegramSubmit(onTelegramSignUp)}>
            <Stack gap={2}>
              <FormSelect
                disabled={isLoading}
                control={telegramControl}
                name="currencyId"
                placeholder={translate('currency')}
                options={globalSettings?.data?.currencies?.map((item) => ({
                  id: item.id,
                  name: item.code,
                }))}
              />
              <Button disabled={isLoading} variant="contained" type="submit">
                {translate('sign_up')}
              </Button>
            </Stack>
          </Form>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog keepMounted open={open} onClose={onClose}>
      <DialogTitle>
        <Typography fontSize={18} fontWeight={600}>
          {translate('sign_up')}
        </Typography>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {open && (
          <Form onSubmit={handleSubmit(onSignUp)}>
            <Stack gap={2}>
              <FormInput disabled={isLoading} control={control} name="username" placeholder={translate('username')} />
              <FormInput disabled={isLoading} control={control} name="firstName" placeholder={translate('name')} />
              <FormInput disabled={isLoading} control={control} name="lastName" placeholder={translate('surname')} />
              <FormInput disabled={isLoading} control={control} name="email" placeholder={translate('email_address')} />
              <FormDatePicker disabled={isLoading} control={control} name="birthDate" />
              <FormSelect
                disabled={isLoading}
                control={control}
                name="currencyId"
                placeholder={translate('currency')}
                options={globalSettings?.data?.currencies?.map((item) => ({
                  id: item.id,
                  name: item.code,
                }))}
              />
              <FormPhoneInput name="phoneNumber" disabled={isLoading} control={control} />
              {meta.showSignupPromoCode && (
                <FormInput
                  disabled={isLoading}
                  control={control}
                  name="promoCode"
                  placeholder={translate('promo_code')}
                />
              )}
              <Typography fontSize={12}>{translate('password_must_contain')}</Typography>
              <FormPasswordField disabled={isLoading} control={control} name="password" />
              <FormPasswordField
                disabled={isLoading}
                control={control}
                name="confirm_password"
                label={translate('repeat_password')}
              />
              {meta.showAgreementSignature && (
                <Box textAlign="center">
                  <FormControlLabel
                    control={<Checkbox />}
                    value={isSignedAgreement}
                    onChange={() => setIsSignedAgreement((prev) => !prev)}
                    label={
                      <Typography
                        fontSize={12}
                        fontWeight={600}
                        sx={{ '& > a': { color: 'primary.main', textDecoration: 'underline' } }}
                      >
                        {translate('i_agree_with')}{' '}
                        <Link to="/terms-and-conditions" target="_blank">
                          {translate('terms_conditions')}
                        </Link>
                      </Typography>
                    }
                  />
                </Box>
              )}
              <Button
                disabled={isLoading || (!isSignedAgreement && meta.showAgreementSignature)}
                variant="contained"
                type="submit"
              >
                {translate('sign_up')}
              </Button>
              {!!telegramBotName && <TelegramLogin botName={telegramBotName} onSuccess={onSuccess} />}
              <Box>
                <Typography fontSize={12} textAlign="center" mt={1}>
                  {translate('already_have_an_account')}
                </Typography>
                <Button disabled={isLoading} size="small" fullWidth onClick={() => dialogActions.set('signIn')}>
                  {translate('sign_in')}
                </Button>
              </Box>
            </Stack>
          </Form>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SignUpPopup;
