import SvgIcon from '@mui/material/SvgIcon';

const ChangePassword = (props) => (
  <SvgIcon width="100%" height="100%" viewBox="0 0 512 512" {...props}>
    <g>
      <g>
        <path
          d="M423,211.5h-16.7v-61.2C406.3,67.4,338.9,0,256,0S105.7,67.4,105.7,150.3v61.2H89
			c-27.6,0-50.1,22.5-50.1,50.1v200.3C39,489.5,61.4,512,89,512h334c27.6,0,50.1-22.5,50.1-50.1V261.6
			C473,233.9,450.6,211.5,423,211.5z M272.7,375.4v53.2c0,9.2-7.5,16.7-16.7,16.7c-9.2,0-16.7-7.5-16.7-16.7v-53.2
			c-19.4-6.9-33.4-25.2-33.4-47c0-27.7,22.4-50.1,50.1-50.1s50.1,22.4,50.1,50.1C306.1,350.1,292.1,368.5,272.7,375.4z M339.5,211.5
			h-167v-61.2c0-46,37.4-83.5,83.5-83.5s83.5,37.4,83.5,83.5V211.5L339.5,211.5z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default ChangePassword;
