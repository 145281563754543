import SvgIcon from '@mui/material/SvgIcon';

const Bonus = (props) => (
  <SvgIcon width="100%" height="100%" viewBox="0 0 512 512" {...props}>
    <g>
      <g>
        <path
          className="st0"
          d="M478.6,99.7h-37.3c4.9-7.8,8.2-16.5,9.1-25.7c3-29.5-12.6-56-39.1-67.7C387-4.5,359.7-0.1,340,17.7l-47,42.7
			c-9.2-10-22.3-16.3-37-16.3c-14.6,0-27.8,6.3-37,16.4l-47.1-42.7c-19.7-17.9-47-22.2-71.4-11.5C74.1,18,58.5,44.5,61.5,74
			c0.9,9.2,4.2,18,9.1,25.7H33.4C15,99.7,0,114.7,0,133.1v50.1c0,9.2,7.5,16.7,16.7,16.7h478.6c9.2,0,16.7-7.5,16.7-16.7v-50.1
			C512,114.7,497.1,99.7,478.6,99.7z M205.9,94.2v5.6h-78.5c-20.8,0-37.1-19.3-31.9-41c2.3-9.5,9.2-17.6,18-21.7
			c12.2-5.6,25.7-3.8,36,5.4L206,93.7C205.9,93.8,205.9,94,205.9,94.2z M417.3,69.5c-1.2,17.4-16.9,30.2-34.3,30.2h-76.9v-5.6
			c0-0.2,0-0.4,0-0.6c12.8-11.6,40.5-36.7,55.6-50.4c8.6-7.8,20.5-11.4,31.6-8.1C409.1,39.8,418.5,53.3,417.3,69.5z"
        />
      </g>
    </g>
    <g>
      <g>
        <path className="st0" d="M33.4,233.3v244.9c0,18.4,14.9,33.4,33.4,33.4h155.8V233.3H33.4z" />
      </g>
    </g>
    <g>
      <g>
        <path className="st0" d="M289.4,233.3v278.3h155.8c18.4,0,33.4-14.9,33.4-33.4V233.3H289.4L289.4,233.3z" />
      </g>
    </g>
  </SvgIcon>
);

export default Bonus;
