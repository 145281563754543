import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LiveCasino: FC<SvgIconProps> = (props) => (
  <SvgIcon width="100%" height="100%" enableBackground="new 0 0 300 300" viewBox="0 0 300 300" {...props}>
    <path d="m42.799 71.265c7.719 0 14-6.281 14-14s-6.281-14-14-14-14 6.281-14 14 6.281 14 14 14zm0-20c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.692-6 6-6z" />
    <circle cx="62.799" cy="85.265" r="4.529" />
    <circle cx="158.799" cy="41.265" r="4.529" />
    <path d="m122.623 83.44c.781.781 1.805 1.172 2.828 1.172s2.047-.391 2.828-1.172l4.52-4.52 4.52 4.52c.781.781 1.805 1.172 2.828 1.172s2.047-.391 2.828-1.172c1.562-1.562 1.562-4.094 0-5.656l-4.52-4.52 4.52-4.52c1.562-1.562 1.562-4.094 0-5.656s-4.094-1.562-5.656 0l-4.52 4.52-4.52-4.52c-1.563-1.562-4.094-1.562-5.656 0s-1.562 4.094 0 5.656l4.52 4.52-4.52 4.52c-1.562 1.563-1.562 4.094 0 5.656z" />
    <path d="m160.799 239.265h-24c-2.211 0-4 1.789-4 4s1.789 4 4 4h24c2.211 0 4-1.789 4-4s-1.789-4-4-4z" />
    <path d="m264.303 77.077-55.457-33.035c-6.699-3.98-15.379-1.777-19.367 4.91l-32.354 54.312h-93.017c-8.441 0-15.309 6.867-15.309 15.309v100.691h-16c-2.211 0-4 1.789-4 4v12.734c0 15.035 12.23 27.266 27.266 27.266h185.469c15.035 0 27.266-12.23 27.266-27.266v-12.734c0-2.211-1.789-4-4-4h-12v-93.214c0-.543-.113-1.058-.309-1.529l16.723-28.072c3.987-6.692 1.784-15.38-4.911-19.372zm-3.504 158.922c0 10.625-8.641 19.266-19.266 19.266h-185.468c-10.625 0-19.266-8.641-19.266-19.266v-8.734h224zm-16-16.734h-188v-100.692c0-4.031 3.277-7.309 7.309-7.309h88.252l-15.975 26.816c-3.494 5.861-2.234 13.253 2.631 17.68l-1.326 2.687-16.82 2.445c-1.508.219-2.758 1.273-3.23 2.723-.469 1.445-.078 3.035 1.012 4.098l12.176 11.867-2.875 16.75c-.258 1.5.359 3.016 1.59 3.91 1.23.902 2.871 1.02 4.211.305l15.047-7.906 15.047 7.906c.582.309 1.223.461 1.859.461.828 0 1.656-.258 2.352-.766 1.23-.895 1.848-2.41 1.59-3.91l-2.875-16.75 4.428-4.315 25.553 15.222c2.262 1.344 4.746 1.984 7.203 1.984 4.82 0 9.523-2.465 12.164-6.895l28.68-48.145v81.834zm-86.266-40.407 1.859 10.836-9.734-5.113c-.582-.309-1.219-.461-1.859-.461s-1.277.152-1.859.461l-9.734 5.113 1.859-10.836c.223-1.297-.207-2.621-1.148-3.539l-7.875-7.676 10.879-1.582c1.305-.188 2.43-1.008 3.012-2.188l4.867-9.859 4.867 9.859c.582 1.18 1.707 2 3.012 2.188l10.879 1.582-7.875 7.676c-.942.918-1.372 2.243-1.15 3.539zm103.805-86.504-53.094 89.129c-1.723 2.898-5.48 3.863-8.398 2.129l-23.715-14.127 1.817-1.771c1.09-1.062 1.48-2.652 1.012-4.098-.473-1.449-1.723-2.504-3.23-2.723l-16.82-2.445-7.523-15.242c-1.344-2.734-5.828-2.734-7.172 0l-2.214 4.485c-.128-.299-.334-.554-.415-.875-.402-1.586-.164-3.234.676-4.641l53.094-89.129c1.727-2.895 5.488-3.855 8.398-2.129l55.457 33.035c1.406.836 2.402 2.172 2.805 3.762.4 1.586.162 3.234-.678 4.64z" />
    <path d="m198.848 60.453c1.819 1.084 4.173.487 5.257-1.332s.487-4.173-1.332-5.257-4.173-.488-5.257 1.332-.488 4.173 1.332 5.257z" />
    <path d="m206.262 174.04c-1.82-1.084-4.174-.487-5.257 1.332-1.084 1.819-.488 4.173 1.332 5.257s4.173.488 5.257-1.332c1.084-1.819.487-4.173-1.332-5.257z" />
    <path d="m214.162 94.55c-.945-.562-2.086-.707-3.137-.41-10.277 2.914-27.977 8.574-31.047 13.719-3.832 6.441-1.434 14.965 5.34 18.996 1.353.805 2.792 1.362 4.269 1.702l-3.632 6.095c-1.129 1.898-.508 4.352 1.391 5.484.641.383 1.348.562 2.043.562 1.363 0 2.691-.695 3.441-1.953l3.545-5.95c.913.949 1.944 1.802 3.115 2.501 2.391 1.422 5.008 2.102 7.57 2.102 4.695 0 9.191-2.289 11.676-6.453 3.062-5.148-.387-23.406-2.719-33.828-.237-1.075-.909-2.005-1.855-2.567zm-2.3 32.301c-1.574 2.645-5.281 3.348-8.277 1.57-1.264-.751-2.203-1.854-2.739-3.127l.024-.041c1.129-1.898.508-4.352-1.391-5.484-1.718-1.025-3.841-.552-5.078.942-1.65.43-3.437.197-4.988-.731-2.988-1.777-4.137-5.379-2.664-7.875 1.691-1.934 12.203-5.949 22.352-9.059 2.1 10.402 3.585 21.539 2.761 23.805z" />
  </SvgIcon>
);

export default LiveCasino;
