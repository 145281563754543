import _Request from '../Request';

import { IMessage, IRequest } from './types';

import { userStorage } from 'store/storage';

class MessagesService extends _Request {
  constructor() {
    super('/');
  }

  sendMessages(data) {
    return this.send<IMessage>({ data, method: 'post' }, `/request/${data.id}/message`);
  }

  getMessages() {
    const userId = userStorage.get()?.id;

    return this.send<IRequest[]>({ method: 'get' }, `/requests/?assignee_ids[]=${userId}`);
  }

  createRequest(data: IRequest) {
    return this.send<IRequest>({ data, method: 'post' }, '/request');
  }
}

export * from './types';

export default new MessagesService();
