import { useState } from 'react';

import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ThemeSwitcher from 'components/sections/ThemeSwitcher';

import useSelectedLanguage from 'store/language';
import useLanguagesList from 'store/languagesList';
import LiveChatIcon from 'components/common/icons/LiveChat';
import useChat from 'components/sections/Chat/useChat';

const LanguagesSwitcher = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [languagesList] = useLanguagesList();
  const [selectedLanguage, selectedLanguageActions] = useSelectedLanguage();

  const open = Boolean(anchorEl);

  const lang = languagesList?.data?.find(({ langId }) => langId === selectedLanguage?.id);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!lang) {
    return null;
  }

  return (
    <>
      <Box display="flex" alignItems="center" onClick={handleOpen} sx={{ cursor: 'pointer' }}>
        <IconButton sx={{ mr: 0.5, width: 21, height: 21, overflow: 'hidden', fontSize: 30 }}>
          {lang?.flag?.includes('http') ? <img width={14} src={lang.flag} /> : lang?.flag}
        </IconButton>
        <ExpandMoreIcon fontSize="small" color="primary" />
      </Box>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        {languagesList?.data?.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => {
              selectedLanguageActions.set(item.langId);
              handleClose();
            }}
          >
            <ListItemIcon>
              {item?.flag?.includes('http') ? <img width={14} src={item.flag} /> : item?.flag}
            </ListItemIcon>
            <ListItemText>{item?.native || item?.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const NavigationActions = () => {
  const { openChat } = useChat();

  return (
    <>
      <LiveChatIcon style={{ cursor: 'pointer' }} onClick={openChat} />
      <ThemeSwitcher />
      <LanguagesSwitcher />
    </>
  );
};

export default NavigationActions;
