import { useEffect, useRef, DependencyList, EffectCallback } from 'react';

const useUpdate = (effect: EffectCallback, deps?: DependencyList): void => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      return effect();
    } else {
      didMount.current = true;
    }
  }, deps);
};

export default useUpdate;
