import { FC } from 'react';

import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Stack } from '@mui/material';

import CloseIcon from 'components/common/svg/close';
import WarnIcon from 'components/common/svg/warn';
import useTranslates from 'utils/translate';

interface BalanceExceptionsProps {
  open: boolean;
  onClose: () => void;
}

const BalanceExceptions: FC<BalanceExceptionsProps> = ({ open, onClose }) => {
  const { translate } = useTranslates();

  return (
    <Dialog keepMounted open={open} onClose={() => onClose()}>
      <DialogTitle>
        <Typography fontSize={18} fontWeight={600}>
          {translate('limit_exceeded_title')}
        </Typography>
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" alignItems="center" key={open ? 1 : 2}>
          <WarnIcon width={60} height={60} />
          <Typography mt={1}>{translate('limit_exceeded_message')}</Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default BalanceExceptions;
