import { useContext } from 'react';

import { ToasterContext } from 'components/common/Toaster';

const useToaster = () => {
  const [, notify] = useContext(ToasterContext);
  return notify;
};

export default useToaster;
