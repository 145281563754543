import { FC } from 'react';
import { Control, FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { PaymentMethod } from 'types';
import Deposit from './Deposit';
import Withdraw from './Withdraw';

const Passim: FC<{
  isDeposit: boolean;
  onClose: () => void;
  getValues: () => FieldValues;
  paymentMethod: PaymentMethod;
  control: Control<FieldValues, unknown>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  changeAmountFieldVisibility: (isVisible: boolean) => void;
  changeActionButtonVisibility: (isVisible: boolean) => void;
  changeActionButtonAvailability: (isAvailability: boolean) => void;
}> = ({
  watch,
  control,
  isDeposit,
  paymentMethod,
  changeAmountFieldVisibility,
  changeActionButtonVisibility,
  changeActionButtonAvailability,
}) => {
  if (isDeposit) {
    return (
      <Deposit
        watch={watch}
        paymentMethod={paymentMethod}
        changeAmountFieldVisibility={changeAmountFieldVisibility}
        changeActionButtonVisibility={changeActionButtonVisibility}
      />
    );
  }

  return (
    <Withdraw
      watch={watch}
      control={control}
      changeAmountFieldVisibility={changeAmountFieldVisibility}
      changeActionButtonVisibility={changeActionButtonVisibility}
      changeActionButtonAvailability={changeActionButtonAvailability}
    />
  );
};

export default Passim;
