import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState, Status } from 'store/types';

import MessagesService, { IMessage, IRequest } from 'api/messages';

import { LOGOUT_ACTION } from 'store/constants';
import { userStorage } from 'store/storage';

type MessagesStateType = {
  data: IRequest[];
  status: Status;
};

type MessagesActionsType = {
  read: () => void;
  createRequest: () => void;
  send: (data: IMessage) => Promise<unknown>;
};

const initialState: MessagesStateType = {
  data: [],
  status: 'idle',
};

const createRequestApplyThunk = createAsyncThunk('messages/createRequest', async (data: IRequest) =>
  MessagesService.createRequest(data)
);
const readMessagesApplyThunk = createAsyncThunk('messages/read', async () => MessagesService.getMessages());
const sendMessagesApplyThunk = createAsyncThunk('messages/send', async (data: IMessage) =>
  MessagesService.sendMessages(data)
);

const messagesSlice = createSlice({
  initialState,
  name: 'messages',
  reducers: {
    createRequest: (state) => {
      state.data = produce(state.data, (draft) => {
        draft = [
          {
            id: 0,
            author_id: null,
            websiteId: null,
            website_assignee_id: 0,
            created: new Date().toISOString(),
            priority: 0,
            deleted: false,
            state: 1,
            messages: [],
            author: {},
          },
          ...draft,
        ];

        return draft;
      });
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createRequestApplyThunk.pending.type, (state: MessagesStateType): void => {
        state.status = 'loading';
      })
      .addCase(
        createRequestApplyThunk.fulfilled.type,
        (state: MessagesStateType, action: PayloadAction<IRequest>): void => {
          const { payload } = action;

          state.status = 'success';
          state.data = produce(state.data, (draft) => {
            draft.map((item, index) => {
              if (!item.id) {
                draft[index] = payload;
              }
              return item;
            });

            return draft;
          });
        }
      )
      .addCase(createRequestApplyThunk.rejected.type, (state: MessagesStateType): void => {
        state.status = 'failed';
      })
      .addCase(
        sendMessagesApplyThunk.fulfilled.type,
        (state: MessagesStateType, action: PayloadAction<IMessage>): void => {
          const { payload } = action;

          state.status = 'success';
          state.data = produce(state.data, (draft) => {
            draft.map((item, index) => {
              if (item.id === payload.request_id) {
                draft[index] = {
                  ...item,
                  messages: [payload, ...item.messages],
                };
              }
              return item;
            });

            return draft;
          });
        }
      )
      .addCase(readMessagesApplyThunk.pending.type, (state: MessagesStateType): void => {
        state.status = 'loading';
      })
      .addCase(
        readMessagesApplyThunk.fulfilled.type,
        (state: MessagesStateType, action: PayloadAction<IRequest[]>): void => {
          state.status = 'success';
          state.data = action.payload || initialState.data;
        }
      )
      .addCase(readMessagesApplyThunk.rejected.type, (state: MessagesStateType): void => {
        state.status = 'failed';
        state.data = initialState.data;
      })
      .addCase(LOGOUT_ACTION, (): MessagesStateType => initialState),
});

const useMessages = (): [MessagesStateType, MessagesActionsType] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.messages);

  const actions = {
    read: () => {
      dispatch(readMessagesApplyThunk());
    },
    send: (data) => {
      const user = userStorage.get();

      if (data.id) {
        return dispatch(sendMessagesApplyThunk(data));
      } else {
        return dispatch(
          createRequestApplyThunk({
            assignee_id: [user?.id],
            ...data,
          })
        );
      }
    },
    createRequest: () => {
      dispatch(messagesSlice.actions.createRequest());
    },
  };

  return [state, actions];
};

export { messagesSlice };
export default useMessages;
