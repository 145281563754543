import { CreditCardType } from '../types';

function hasEnoughResultsToDetermineBestMatch(results: CreditCardType[]): boolean {
  const numberOfResultsWithMaxStrengthProperty = results.filter((result) => result.matchStrength).length;

  return numberOfResultsWithMaxStrengthProperty > 0 && numberOfResultsWithMaxStrengthProperty === results.length;
}

export function findBestMatch(results: CreditCardType[]): CreditCardType | null {
  if (!hasEnoughResultsToDetermineBestMatch(results)) {
    return null;
  }

  return results.reduce((bestMatch, result) => {
    if (!bestMatch) {
      return result;
    }

    if (Number(bestMatch.matchStrength) < Number(result.matchStrength)) {
      return result;
    }

    return bestMatch;
  });
}
