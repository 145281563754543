import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const Affiliate: FC<SvgIconProps> = (props) => (
  <SvgIcon width="100%" height="100%" viewBox="0 0 64 64" {...props}>
    <g>
      <path d="M13,36h2v5a1,1,0,0,1-2,0ZM9,30a4.00458,4.00458,0,0,0,4,4h2V26H13A4.00458,4.00458,0,0,0,9,30Zm8,4.04175a14.65061,14.65061,0,0,1,8,2.676V23.28271a14.65433,14.65433,0,0,1-8,2.67554ZM42,32A20,20,0,1,1,22,12,20.0226,20.0226,0,0,1,42,32Zm-8.89453-5.55273a1.00046,1.00046,0,0,0,1.3418.44726l2-1a1.00012,1.00012,0,0,0-.89454-1.78906l-2,1A1,1,0,0,0,33.10547,26.44727ZM31,30a3.00328,3.00328,0,0,0-3-3H27V21a1.00134,1.00134,0,0,0-1.79688-.60449C25.09033,20.543,22.36475,24,16,24H13a5.996,5.996,0,0,0-2,11.65V41a3,3,0,0,0,6,0V36.03375c5.64819.33618,8.09631,3.431,8.2002,3.56586A.9994.9994,0,0,0,26,40a.98729.98729,0,0,0,.31641-.05176A.9989.9989,0,0,0,27,39V33h1A3.00328,3.00328,0,0,0,31,30Zm2,0a1,1,0,0,0,1,1h2a1,1,0,0,0,0-2H34A1,1,0,0,0,33,30Zm3.44727,4.10547-2-1a1.00012,1.00012,0,1,0-.89454,1.78906l2,1a1.00012,1.00012,0,0,0,.89454-1.78906ZM28,29H27v2h1a1,1,0,0,0,0-2Zm15.97,2c.02.33.03.66.03,1,0,.34-.01.67-.03,1h2.09a8.36333,8.36333,0,0,1,0-2ZM41.1,15.79a8.961,8.961,0,0,1-1.35-1.47l-2.26,2.07a18.763,18.763,0,0,1,1.35,1.48Zm0,32.42-2.26-2.08a18.763,18.763,0,0,1-1.35,1.48l2.25,2.08A9.086,9.086,0,0,1,41.1,48.21ZM47,16a7,7,0,1,0-7-7A7,7,0,0,0,47,16Zm8,9a7,7,0,1,0,7,7A7,7,0,0,0,55,25ZM47,48a7,7,0,1,0,7,7A7,7,0,0,0,47,48Z" />
    </g>
  </SvgIcon>
);

export default Affiliate;
