import { FC, useEffect } from 'react';

import { Box, Dialog, CircularProgress, DialogTitle, IconButton, Typography } from '@mui/material';

import CloseIcon from 'components/common/svg/close';

import useMessages from 'store/messages';
import useTranslates from 'utils/translate';

import Messenger from './Messenger';

interface RequestsDialogProps {
  open: boolean;
  onClose: () => void;
}

const RequestsDialog: FC<RequestsDialogProps> = ({ open, onClose }) => {
  const [requests, requestsActions] = useMessages();
  const { translate } = useTranslates();

  useEffect(() => {
    requestsActions.read();
  }, []);

  return (
    <Dialog
      keepMounted
      open={open}
      onClose={() => onClose()}
      PaperProps={{
        sx: { maxWidth: 800 },
      }}
    >
      <DialogTitle>
        <Typography fontSize={18} fontWeight={600}>
          {translate('messages')}
        </Typography>
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {requests?.status === 'loading' && !requests?.data?.length ? (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="300px">
          <CircularProgress />
        </Box>
      ) : (
        <Messenger requests={requests?.data} />
      )}
    </Dialog>
  );
};

export default RequestsDialog;
