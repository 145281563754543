import isNumber from 'lodash/isNumber';
import { io } from 'socket.io-client';

import { SOCKET_BASE_URL } from 'config';

import { accessStorage } from 'store/storage';

import useBonusBalance from 'store/bonusBalance';
import useRealBalance from 'store/realBalance';
import useDialog from '../store/dialog';
import { USER_LOGOUT } from '../utils/logoutListener';

const _socket = {
  current: null,
  tokenExists: false,
};

window.addEventListener(USER_LOGOUT, () => {
  if (_socket.current) {
    _socket.current.disconnect();
  }
});

export enum SocketPlayerRooms {
  wallet = 'wallet',
  casino = 'casino',
  notification = 'notification',
}

const useSockets = () => {
  const [, realBalanceActions] = useRealBalance();
  const [, bonusBalanceActions] = useBonusBalance();
  const [, dialogActions] = useDialog();

  const updateBalance = (data: { balance: number; amountToWager: number }) => {
    realBalanceActions.updateRealBalance(data);
  };

  const updateBonusBalance = (balance: number) => {
    bonusBalanceActions.updateBonusBalance(balance);
  };

  const connect = () => {
    const token = accessStorage.get();

    const tokenExists = !!token;
    if (!tokenExists) {
      _socket.current?.disconnect();
      _socket.current = null;
      return;
    }
    _socket.current =
      _socket.current ||
      io(SOCKET_BASE_URL, {
        transports: ['websocket'],
        query: {
          token,
          type: 'player',
        },
      });

    _socket.current.on(SocketPlayerRooms.wallet, (data) => {
      if (isNumber(data.balance)) {
        updateBalance(data);
        return;
      }
      if (isNumber(data.bonusBalance)) {
        updateBonusBalance(data.bonusBalance);
        return;
      }
    });

    _socket.current.on(SocketPlayerRooms.casino, (data) => {
      if (data.exception) {
        dialogActions.set('balanceExceptions');
        return;
      }
    });

    _socket.current.on('disconnect', () => {
      _socket.current = null;
      connect();
    });
    _socket.current.on('close', () => {
      _socket.current = null;
      connect();
    });
  };

  return {
    connect,
  };
};

export default useSockets;
