import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Box, Dialog, CircularProgress, DialogTitle, DialogContent, IconButton, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import DoneIcon from 'components/common/svg/Done';
import CloseIcon from 'components/common/svg/close';

import { SettingsService } from 'api/settings';

import useTranslates from 'utils/translate';

interface EmailVerificationProps {
  open: boolean;
  onClose: () => void;
}

const EmailVerification: FC<EmailVerificationProps> = ({ open, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccesses, setIsSuccesses] = useState(false);

  const navigation = useNavigate();
  const [params] = useSearchParams();

  const { translate } = useTranslates();

  const hash = params.get('token');

  const handleClose = () => {
    navigation('/');
    onClose();
  };

  useEffect(() => {
    if (hash) {
      setIsLoading(true);
      SettingsService.emailConfirmation({
        hash,
      })
        .then((res) => {
          if (res.response?.status !== 400) {
            setIsSuccesses(true);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [hash]);

  return (
    <Dialog keepMounted open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography fontSize={18} fontWeight={600}>
          {translate('email_verification')}
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box width="100%" height="100px" display="flex" alignItems="center" justifyContent="center" mb={2}>
          {isLoading ? (
            <CircularProgress size="51px" />
          ) : isSuccesses ? (
            <DoneIcon />
          ) : (
            <ErrorOutlineIcon sx={{ fontSize: '51px', color: 'error.main' }} />
          )}
        </Box>
        <Typography textAlign="center">
          {translate(
            isLoading
              ? 'email_verification_text_loading'
              : isSuccesses
              ? 'email_verification_text_verify'
              : 'email_verification_text_error'
          )}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default EmailVerification;
