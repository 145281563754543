import { Box, ListItemText, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import useTranslates from 'utils/translate';

interface MenuItemWithoutLinkProps {
  children?: ReactNode;
  icon?: JSX.Element | string;
  onClick?: () => void;
}

interface MenuItemWithoutBoxProps {
  selected?: boolean;
  icon?: JSX.Element | string;
  title: string;
  onClick: () => void;
}

interface MenuItemProps extends MenuItemWithoutLinkProps {
  to: string;
}

const MenuItemWithoutLink: FC<MenuItemWithoutLinkProps> = ({ children, icon, onClick }) => (
  <Box
    sx={{
      width: '100%',
      borderRadius: '6px',
      padding: '8.5px 14px',
      bgcolor: 'secondary.light',
      display: 'flex',
      alignItems: 'center',
      gap: 2,
    }}
    onClick={onClick}
  >
    {typeof icon === 'string' ? <img width="24px" src={icon} /> : icon}
    <ListItemText>{children}</ListItemText>
  </Box>
);

const sx = {
  navButton:
    ({ selected }) =>
    ({ palette }) => ({
      maxWidth: '18vw',
      minWidth: '18vw',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'column',
      position: 'relative',
      textAlign: 'center',
      color: selected ? palette.primary.main : palette.text.primary,
      '& svg': {
        flex: 1,
        fill: selected ? palette.primary.main : palette.text.primary,
      },
      '& p': {
        mt: 0.5,
        fontSize: 12,
        fontWeight: 600,
      },
    }),
};

const MenuItemWithoutBox: FC<MenuItemWithoutBoxProps> = ({ selected, icon, onClick, title }) => {
  const { translate } = useTranslates();
  return (
    <Box sx={sx.navButton({ selected })} onClick={onClick}>
      {typeof icon === 'string' ? <img src={icon} width="24px" alt={title} /> : icon}
      <Typography>{translate(title)}</Typography>
    </Box>
  );
};

const MenuItem: FC<MenuItemProps> = ({ onClick, to, children, icon }) => (
  <Link to={to} onClick={onClick} style={{ width: '100%' }}>
    <MenuItemWithoutLink icon={icon}>{children}</MenuItemWithoutLink>
  </Link>
);

export { MenuItemWithoutBox, MenuItemWithoutLink };

export default MenuItem;
