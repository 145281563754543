import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState, Status } from 'store/types';

import { RealBalanceService } from 'api/realBalance';
import { LOGOUT_ACTION } from 'store/constants';

type RealBalanceType = {
  status: Status;
  amount: number;
  withdrawableAmount: number;
};

type BalanceUpdateType = {
  balance: number;
  amountToWager: number;
};

type RealBalanceActionsType = {
  read: () => void;
  updateRealBalance: (params: BalanceUpdateType) => void;
};

const initialState: RealBalanceType = {
  amount: 0,
  withdrawableAmount: 0,
  status: 'idle',
};

const readRealBalanceThunk = createAsyncThunk('real-balance/read', async () => RealBalanceService.getRealBalance());

const realBalanceSlice = createSlice({
  initialState,
  name: 'realBalance',
  reducers: {
    updateRealBalance: (state, action) => {
      state.amount = action.payload.balance;
      state.withdrawableAmount = action.payload.balance - action.payload.amountToWager;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(readRealBalanceThunk.pending.type, (state: RealBalanceType): void => {
        state.status = 'loading';
      })
      .addCase(
        readRealBalanceThunk.fulfilled.type,
        (state: RealBalanceType, action: PayloadAction<BalanceUpdateType>): void => {
          state.status = 'success';
          state.amount = action.payload.balance;
          state.withdrawableAmount = action.payload.balance - action.payload.amountToWager;
        }
      )
      .addCase(readRealBalanceThunk.rejected.type, (state: RealBalanceType): void => {
        state.status = 'failed';
        state.amount = initialState.amount;
      })
      .addCase(LOGOUT_ACTION, (): RealBalanceType => initialState),
});

const useRealBalance = (): [RealBalanceType, RealBalanceActionsType] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.realBalance);

  const actions = {
    read: () => {
      dispatch(readRealBalanceThunk());
    },
    updateRealBalance: (params: BalanceUpdateType) => {
      dispatch(realBalanceSlice.actions.updateRealBalance(params));
    },
  };

  return [state, actions];
};

export { realBalanceSlice };
export default useRealBalance;
