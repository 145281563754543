export const isDev = process.env.NODE_ENV === 'development';

const domain = window.location.host.split('.').slice(-2).join('.');
export const API_BASE_URL = process.env.REACT_APP_API_URL?.replace('{domain}', domain) || '';
export const UPLOAD_URL = `${API_BASE_URL}player/upload`;
export const SOCKET_BASE_URL = process.env.REACT_APP_WS_URL?.replace('{domain}', domain) || '';

export const PAGE_MIN_WIDTH = 1280;
export const PAGE_MAX_WIDTH = 1920;
export const PAGE_PADDING = 30;
export const HEADER_HEIGHT = 72;
export const MENU_HEIGHT = 63;
export const FOOTER_HEIGHT = 45;

export const DEFAULT_LANGUAGE_ID = 1;

export const rowsPerPageOptions = [20, 50, 100];

export enum GAMES_TYPES_IDS {
  casino = 6,
  live_casino = 7,
  tv_games = 8,
  table_games = 9,
  turbo_games = 10,
  virtual_sport = 11,
  lotto = 12,
  skill_games = 13,
}

export enum BANNERS {
  home = 'HOMEPAGE',
  casino = 'CASINO',
  live_casino = 'LIVE_CASINO',
  tv_games = 'TV_GAMES',
  table_games = 'TABLE_GAMES',
  turbo_games = 'TURBO_GAMES',
  virtual_sport = 'VIRTUAL_SPORT',
  lotto = 'LOTTO',
  skill_games = 'SKILL_GAMES',
}

export enum MOBILE_BANNERS {
  home = 'MOBILE_HOMEPAGE',
  casino = 'MOBILE_CASINO',
  live_casino = 'MOBILE_LIVE_CASINO',
  tv_games = 'MOBILE_TV_GAMES',
  table_games = 'MOBILE_TABLE_GAMES',
  turbo_games = 'MOBILE_TURBO_GAMES',
  virtual_sport = 'MOBILE_VIRTUAL_SPORT',
  lotto = 'MOBILE_LOTTO',
  skill_games = 'MOBILE_SKILL_GAMES',
}

export const NAVIGATION_GAMES_ROUTES = [
  {
    path: '/games/casino',
    name: 'Casino',
  },
  {
    path: '/games/live_casino',
    name: 'Live Casino',
  },
  {
    path: '/games/tv_games',
    name: 'TV Games',
  },
  {
    path: '/games/table_games',
    name: 'Table Games',
  },
  {
    path: '/games/turbo_games',
    name: 'Turbo Games',
  },
  {
    path: '/games/virtual_sport',
    name: 'Virtual Sport',
  },
  {
    path: '/games/lotto',
    name: 'Lotto',
  },
  {
    path: '/games/skill_games',
    name: 'Skill Games',
  },
];

export const NAVIGATION_ROUTES = [
  {
    path: '/',
    name: 'home',
  },
  {
    path: '/games',
    name: 'games',
  },
  ...NAVIGATION_GAMES_ROUTES,
];

export const API_STATUS_CODES = {
  UNAUTHORIZED: 401 as const,
};

export const DATE_PICKER = {
  FORMAT: 'yyyy.MM.dd',
};

export const DATE_TIME_PICKER = {
  FORMAT: 'yyyy.MM.dd HH:mm',
};

export const ACCEPTED_FILE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

export enum AvailableBonusStatuses {
  notStarted = 'not-started',
  inProgress = 'in-progress',
  completed = 'completed',
  expired = 'expired',
}
export const availableBonusStatuses = [
  {
    id: 'completed',
    name: 'completed',
  },
  {
    id: 'in-progress',
    name: 'in_progress',
  },
  {
    id: 'not-started',
    name: 'not_started',
  },
  {
    id: 'expired',
    name: 'expired',
  },
];

export const awardType = [
  {
    id: 'money',
    name: 'money',
  },
  {
    id: 'freebet',
    name: 'freebet',
  },
  {
    id: 'freespin',
    name: 'freespin',
  },
];

export const casinoBetStatus = [
  {
    id: 1,
    name: 'active_status',
    color: 'text.primary',
  },
  {
    id: 2,
    name: 'won_status',
    color: 'success.main',
  },
  {
    id: 3,
    name: 'lost_status',
    color: 'error.main',
  },
  {
    id: 4,
    name: 'bet_reverse_status',
    color: 'warning.main',
  },
];

export const GAME_RATIO = {
  1: '16:9',
  2: '4:3',
};

export const GAME_RATIO_MOBILE = {
  1: '9:16',
  2: '3:4',
};

export const PAGE_META_KEYS = {
  '/games/casino': 'CASINO',
  '/games/live_casino': 'LIVE_CASINO',
  '/games/table_games': 'TABLE_GAMES',
  '/games/turbo_games': 'TURBO_GAMES',
  '/games/tv_games': 'TV_GAMES',
  '/games/virtual_sport': 'VIRTUAL_SPORT',
  '/games/lotto': 'LOTTO',
  '/games/skill_games': 'SKILL_GAMES',
  '/games/scratch_cards': 'SCRATCH_CARDS',
};

export const DEFAULT_META_KEY = 'HOMEPAGE';
export const APPLICATION_NAME = 'Mtrybet';

export const TRANSACTION_TYPES_NAMES = {
  1: 'BET',
  2: 'WON',
  3: 'DEPOSIT',
  4: 'REFUND',
  5: 'ROLLBACK',
  6: 'WITHDRAW',
  7: 'BALANCE_ADJUSTMENT',
};

export const TRANSACTION_TYPES_OPTIONS = [
  {
    id: 3,
    name: 'DEPOSIT',
  },
  {
    id: 6,
    name: 'WITHDRAW',
  },
  {
    id: 7,
    name: 'BALANCE_ADJUSTMENT',
  },
];

export const TRANSACTION_STATUSES_NAMES = {
  1: 'SUCCESS',
  2: 'PENDING',
  3: 'FAILED',
  4: 'IN_USER_REVIEW',
  5: 'CANCELLED_BY_USER',
  6: 'CANCELLED_BY_PLAYER',
};

export const TRANSACTION_STATUSES_OPTIONS = Object.entries(TRANSACTION_STATUSES_NAMES).map(([id, name]) => ({
  id,
  name,
}));

export const RTL_DIRECTION_LANGUAGES = isDev ? [99, 2, 7] : [99, 2];

export enum DirectionEnum {
  ltr = 'ltr',
  rtl = 'rtl',
}
