import { useReducer } from 'react';
import { Control, useController } from 'react-hook-form';

import { TextField, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import useTranslates from 'utils/translate';
import useScreen from 'hooks/useScreen';

interface PasswordFieldProps {
  name?: string;
  label?: string;
  error?: boolean;
  control: Control;
  maxLength?: number;
  disabled?: boolean;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
}

const PasswordField = ({
  size,
  control,
  disabled,
  fullWidth,
  maxLength,
  error: invalid,
  name = 'password',
  label = 'password',
}: PasswordFieldProps) => {
  const { isMobile } = useScreen();
  const { translate } = useTranslates();

  const {
    fieldState: { error },
    field: { ref, value, onChange },
  } = useController({
    name,
    control,
  });
  const [showPassword, toggleShowPassword] = useReducer((p) => !p, false);

  return (
    <>
      <TextField
        required
        ref={(el) => {
          ref(el);
        }}
        size={size}
        name={name}
        value={value}
        disabled={disabled}
        onChange={(event) => {
          if (maxLength && event.target.value.length > maxLength) {
            return;
          }
          onChange(event);
        }}
        fullWidth={fullWidth}
        error={invalid || !!error}
        type={showPassword ? 'text' : 'password'}
        placeholder={translate(label)}
        helperText={translate(error?.message)}
        inputProps={{
          autoComplete: 'new-password',
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size={isMobile ? 'small' : 'medium'}
                edge="end"
                tabIndex={-1}
                aria-label="toggle password visibility"
                onClick={toggleShowPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default PasswordField;
