import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Stack, Button } from '@mui/material';

import CloseIcon from 'components/common/svg/close';
import Form from 'components/common/Form/Form';
import FormPasswordField from 'components/common/Form/Password';

import useToaster from 'hooks/useToaster';
import useTranslates from 'utils/translate';
import { changePasswordResolver } from 'config/resolvers';
import { SettingsService } from 'api/settings';
import useCurrentUser from 'store/currentUser';

interface ChangePasswordProps {
  open: boolean;
  onClose: () => void;
}

const ChangePassword: FC<ChangePasswordProps> = ({ open, onClose }) => {
  const { translate } = useTranslates();
  const [currentUser] = useCurrentUser();

  const notify = useToaster();
  const { control, reset, handleSubmit } = useForm({
    resolver: changePasswordResolver,
  });

  useEffect(() => {
    reset({});
  }, [open]);

  const onUpdatePassword = (data) => {
    SettingsService.changePassword(data, currentUser.data.id).then(() => {
      onClose();
      notify({
        message: translate('password_successfully_changed'),
      });
    });
  };

  return (
    <Dialog keepMounted open={open} onClose={() => onClose()}>
      <DialogTitle>
        <Typography fontSize={18} fontWeight={600}>
          {translate('change_password')}
        </Typography>
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit(onUpdatePassword)}>
          <Stack gap={2} mb={3} key={open ? 1 : 2}>
            <FormPasswordField control={control} name="oldPassword" label={translate('enter_old_password')} />
            <FormPasswordField control={control} name="newPassword" label={translate('enter_new_password')} />
            <FormPasswordField control={control} name="confirmPassword" label={translate('confirm_password')} />
          </Stack>
          <Button fullWidth variant="contained" type="submit">
            {translate('save_changes')}
          </Button>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePassword;
