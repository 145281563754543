import _Request from '../Request';

class RealBalanceService extends _Request {
  constructor() {
    super('/wallet');
  }

  getRealBalance() {
    return this.send({ method: 'get' });
  }
}

export default new RealBalanceService();
