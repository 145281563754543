import { FC } from 'react';
import dayjs from 'dayjs';

import { Box, Typography } from '@mui/material';

import { IMessage } from 'api/messages';
import { CurrentUserType } from 'store/currentUser';

import sx from './styles';

interface MessageProps {
  message: IMessage;
  user: CurrentUserType;
}

const Message: FC<MessageProps> = ({ user, message }) => {
  const isMy = message?.author_id === user.id;

  return (
    <Box sx={sx.messageRoot({ isMy })}>
      <Box maxWidth="50%">
        <Typography fontSize={14} fontWeight={400} sx={{ opacity: 0.5 }}>
          {dayjs(message.created).format('DD/MMM hh:mm')}
        </Typography>
        <Box sx={sx.messageItem({ isMy })}>
          <Typography fontSize={16} lineHeight="20px" fontWeight={400}>
            {message.body}
          </Typography>
        </Box>
        {message?.attachments?.[0] && (
          <Box sx={{ mt: 2 }}>
            <img width="100%" style={{ borderRadius: '10px' }} src={message.attachments[0]} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Message;
