import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Notification: FC<SvgIconProps> = (props) => (
  <SvgIcon width="100%" height="100%" viewBox="0 0 512 512" {...props}>
    <g>
      <path className="st0" d="M256,512c30.7,0,57.1-18.5,68.7-45H187.3C198.9,493.5,225.3,512,256,512z" />
      <path
        className="st0"
        d="M411,247.9V215c0-69.8-46.4-129-110-148.3V45c0-24.8-20.2-45-45-45s-45,20.2-45,45v21.7
		C147.4,86,101,145.2,101,215v32.9c0,61.3-23.4,119.5-65.8,163.8c-4.2,4.3-5.3,10.7-3,16.3s7.8,9,13.8,9h420c6,0,11.4-3.6,13.8-9.1
		s1.2-11.9-3-16.3C434.4,367.3,411,309.2,411,247.9L411,247.9z M271,60.7c-4.9-0.5-9.9-0.7-15-0.7s-10.1,0.3-15,0.7V45
		c0-8.3,6.7-15,15-15c8.3,0,15,6.7,15,15V60.7z"
      />
      <path
        className="st0"
        d="M451,215c0,8.3,6.7,15,15,15s15-6.7,15-15c0-60.1-23.4-116.6-65.9-159.1c-5.9-5.9-15.4-5.9-21.2,0
		s-5.9,15.4,0,21.2C430.7,113.9,451,162.9,451,215L451,215z"
      />
      <path
        className="st0"
        d="M46,230c8.3,0,15-6.7,15-15c0-52.1,20.3-101.1,57.1-137.9c5.9-5.9,5.9-15.4,0-21.2c-5.9-5.9-15.4-5.9-21.2,0
		C54.4,98.4,31,154.9,31,215C31,223.3,37.7,230,46,230z"
      />
    </g>
  </SvgIcon>
);

export default Notification;
