import SvgIcon from '@mui/material/SvgIcon';

const RealWallet = (props) => (
  <SvgIcon width="100%" height="100%" viewBox="0 0 512 512" {...props}>
    <g>
      <path
        className="st0"
        d="M306.8,314.7c0-51.7,42.1-93.8,93.8-93.8H480V156c0-8.3-6.7-15-15-15H51c-28.1-0.1-51-22.9-51-51.2v224.8V400
		c0,40.4,32.7,73.1,73.1,73.1h392c8.3,0,15-6.7,15-15v-49.7h-79.4C348.9,408.5,306.8,366.4,306.8,314.7L306.8,314.7z"
      />
      <path
        className="st0"
        d="M497,250.8h-96.4c-35.3,0-63.8,28.6-63.8,63.8s28.6,63.8,63.8,63.8H497c8.3,0,15-6.7,15-15v-97.7
		C512,257.6,505.3,250.8,497,250.8L497,250.8z M392.1,329.7c-8.5,0.1-15-7.1-15-15c0-1.9,0.3-3.9,1.2-5.8c5.1-12.3,22.6-12.3,27.7,0
		c0.8,1.9,1.2,3.8,1.2,5.8C407,322.5,400.5,329.8,392.1,329.7L392.1,329.7z"
      />
      <path
        className="st0"
        d="M66.1,110.9h371.6V53.8c0-8.3-6.7-15-15-15H66.1c-19.9,0-36.1,16.1-36.1,36C30,94.8,46.2,110.9,66.1,110.9
		L66.1,110.9z"
      />
    </g>
  </SvgIcon>
);

export default RealWallet;
