import { FC, Fragment, ReactNode, useEffect, useState } from 'react';
import { isSafari } from 'react-device-detect';
import { Link, useLocation } from 'react-router-dom';

import { Box, Button, IconButton, Stack, SwipeableDrawer, Typography } from '@mui/material';

import Logo from 'components/common/svg/logo';

import AccountIcon from 'components/common/icons/Account';
import CloseIcon from 'components/common/icons/Close';
import LiveCasinoIcon from 'components/common/icons/LiveCasino';
import MenuIcon from 'components/common/icons/Menu';
import RealWalletIcon from 'components/common/icons/RealWallet';
import CasinoIcon from 'components/common/icons/SlotsCasino';
import SportIcon from 'components/common/icons/Sport';
import SportLiveIcon from 'components/common/icons/SportLive';

import Notifications from 'components/sections/Navigation/UserSection/Notifications';
import UserProfile from 'components/sections/Navigation/UserSection/UserProfile';

import useCategories from 'store/categories';
import useCurrentUser from 'store/currentUser';
import useDialog from 'store/dialog';
import useSettings, { IWebsiteConfigs } from 'store/settings';
import useWebsiteSports, { SportProviders } from 'store/websiteSports';

import useTranslates from 'utils/translate';

import UserWallet from '../UserSection/Wallet';

import { MOBILE_HEADER_ID, MOBILE_NAVBAR_ID } from '../constants';
import MobileMenu from './Menu';
import sx from './styles';
import ApplicationDialog from 'components/sections/ApplicationDialog';
import { hasLiveType, hasPrematchType } from 'utils/predicates';

interface IMobileNavigationProps {
  footerNavigation?: ReactNode;
  headerVisible?: boolean;
}

const MobileNavigation: FC<IMobileNavigationProps> = ({ footerNavigation, headerVisible = true }) => {
  const { translate } = useTranslates();

  const [categories] = useCategories();
  const [, dialogActions] = useDialog();
  const [currentUser] = useCurrentUser();
  const [websiteSports] = useWebsiteSports();
  const [
    {
      data: { meta = {} as IWebsiteConfigs },
    },
  ] = useSettings();
  const { pathname, search } = useLocation();

  const [opened, setOpened] = useState(false);

  const href = `${pathname}${search}`;

  const onMenuClose = () => setOpened(false);

  const toggleOpened = () => setOpened((prev) => !prev);

  useEffect(() => {
    setOpened(false);
  }, [href]);

  const hideNavBarItems = ['/game/', '/sport'];

  const isHomePagePathname = pathname === '/';

  const showNavBar =
    !hideNavBarItems.some((hideNavBarItem) => pathname.includes(hideNavBarItem)) &&
    !(!meta?.isHomepageActive && isHomePagePathname);

  const filteredWebsiteSports = meta.preferredSportId
    ? websiteSports.data.filter(({ id }) => id === meta.preferredSportId)
    : websiteSports.data;

  const menuItemClick = (event, isLoginRequired) => {
    if (!currentUser?.data?.id && isLoginRequired) {
      event.stopPropagation();
      event.preventDefault();
      dialogActions.set('signIn');
    }
  }

  return (
    <>
      <ApplicationDialog forceHide={!isHomePagePathname || opened} />
      {headerVisible && (
        <Box sx={sx.head({ opened, isSafari })} id={MOBILE_HEADER_ID}>
          <Link to="/">
            <Logo useInlineBounds onClick={onMenuClose} />
          </Link>
          <Stack direction="row" gap={0.5} alignItems="center">
            {currentUser?.data?.id ? (
              <>
                {meta.showDeposit && meta.showDepositIconOnMobileHeader && (
                  <IconButton color="success" onClick={() => dialogActions.set('deposit')}>
                    <RealWalletIcon />
                  </IconButton>
                )}
                <UserWallet showOnlyBalance />

                <Notifications />
                <UserProfile user={currentUser?.data}>
                  <IconButton color="inherit">
                    <AccountIcon />
                  </IconButton>
                </UserProfile>
              </>
            ) : (
              <>
                <Button
                  sx={{
                    height: 30,
                    padding: '12px',
                    color: meta.customColors?.signIn || 'text.main',
                    backgroundColor: meta.customColors?.signInBg || 'primary.main',
                  }}
                  variant="contained"
                  onClick={() => dialogActions.set('signIn')}
                >
                  {translate('sign_in')}
                </Button>
                {meta.showSignup && (
                  <Button
                    sx={{
                      height: 30,
                      padding: '12px',
                      color: meta.customColors?.signIn || 'text.main',
                      backgroundColor: meta.customColors?.signInBg || 'primary.main',
                    }}
                    variant="contained"
                    onClick={() => dialogActions.set('signUp')}
                  >
                    {translate('sign_up')}
                  </Button>
                )}
              </>
            )}
          </Stack>
        </Box>
      )}
      {showNavBar && (
        <Stack sx={sx.menuItems} direction="row" gap="20px">
          {meta?.isHomepageActive && (
            <Button component={Link} to="/" sx={sx.button({ selected: pathname === '/' })}>
              {translate('home')}
            </Button>
          )}

          {websiteSports.data.filter(hasPrematchType).map((websiteSport, index) => {
            const path = !meta?.isHomepageActive && !index ? '/' : `/sport/${websiteSport.id}`;

            return (
              <Button component={Link} to={path} key={path} sx={sx.button({ selected: pathname === path })}>
                {translate(`sport_${websiteSport.id}`)}
              </Button>
            );
          })}
          {meta.showGamesInTopNavigation && (
            <Button key="/games" component={Link} to="/games" sx={sx.button({ selected: pathname === '/games' })}>
              {translate('games')}
            </Button>
          )}

          {categories?.data?.map((item) => {
            const isDirectGameId = !Number.isNaN(Number(item.uri))
            const path = isDirectGameId ? `/game/${item.uri}` : `/games/${item.uri}`;

            return (
              <Button
                to={path}
                key={item.key}
                component={(props) => <Link {...props} onClick={event => menuItemClick(event, isDirectGameId)} />}
                sx={sx.button({ selected: pathname === path })}
              >
                {item.name}
              </Button>
            );
          })}
        </Stack>
      )}

      <SwipeableDrawer
        anchor="right"
        open={opened}
        sx={sx.drawer}
        PaperProps={{
          sx: sx.drawerPaper,
        }}
        onOpen={() => setOpened(true)}
        onClose={() => setOpened(false)}
      >
        <MobileMenu onMenuClose={onMenuClose} />
      </SwipeableDrawer>
      {(footerNavigation || showNavBar) && (
        <Stack
          id={MOBILE_NAVBAR_ID}
          sx={sx.navBar}
          direction="row"
          gap={1}
          display="flex"
          style={{
            overflowX: 'auto',
            overflowY: 'hidden',
          }}
        >
          {footerNavigation || (
            <>
              {filteredWebsiteSports.map((websiteSport, index) => {
                const path = !meta?.isHomepageActive && !index ? '/' : `/sport/${websiteSport.id}`;

                const isSoftSwiss = websiteSport.id === SportProviders.SOFT_SWISS_ID;
                return (
                  <Fragment key={index}>
                    {hasLiveType(websiteSport) && (
                      <Link
                        to={`${path}${websiteSport.id === SportProviders.BET_BY_ID ? '?bt-path=/live' : '?tab=live'}`}
                      >
                        <Box sx={sx.navButton({ selected: false })}>
                          <SportLiveIcon sx={{ fontSize: 24 }} />
                          <Typography>{translate(`live_${websiteSport.id}`)}</Typography>
                        </Box>
                      </Link>
                    )}
                    {hasPrematchType(websiteSport) && (
                      <Link
                        to={`${path}${
                          websiteSport.id === SportProviders.BET_BY_ID
                            ? ''
                            : `?tab=${isSoftSwiss ? 'schedule' : 'sport'}`
                        }`}
                      >
                        <Box sx={sx.navButton({ selected: false })}>
                          <SportIcon sx={{ fontSize: 24 }} />
                          <Typography>{translate(`sport_${websiteSport.id}`)}</Typography>
                        </Box>
                      </Link>
                    )}
                  </Fragment>
                );
              })}

              <Link to="/games/slots">
                <Box sx={sx.navButton({ selected: pathname === '/games/slots' })}>
                  <CasinoIcon />
                  <Typography>{translate('casino')}</Typography>
                </Box>
              </Link>
              <Link to="/games/live_casino">
                <Box sx={sx.navButton({ selected: pathname === '/games/live_casino' })}>
                  <LiveCasinoIcon />
                  <Typography>{translate('live_casino')}</Typography>
                </Box>
              </Link>
            </>
          )}
          <Box sx={sx.navButton({ selected: false })} onClick={toggleOpened}>
            {opened ? <CloseIcon /> : <MenuIcon />}
            <Typography>{translate('menu')}</Typography>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default MobileNavigation;
