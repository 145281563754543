import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Account = (props?: SvgIconProps) => (
  <SvgIcon width="100%" height="100%" viewBox="0 0 512 512" {...props}>
    <path
      d="M252.4,246.6c33.9,0,63.2-12.2,87.2-36.1c24-24,36.1-53.3,36.1-87.2s-12.2-63.2-36.1-87.2
    c-24-24-53.3-36.1-87.2-36.1s-63.2,12.2-87.2,36.1S129,89.4,129,123.3s12.2,63.2,36.1,87.2C189.1,234.5,218.5,246.6,252.4,246.6z"
    />
    <path
      d="M468.1,393.7c-0.7-10-2.1-20.9-4.1-32.4c-2.1-11.6-4.8-22.5-8-32.5c-3.3-10.3-7.8-20.6-13.4-30.3
    c-5.8-10.2-12.6-19-20.2-26.3c-8-7.6-17.7-13.7-29-18.2c-11.2-4.4-23.7-6.7-37-6.7c-5.2,0-10.3,2.1-20,8.5c-6,3.9-13,8.4-20.9,13.5
    c-6.7,4.3-15.8,8.3-27,11.9c-10.9,3.5-22.1,5.3-33,5.3s-22.1-1.8-33-5.3c-11.2-3.6-20.3-7.6-27-11.9c-7.8-5-14.8-9.5-20.9-13.5
    c-9.8-6.4-14.8-8.5-20-8.5c-13.3,0-25.8,2.3-37,6.7c-11.3,4.5-21,10.6-29,18.2c-7.6,7.3-14.4,16.1-20.2,26.3
    c-5.6,9.8-10.1,20-13.4,30.3c-3.2,10-5.9,20.9-8,32.5c-2.1,11.5-3.5,22.4-4.1,32.4c-0.7,9.8-1,20-1,30.2c0,26.7,8.5,48.4,25.2,64.3
    C83.8,504,105.7,512,132.3,512h246.5c26.6,0,48.5-8,65.1-23.7c16.8-15.9,25.3-37.6,25.3-64.3C469.2,413.6,468.8,403.5,468.1,393.7z"
    />
  </SvgIcon>
);

export default Account;
