import { FC, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import useWebsiteContent from 'store/websiteContent';

const StaticContent = lazy(() => import('./StaticContent'));

interface IRoute {
  exact?: boolean;
  path: string;
  component: FC;
}

interface SwitchRouter {
  routes?: IRoute[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const renderRoute = ({ exact, ...route }: IRoute, index: number): JSX.Element => {
  const Component = route.component;

  return <Route key={index} path={route.path} element={<Component />} />;
};

const SwitchRouter = ({ routes }: SwitchRouter): JSX.Element => {
  const [websiteContents] = useWebsiteContent();
  return (
    <Routes>
      {routes?.length > 0 && routes.map(renderRoute)}
      {websiteContents.data.map((content) => (
        <Route key={content.url} path={`/info/${content.url}`} element={<StaticContent content={content} />} />
      ))}
    </Routes>
  );
};

export default SwitchRouter;
