import { alpha, createTheme, darken, lighten } from '@mui/material/styles';
import useSelectedLanguage from 'store/language';

import useSettings from 'store/settings';
import useSelectedTheme from 'store/theme';

export const DEFAULT_PALETTE = {
  light: {
    error: '#FC4955',
    info: '#7F7FAF',
    primary: 'rgba(54, 195, 255, 1)',
    primaryText: '#3e5060',
    secondary1: '#2d4955',
    secondary2: '#FFCD42',
    secondaryText: '#2d4955',
    success: '#379634',
    background: '#E7E7E7',
    secondary: {
      light: 'rgb(231, 235, 240)',
      dark: 'rgb(231, 235, 240)',
    },
  },
  dark: {
    error: '#FC4955',
    info: '#7F7FAF',
    primary: 'rgba(54, 195, 255, 1)',
    primaryText: '#FFFFFF',
    secondary1: '#2d4955',
    secondary2: '#FFCD42',
    secondaryText: '#2d4955',
    success: '#379634',
    background: '#121b30',
    secondary: {
      light: 'rgba(255, 255, 255, 0.1)',
      dark: 'rgba(0, 0, 0, 0.1)',
    },
  },
};

const fontFamily = 'Quicksand, sans-serif';
const boldFontFamily = 'Quicksand-Bold, sans-serif';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    bordered: true;
  }
  interface ButtonPropsColorOverrides {
    naturalGrey: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    naturalGrey: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    naturalGrey: Palette['primary'];
  }
  interface PaletteOptions {
    naturalGrey: PaletteOptions['primary'];
  }
}

const defaultMuiTheme = createTheme();

const useCustomTheme = () => {
  const [{ dir, isRtl }] = useSelectedLanguage();
  const [{ data: settings }] = useSettings();
  const [selectedTheme] = useSelectedTheme();

  const customPalette = {
    dark: {
      ...DEFAULT_PALETTE.dark,
      primary: settings?.actionColorDark || DEFAULT_PALETTE.dark.primary,
      primaryText: settings?.fontColorDark || DEFAULT_PALETTE.dark.primaryText,
      background: {
        default: settings?.mainColorDark || DEFAULT_PALETTE.dark.background,
        paper: lighten(settings?.mainColorDark || DEFAULT_PALETTE.dark.background, 0.102),
      },
    },
    light: {
      ...DEFAULT_PALETTE.light,
      primary: settings?.actionColor || DEFAULT_PALETTE.light.primary,
      primaryText: settings?.fontColor || DEFAULT_PALETTE.light.primaryText,
      background: {
        default: settings?.mainColor || DEFAULT_PALETTE.light.background,
        paper: lighten(settings?.mainColor || DEFAULT_PALETTE.light.background, 0.7),
      },
    },
  };

  const palette = customPalette[selectedTheme.type];

  const theme = createTheme({
    direction: dir,
    typography: {
      fontFamily,
      h3: {
        fontSize: 24,
        lineHeight: '30px',
        fontWeight: 'bold',
        letterSpacing: '0.18px',
        color: lighten(palette.secondaryText, 0.3),
        [defaultMuiTheme.breakpoints.down('md')]: {
          fontSize: 20,
          lineHeight: '26px',
        },
      },
      h4: {
        fontSize: 24,
        lineHeight: '30px',
        fontWeight: 'bold',
        [defaultMuiTheme.breakpoints.down('md')]: {
          fontSize: 18,
          lineHeight: '24px',
        },
      },
      h6: {
        fontSize: 18,
        lineHeight: '30px',
        fontWeight: 'normal',
      },
      subtitle1: {
        fontSize: 16,
        lineHeight: '20px',
        color: palette.secondaryText,
      },
      subtitle2: {
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 400,
      },
    },
    palette: {
      info: {
        main: palette.info,
        dark: lighten('#000061', 0.3),
        light: '#36C3FF',
      },
      text: {
        primary: palette.primaryText,
        secondary: palette.secondaryText,
      },
      primary: {
        main: palette.primary,
        dark: darken(palette.primary, 0.05),
        light: lighten(palette.primary, 0.5),
      },
      secondary: {
        main: lighten(palette.secondary1, 0.5),
        ...palette.secondary,
      },
      error: {
        main: palette.error,
      },
      success: {
        main: palette.success,
      },
      warning: {
        main: palette.secondary2,
      },
      background: palette.background,
      naturalGrey: {
        main: '#96A2A9',
        dark: '#E5E8EA',
        light: 'rgba(255, 255, 255, 0.51);',
      },
      divider: 'rgba(0, 0, 0, 0.3)',
    },
    components: {
      MuiMenu: {
        defaultProps: {
          disableScrollLock: true,
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            '& .MuiAlert-icon': {
              opacity: 1,
              fontSize: 20,
              padding: '8px 0',
            },
            '& button': {
              color: palette.primaryText,
            },
          },
          standardError: {
            color: palette.primaryText,
            backgroundColor: palette.error,
            '& .MuiAlert-icon': {
              color: palette.primaryText,
            },
          },
          standardSuccess: {
            color: palette.primaryText,
            backgroundColor: palette.success,
            '& .MuiAlert-icon': {
              color: palette.primaryText,
            },
          },
        },
      },
      MuiAlertTitle: {
        styleOverrides: {
          root: {
            fontSize: 16,
            lineHeight: '22px',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          'div[role="tooltip"][class*="popper"]': {
            maxWidth: '350px',
            paddingTop: '16px',
            paddingBottom: '16px',
            width: 'calc(100% - 48px)',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          popper: {
            maxWidth: 200,
            pointerEvents: 'none',
          },
          tooltip: {
            padding: 12,
            color: palette.primaryText,
            borderRadius: 4,
            background: palette.background.paper,
            fontSize: 15,
            lineHeight: '24px',
            fontFamily: fontFamily,
            fontWeight: 700,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: 3,
            borderRadius: '3px 3px 0 0',
            transform: 'scaleX(0.5)',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: 14,
            fontWeight: 600,
            minWidth: '70px !important',
            padding: '28px 8px !important',
            color: lighten(palette.primaryText, 0.4),
            textTransform: 'none',
            '&.Mui-selected': {
              color: palette.primary,
              background: 'transparent',
            },
            [defaultMuiTheme.breakpoints.down('md')]: {
              padding: '18px 12px',
            },
          },
        },
      },
      MuiAccordion: {
        defaultProps: {
          elevation: 0,
        },
        styleOverrides: {
          root: {
            margin: '0 0 10px 0 !important',
            borderRadius: '10px !important',
            backgroundColor: palette.secondary.dark,
            '&:before': {
              display: 'none',
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: 16,
            height: '40px !important',
            minHeight: '40px !important',
          },
          content: {
            margin: 0,
            fontSize: 14,
            fontWeight: 600,
          },
        },
      },
      MuiInputLabel: {
        defaultProps: {
          shrink: true,
        },
        styleOverrides: {
          standard: {
            fontWeight: 700,
            color: `${palette.primaryText} !important`,
            '&.Mui-error': {
              color: `${palette.error} !important`,
            },
          },
          outlined: {
            color: `${palette.primaryText} !important`,
            '&.Mui-error': {
              color: `${palette.error} !important`,
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            left: isRtl ? 0 : 'auto',
            right: isRtl ? 'auto' : 0,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            height: 42,
            borderRadius: '6px',
            'label + &.MuiInput-underline': {
              marginTop: 20,
            },
            '& input': {
              height: 9,
              fontSize: 14,
              background: 'transparent',
              minWidth: 'auto !important',
            },
            '&.MuiInput-underline': {
              color: 'black',
              background: '#FBFCFF',
              outlineOffset: '-1px',
              outline: '1px solid #DADFEA',
              '& > input': {
                padding: '16.5px 14px',
              },
              '& > fieldset': {
                padding: '0 8px',
                borderColor: '#DADFEA',
              },
              '& .MuiInputAdornment-root': {
                width: isRtl ? 'auto' : 60,
                '@media only screen and (max-width: 700px)': {
                  margin: 0,
                },
              },
              '&:after, &:before': {
                display: 'none',
              },
              '&.Mui-focused': {
                outlineOffset: '-2px',
                outline: `2px solid ${palette.primary}`,
              },
              '&.Mui-error': {
                borderColor: palette.error,
              },
              '&.Mui-disabled': {
                '& input': {
                  color: lighten(palette.info, 0.5),
                  WebkitTextFillColor: lighten(palette.info, 0.5),
                },
              },
              '& .MuiSelect-select': {
                padding: isRtl ? '16.5px 14px 16.5px 40px' : '16.5px 40px 16.5px 14px',
              },
              '& .MuiSelect-icon': {
                marginRight: isRtl ? 0 : '14px',
                marginLeft: !isRtl ? 0 : '14px',
              },
            },
            '&.MuiOutlinedInput-root': {
              '& svg': {
                color: alpha(palette.primaryText, 0.5),
              },
              '&.Mui-focused > fieldset': {
                border: `2px solid ${palette.primary} !important`,
              },
              '&.Mui-error > fieldset': {
                borderColor: `${palette.error} !important`,
              },
              '& > fieldset': {
                borderRadius: '6px',
                borderColor: `${alpha(palette.primaryText, 0.2)} !important`,
              },
              '& legend': {
                maxWidth: '100%',
              },
              '&.Mui-disabled': {
                '& input, & p': {
                  color: alpha(palette.primaryText, 0.5),
                  WebkitTextFillColor: alpha(palette.primaryText, 0.5),
                },
                '& > fieldset': {
                  borderStyle: 'dashed',
                },
              },
            },
          },
        },
      },
      MuiAppBar: {
        defaultProps: {
          elevation: 0,
        },
        styleOverrides: {
          root: {
            zIndex: 2000,
            background: palette.secondary1,
            color: '#FFFFFF',
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          regular: {
            [defaultMuiTheme.breakpoints.down('md')]: {
              minHeight: 56,
            },
            [defaultMuiTheme.breakpoints.up('sm')]: {
              minHeight: 56,
            },
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            '& svg, & path': {
              fill: palette.primary,
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected, &.Mui-selected:hover, &:hover': {
              backgroundColor: '#F6F8F9 !important',
            },
          },
        },
      },
      MuiSkeleton: {
        defaultProps: {
          animation: 'wave',
          variant: 'rectangular',
        },
        styleOverrides: {
          root: {
            background: palette.secondary.dark,
          },
          text: {
            background: palette.secondary.dark,
          },
          rectangular: {
            borderRadius: '20px',
            [defaultMuiTheme.breakpoints.down('md')]: {
              borderRadius: '10px',
            },
          },
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'bordered' },
            style: ({ theme, ...props }) => {
              const params: { ownerState: Record<string, string> } = { ...props } as {
                ownerState: Record<string, string>;
              };

              const color = `${
                params.ownerState.color === 'naturalGrey' ? theme.palette.naturalGrey.main : theme.palette.text.primary
              } !important`;

              return {
                color,
                backgroundColor: theme.palette.naturalGrey.light,
                border: `1px solid ${theme.palette.naturalGrey.light}`,
                ':disabled': {
                  color,
                  opacity: 0.6,
                },
                '& svg': {
                  color: theme.palette.naturalGrey.main,
                },
              };
            },
          },
        ],
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          containedSecondary: {
            color: palette.primary,
            backgroundColor: palette.secondary1,
            border: `1px solid ${palette.secondary1}`,
            '&:hover': {
              color: 'white',
              backgroundColor: lighten(palette.primary, 0.3),
              border: `1px solid ${lighten(palette.primary, 0.3)}`,
            },
          },
          containedPrimary: {
            color: 'white',
            border: `1px solid ${palette.primary}`,
            '&:hover': {
              backgroundColor: lighten(palette.primary, 0.3),
              border: `1px solid ${lighten(palette.primary, 0.3)}`,
            },
          },
          contained: {
            ':disabled': {
              color: `${alpha(palette.primaryText, 0.35)} !important`,
              borderColor: 'transparent',
              backgroundColor: palette.secondary.light,
            },
          },
          text: {
            font: `normal normal 700 12px/15px ${fontFamily}`,
            '& svg': {
              color: palette.primaryText,
            },
            ':disabled': {
              color: `${alpha(palette.primaryText, 0.35)} !important`,
            },
          },
          outlined: {
            ':disabled': {
              color: `${alpha(palette.primaryText, 0.35)} !important`,
              borderColor: palette.secondary.light,
              backgroundColor: palette.secondary.light,
            },
          },
          root: {
            fontWeight: '700',
            fontSize: 14,
            lineHeight: '15px',
            textTransform: 'none',
            borderRadius: '8px',
            fontFamily: boldFontFamily,
          },
          sizeLarge: {
            height: 48,
            padding: '10px 22px',
          },
          sizeMedium: {
            height: 42,
            padding: '8px 22px',
          },
          sizeSmall: {
            padding: '5px 22px',
          },
          containedSuccess: {
            color: 'white',
          },
          startIcon: {
            marginRight: isRtl ? '-4px' : '8px',
            marginLeft: isRtl ? '8px' : '-4px',
          },
          endIcon: {
            marginLeft: isRtl ? '-4px' : '8px',
            marginRight: isRtl ? '8px' : '-4px',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            zIndex: 1300,
            '& > .MuiBackdrop-root': {
              background: 'rgba(16, 24, 43, 0.8)',
              backdropFilter: 'blur(17px)',
            },
          },
          paper: {
            width: '100%',
            maxWidth: 360,
            border: '2px solid rgba(255, 255, 255, 0.2)',
            borderRadius: '10px',
            background: palette.background.default,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid #D9DDE0',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: '16px 24px',
            font: `normal normal 600 16px/20px ${fontFamily}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            background: 'rgba(255, 255, 255, 0.1)',
            padding: '30px 24px !important',
            '& > *:not(:first-of-type)': {
              marginTop: '16px',
            },
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            color: '#082032',
            font: `normal normal normal 14px/20px ${fontFamily}`,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '24px',
            [defaultMuiTheme.breakpoints.down('md')]: {
              justifyContent: 'center',
            },
            '& > *:not(:first-of-type)': {
              marginLeft: '12px',
            },
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: ({ theme }) => {
            return {
              padding: '13px 0 !important',
              color: theme.palette.naturalGrey.dark,
              '&:after, &:before': {
                content: '""',
                position: 'absolute',
                width: 12,
                height: 12,
                top: '8px',
                borderRadius: '10px',
                background: '#BCBBCA',
                border: '1px solid #FFFFFF',
              },
              '&:after': {
                zIndex: 1,
                left: '-8px',
              },
              '&:before': {
                zIndex: 2,
                right: '-8px',
              },
              '& .MuiSlider-track, & .MuiSlider-rail': {
                opacity: 1,
                border: 'none',
                background: '#BCBBCB',
              },
              '& .MuiSlider-thumb': {
                zIndex: 3,
                border: '2px solid white',
                boxShadow: 'none !important',
                background: palette.secondary1,
              },
              '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
              },
              '& .MuiSlider-valueLabel': {
                top: 55,
                fontSize: 15,
                fontWeight: 'normal',
                borderRadius: '34px',
                backgroundColor: palette.secondary1,
                color: theme.palette.naturalGrey.light,
                '&:before': {
                  display: 'none',
                },
                '& *': {
                  background: 'transparent',
                  color: '#fff',
                },
              },
            };
          },
        },
      },
    },
  });

  theme.shadows.unshift('none', '0px 0px 10px #829DB233');

  return theme;
};

export default useCustomTheme;
