import useMount from 'hooks/useMount';

const containerId = 'zendesk-container';

const ZendeskChat = ({ secretKey }: { secretKey: string }) => {
  useMount(() => {
    const script = document.createElement('script');
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${secretKey}`;
    script.async = true;
    script.id = 'ze-snippet';

    const container = document.getElementById(containerId);
    container?.appendChild(script);
  });
  return <div id={containerId} />;
};

export default ZendeskChat;
