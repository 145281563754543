import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState, Status } from 'store/types';

import { SettingsService } from 'api/settings';

interface IWebsiteContentTranslation {
  id: number;
  langId: number;
  title: string;
  subtitle: string;
  content: string;
  img: string;
  imgDark: string | null;
  seoDescription: string;
  seoKeywords: string[];
  seoTitle: string;
}

export interface IWebsiteContent {
  id: number;
  name: string;
  url: string;
  translations: IWebsiteContentTranslation[];
}

type WebsiteContentStateType = {
  data: IWebsiteContent[];
  status: Status;
};

type WebsiteContentActionsType = {
  read: () => void;
};

const initialState: WebsiteContentStateType = {
  data: [],
  status: 'idle',
};

const readWebsiteContentApplyThunk = createAsyncThunk('website-content/read', async () =>
  SettingsService.websiteContent()
);

const websiteContentSlice = createSlice({
  initialState,
  name: 'website-content',
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(readWebsiteContentApplyThunk.pending.type, (state: WebsiteContentStateType): void => {
        state.status = 'loading';
      })
      .addCase(
        readWebsiteContentApplyThunk.fulfilled.type,
        (state: WebsiteContentStateType, action: PayloadAction<IWebsiteContent[]>): void => {
          state.status = 'success';
          state.data = action.payload || initialState.data;
        }
      )
      .addCase(readWebsiteContentApplyThunk.rejected.type, (state: WebsiteContentStateType): void => {
        state.status = 'failed';
        state.data = initialState.data;
      }),
});

const useWebsiteContent = (): [WebsiteContentStateType, WebsiteContentActionsType] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.websiteContent);

  const actions = {
    read: () => {
      dispatch(readWebsiteContentApplyThunk());
    },
  };

  return [state, actions];
};

export { websiteContentSlice };
export default useWebsiteContent;
