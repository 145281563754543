import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState, Status } from 'store/types';

import { IGlobalSettings, SettingsService } from 'api/settings';

type GlobalSettingsStateType = {
  data: Partial<IGlobalSettings>;
  status: Status;
};

type GlobalSettingsActionsType = {
  read: () => void;
};

const initialState: GlobalSettingsStateType = {
  data: {},
  status: 'idle',
};

const readGlobalSettingsApplyThunk = createAsyncThunk('globalSettings/read', async () => SettingsService.getSettings());

const globalSettingsSlice = createSlice({
  initialState,
  name: 'globalSettings',
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(readGlobalSettingsApplyThunk.pending.type, (state: GlobalSettingsStateType): void => {
        state.status = 'loading';
      })
      .addCase(
        readGlobalSettingsApplyThunk.fulfilled.type,
        (state: GlobalSettingsStateType, action: PayloadAction<IGlobalSettings>): void => {
          state.status = 'success';
          state.data = action.payload || initialState.data;
        }
      )
      .addCase(readGlobalSettingsApplyThunk.rejected.type, (state: GlobalSettingsStateType): void => {
        state.status = 'failed';
        state.data = initialState.data;
      }),
});

const useGlobalSettings = (): [GlobalSettingsStateType, GlobalSettingsActionsType] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.globalSettings);

  const actions = {
    read: () => {
      dispatch(readGlobalSettingsApplyThunk());
    },
  };

  return [state, actions];
};

export { globalSettingsSlice };
export default useGlobalSettings;
