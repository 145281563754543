import { IconButton } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const PrevArrow = (props) => {
  const { onClick, sx } = props;

  return (
    <IconButton
      sx={{
        zIndex: 10,
        borderRadius: '10px',
        position: 'absolute',
        bottom: 8,
        right: 56,
        '&, &:hover': {
          color: 'text.primary',
          bgcolor: 'background.paper',
        },
        ...sx,
      }}
      onClick={onClick}
    >
      <NavigateBeforeIcon />
    </IconButton>
  );
};

export default PrevArrow;
