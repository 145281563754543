import { FC, useRef, useState, useEffect } from 'react';

import { Box, Stack, Button, Badge, IconButton, Typography, TextField } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import SendIcon from 'components/common/svg/Send';
import AttachFile from 'components/common/AttachFile';
import AttachIcon from 'components/common/svg/Attach';
import noConversationsImage from 'assets/noConversations.png';

import { IRequest } from 'api/messages';
import useMessages from 'store/messages';
import useCurrentUser from 'store/currentUser';

import useScreen from 'hooks/useScreen';
import useTranslates from 'utils/translate';

import Message from './Message';
import sx from './styles';

interface MessengerProps {
  requests: IRequest[];
}

const Messenger: FC<MessengerProps> = ({ requests }) => {
  const lastRef = useRef(null);
  const { isMobile } = useScreen();
  const { translate } = useTranslates();

  const [currentUser] = useCurrentUser();
  const [, requestsActions] = useMessages();

  const [subjectName, setSubjectName] = useState('');
  const [messageText, setMessageText] = useState('');
  const [attachedFile, setAttachedFile] = useState('');
  const [selectedSubjectId, setSelectedSubjectId] = useState<number>(null);

  const selectedSubject: IRequest = requests?.find((item) => item.id === selectedSubjectId) || {};
  const selectedSubjectFirstMessage = selectedSubject?.messages?.[0] || {};

  const firstRequests = requests?.[0];

  const isShowMessages = Array.isArray(selectedSubject?.messages);

  useEffect(() => {
    lastRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [selectedSubjectId, requests]);

  useEffect(() => {
    if (!selectedSubjectId && !isMobile && requests?.length) {
      setSelectedSubjectId(requests[0].id);
    }
  }, [selectedSubjectId, isMobile, requests]);

  const createNewRequest = () => {
    setSubjectName('');
    setAttachedFile('');
    setSelectedSubjectId(0);
    requestsActions.createRequest();
  };

  const sendMessage = () => {
    if (messageText?.trim()?.length) {
      requestsActions.send({
        bucket: 'Sent',
        body: messageText,
        id: selectedSubject.id,
        attachments: attachedFile ? [attachedFile] : [],
        subject: selectedSubjectFirstMessage?.subject || subjectName,
      });
    }
  };

  const selectRequest = (id) => {
    setSubjectName('');
    setMessageText('');
    setAttachedFile('');
    setSelectedSubjectId(id);
  };

  const isSendDisabled = !(
    messageText?.length && (selectedSubjectFirstMessage.id ? selectedSubjectFirstMessage?.subject : subjectName?.length)
  );

  if (!requests.length) {
    return (
      <Box
        py={8}
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        bgcolor="background.paper"
      >
        <img width={isMobile ? 150 : 270} src={noConversationsImage} alt="noConversationsImage" />
        <Typography fontSize={16} fontWeight={700} mt={7.5} mb={5}>
          {translate('you_have_no_conversations')}
        </Typography>
        <Button variant="contained" onClick={createNewRequest}>
          {translate('compose_message_button')}
        </Button>
      </Box>
    );
  }

  return (
    <Box display="flex" bgcolor="background.paper" maxHeight={700} height="calc(100vh - 200px)">
      {(isMobile ? !isShowMessages : true) && (
        <Box sx={sx.requestsList({ isMobile })}>
          <Box width="100%" p={2}>
            <Button fullWidth variant="contained" disabled={!firstRequests.id} onClick={createNewRequest}>
              {translate('compose_message_button')}
            </Button>
          </Box>
          {requests?.map((item) => {
            const lastMessage = item.messages?.[0];

            return (
              <Box
                key={item.id}
                onClick={() => selectRequest(item.id)}
                sx={sx.requestItem({ selected: selectedSubject?.id === item.id })}
              >
                <Typography fontSize={16} fontWeight={600} mb={1}>
                  {lastMessage?.subject || translate('subject')}
                </Typography>
                <Typography fontSize={16} fontWeight={400} sx={{ opacity: 0.5 }}>
                  {lastMessage?.body || translate('your_message')}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}
      <Box sx={sx.messengerContent}>
        {isShowMessages && (
          <>
            <Box width="100%" flex={1} style={{ overflowY: 'auto', overscrollBehaviorY: 'none' }} height="100%">
              {isMobile && isShowMessages && (
                <Box
                  px={3}
                  py={1}
                  display="flex"
                  alignItems="center"
                  bgcolor="background.paper"
                  onClick={() => selectRequest(null)}
                  sx={{
                    top: 0,
                    zIndex: 10,
                    position: 'sticky',
                  }}
                >
                  <IconButton edge="start">
                    <ArrowBackIcon color="primary" />
                  </IconButton>
                  <Typography ml={1}>{selectedSubject?.messages?.[0]?.subject || translate('subject')}</Typography>
                </Box>
              )}
              <Box p={3}>
                {!!selectedSubject.id &&
                  [...selectedSubject.messages]
                    .reverse()
                    .map((message) => <Message key={message.id} user={currentUser.data} message={message} />)}
                <Box ref={lastRef} />
              </Box>
            </Box>
            {selectedSubject && (
              <Stack gap={1} p={3} bgcolor="rgba(0, 0, 0, 0.16)">
                {!selectedSubjectFirstMessage?.subject && (
                  <TextField
                    fullWidth
                    variant="outlined"
                    sx={sx.textField}
                    value={subjectName}
                    key={selectedSubject.id}
                    InputProps={{ sx: sx.input({ isMobile }) }}
                    placeholder={translate('type_subject')}
                    onChange={(event) => setSubjectName(event.target.value)}
                  />
                )}
                <TextField
                  fullWidth
                  variant="outlined"
                  sx={sx.textField}
                  value={messageText}
                  placeholder={translate('your_message')}
                  onKeyDown={(event) => event.code === 'Enter' && sendMessage()}
                  onChange={(event) => setMessageText(event.target.value)}
                  InputProps={{
                    sx: sx.input({ isMobile }),
                    startAdornment: (
                      <AttachFile onChange={setAttachedFile}>
                        <IconButton edge="start" sx={({ palette }) => ({ fill: palette.primary.main })}>
                          <Badge variant="dot" color="error" invisible={!attachedFile}>
                            <AttachIcon />
                          </Badge>
                        </IconButton>
                      </AttachFile>
                    ),
                    endAdornment: (
                      <IconButton
                        edge="end"
                        onClick={sendMessage}
                        disabled={isSendDisabled}
                        sx={({ palette }) => ({
                          fill: palette.primary.main,
                          opacity: isSendDisabled && 0.2,
                        })}
                      >
                        <SendIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Stack>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Messenger;
