import _Request from '../Request';

class BonusBalanceService extends _Request {
  constructor() {
    super('/player');
  }

  getBonusBalance() {
    return this.send({ method: 'get' }, '/bonus-balance');
  }
}

export default new BonusBalanceService();
