import useMount from 'hooks/useMount';
import { useEffect } from 'react';
import useTranslates from 'utils/translate';
import { ACCOUNT_NUMBER_LENGTH, BKASH_B, EMAIL_REGEXP } from './constants';
import { Grid } from '@mui/material';
import FormInput from 'components/common/Form/Input';

const ACCOUNT_EMAIL_FIELD = 'accountEmail';
const ACCOUNT_NUMBER_FIELD = 'accountNumber';

const Withdraw = ({
  control,
  watch,
  setError,
  paymentMethod,
  changeActionButtonVisibility,
  changeAmountFieldVisibility,
  changeActionButtonAvailability,
}) => {
  const amount = watch('amount');
  const accountNumber = watch(ACCOUNT_NUMBER_FIELD);
  const accountEmail = watch(ACCOUNT_EMAIL_FIELD);
  const { translate } = useTranslates();

  useMount(() => {
    changeAmountFieldVisibility(true);
    changeActionButtonVisibility(true);
    changeActionButtonAvailability(false);
  });

  const isBkashB = paymentMethod.method.name === BKASH_B;
  const needsAccountEmail = isBkashB;

  const areAllFieldsFilled = Boolean(amount) && accountNumber && (!needsAccountEmail || Boolean(accountEmail));

  const onEmailFieldBlur = () => {
    const email = watch(ACCOUNT_EMAIL_FIELD);
    if (EMAIL_REGEXP.test(email)) {
      setError(ACCOUNT_EMAIL_FIELD, null);
    } else {
      setError(ACCOUNT_EMAIL_FIELD, { message: translate('invalid_email') });
    }
  };

  const onAccountNumberFieldBlur = () => {
    if (!isBkashB) return;

    const accountNumber = watch(ACCOUNT_NUMBER_FIELD);
    if (accountNumber.length === ACCOUNT_NUMBER_LENGTH) {
      setError(ACCOUNT_NUMBER_FIELD, null);
    } else {
      setError(ACCOUNT_NUMBER_FIELD, { message: translate('invalid_account_number') });
    }
  };

  const isValidAccountNumber = accountNumber?.length === ACCOUNT_NUMBER_LENGTH;
  const isEmailValid = !needsAccountEmail || EMAIL_REGEXP.test(accountEmail);

  useEffect(() => {
    changeActionButtonAvailability(isValidAccountNumber && areAllFieldsFilled && isEmailValid);
  }, [isValidAccountNumber, areAllFieldsFilled, isEmailValid]);

  return (
    <>
      {needsAccountEmail && (
        <Grid item xs={24}>
          <FormInput
            onBlur={onEmailFieldBlur}
            label={translate('account_email')}
            name={ACCOUNT_EMAIL_FIELD}
            required
            control={control}
          />
        </Grid>
      )}
      <Grid item xs={24}>
        <FormInput
          required
          control={control}
          onBlur={onAccountNumberFieldBlur}
          name={ACCOUNT_NUMBER_FIELD}
          label={translate('account_number')}
        />
      </Grid>
    </>
  );
};

export default Withdraw;
