import { Box, Grid } from '@mui/material';
import FormCardInput from 'components/common/Form/CardInput/CardInput';
import FormInput from 'components/common/Form/Input';
import FormPhoneInput from 'components/common/Form/PhoneInput';
import FormPasswordField from 'components/common/Form/Password';
import FormSelect from 'components/common/Form/Select';
import useMount from 'hooks/useMount';
import { FC, useState } from 'react';
import { Control, FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import useCountries from 'store/countries';
import useCurrentUser from 'store/currentUser';
import { PaymentMethod } from 'types';
import useTranslates from 'utils/translate';
import FormLabel from 'components/common/Form/FormLabel';
import { LoadingButton } from '@mui/lab';
import { PaymentMethodsService } from 'api/paymentMethods';
import useToaster from 'hooks/useToaster';

const months = [
  { id: 1, name: 'Jan' },
  { id: 2, name: 'Feb' },
  { id: 3, name: 'Mar' },
  { id: 4, name: 'Apr' },
  { id: 5, name: 'May' },
  { id: 6, name: 'Jun' },
  { id: 7, name: 'Jul' },
  { id: 8, name: 'Aug' },
  { id: 9, name: 'Sep' },
  { id: 10, name: 'Oct' },
  { id: 11, name: 'Nov' },
  { id: 12, name: 'Dec' },
];

const years = Array.from({ length: 10 }, (_, i) => {
  const year = new Date().getFullYear();
  return { id: year + i, name: `${year + i}` };
});

const BorderLess: FC<{
  isDeposit: boolean;
  onClose: () => void;
  paymentMethod: PaymentMethod;
  control: Control<FieldValues, unknown>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  changeAmountFieldVisibility: (isVisible: boolean) => void;
  changeActionButtonVisibility: (isVisible: boolean) => void;
  changeActionButtonAvailability: (isAvailability: boolean) => void;
}> = ({
  watch,
  control,
  setValue,
  isDeposit,
  paymentMethod,
  changeAmountFieldVisibility,
  changeActionButtonVisibility,
}) => {
  const [currentUser] = useCurrentUser();
  const { translate } = useTranslates();
  const [countries] = useCountries();
  const notify = useToaster();
  const [loading, setLoading] = useState(false);
  useMount(() => {
    setValue('email', currentUser.data.email);
    setValue('currency', currentUser.data.currency.code);
    changeActionButtonVisibility(false);
    changeAmountFieldVisibility(true);
  });

  if (!isDeposit) return <>Not Supported</>;

  const amount = watch('amount');
  const firstName = watch('firstname');
  const lastName = watch('lastname');
  const phoneNumber = watch('phoneNumber');
  const country = watch('country');
  const state = watch('state');
  const city = watch('city');
  const address = watch('address');
  const zip = watch('zip');
  const cardName = watch('cardName');
  const cardNumber = watch('cardNumber');
  const expMonth = watch('expMonth');
  const expYear = watch('expYear');
  const cardCVV = watch('cardCVV');

  const areAllFieldsFilled = Boolean(
    amount &&
      firstName &&
      lastName &&
      phoneNumber &&
      country &&
      state &&
      city &&
      address &&
      zip &&
      cardName &&
      cardNumber &&
      expMonth &&
      expYear &&
      cardCVV
  );

  const onClick = async () => {
    setLoading(true);
    const result = await PaymentMethodsService.getPaymentMethodExtraInfo<[{ key: string; value: string }]>({
      amount: Number(amount),
      infoProperties: {
        cardCVV,
        cardName,
        cardNumber: cardNumber.replace(/\s/g, ''),
        expMonth: String(expMonth),
        expYear: String(expYear),
        firstname: firstName,
        lastname: lastName,
        phone: phoneNumber,
        country,
        state,
        city,
        address,
        zipCode: zip,
        email: currentUser.data.email,
      },
      sourceId: paymentMethod.method.id,
    });

    setLoading(false);
    if (result[0].value) {
      window.location.href = result[0].value;
    } else {
      notify({
        message: translate('payment_invalid_credentials'),
        type: 'error',
      });
    }
  };

  return (
    <>
      <Grid item xs={6}>
        <FormInput label={translate('firstname')} name="firstname" required control={control} />
      </Grid>
      <Grid item xs={6}>
        <FormInput label={translate('lastname')} name="lastname" required control={control} />
      </Grid>
      <Grid item xs={6}>
        <FormLabel>{translate('phone')}</FormLabel>
        <FormPhoneInput name="phoneNumber" required control={control} />
      </Grid>
      <Grid item xs={6}>
        <FormLabel>{translate('country')}</FormLabel>
        <FormSelect
          control={control}
          name="country"
          label={translate('country')}
          placeholder={translate('country')}
          options={countries?.data?.map((item) => ({
            id: item.id,
            name: `${item.name} ${item.flag}`,
          }))}
        />
      </Grid>
      <Grid item xs={6}>
        <FormInput label={translate('state')} name="state" required control={control} />
      </Grid>
      <Grid item xs={6}>
        <FormInput label={translate('city')} name="city" required control={control} />
      </Grid>
      <Grid item xs={6}>
        <FormInput label={translate('address')} name="address" required control={control} />
      </Grid>
      <Grid item xs={6}>
        <FormInput label={translate('zip')} name="zip" required control={control} />
      </Grid>
      <Grid item xs={24}>
        <FormInput label={translate('card_name')} name="cardName" required control={control} />
      </Grid>
      <Grid item xs={12}>
        <FormCardInput label={translate('card_number')} name="cardNumber" required control={control} />
      </Grid>
      <Grid item xs={3}>
        <FormLabel>{translate('exp_month')}</FormLabel>
        <FormSelect label={translate('exp_month')} name="expMonth" required control={control} options={months} />
      </Grid>
      <Box
        style={{
          display: 'flex',
          position: 'relative',
          left: '7px',
          top: '45px',
        }}
      >
        {'/'}
      </Box>
      <Grid item xs={3}>
        <FormLabel>{translate('exp_year')}</FormLabel>
        <FormSelect name="expYear" required control={control} options={years} />
      </Grid>
      <Grid item xs={1.75} />
      <Grid item xs={4} alignSelf="flex-end">
        <FormLabel>{translate('card_cvv')}</FormLabel>
        <FormPasswordField label={translate('card_cvv')} maxLength={3} name="cardCVV" control={control} />
      </Grid>
      <Grid item xs={12}>
        <LoadingButton
          loading={loading}
          disabled={!areAllFieldsFilled}
          variant="contained"
          color="success"
          onClick={onClick}
          sx={{ mt: 3 }}
        >
          {translate('submit_deposit')}
        </LoadingButton>
      </Grid>
    </>
  );
};

export default BorderLess;
