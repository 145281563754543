import { IPaged } from 'types';

import _Request from '../Request';

import {
  ICasinoCategory,
  IGameDetails,
  IGame,
  ICasinoGameFilter,
  ICasinoProvider,
  IStartGameProps,
  IGamesTag,
  TVBetGame,
  BetGamesTVGame,
} from './types';
import { DataItemType } from 'store/types';
class CasinoService extends _Request {
  constructor() {
    super('');
  }

  getGames(params: ICasinoGameFilter = {}) {
    return this.send<IPaged<IGame>>(
      {
        method: 'get',
        params: {
          orderKey: 'order',
          orderDirection: 'desc',
          ...params,
        },
      },
      '/games'
    ).then((games) => games);
  }

  getGamesBy(gameId: number) {
    return this.send<IGameDetails>(
      {
        method: 'get',
      },
      `/games/${gameId}`
    ).then((game) => game);
  }

  getRetrievingGames() {
    return this.send<{ data: DataItemType[] }>(
      {
        method: 'get',
      },
      '/games'
    ).then((games) => games.data);
  }

  getRetrievingCategories() {
    return this.send<DataItemType[]>(
      {
        method: 'get',
      },
      '/categories'
    ).then((categories) => categories);
  }

  getCategories() {
    return this.send<ICasinoCategory[]>({ method: 'get' }, '/casinoCategories');
  }

  getProviders(category) {
    return this.send<ICasinoProvider[]>({ method: 'get', params: { category } }, `/games/providers`);
  }

  startGame(data: IStartGameProps) {
    return this.send<{ url: string } & Partial<TVBetGame | BetGamesTVGame>>(
      { data: { ...data, exit_url: '', https: false } },
      '/casino/startGame'
    ).then(({ url, ...rest }) => {
      // TEMP SOLUTION
      if ((rest as TVBetGame)?.aggregator?.name === 'TvBet') {
        return { url, ...rest, tvBet: true };
      } else if ((rest as BetGamesTVGame)?.aggregator?.name === 'BetGames TV') {
        return { url, ...rest, betGamesTV: true };
      }
      return { url };
    });
  }

  startDemo(data: IStartGameProps) {
    return this.send<{ url: string } & Partial<TVBetGame>>(
      { data: { ...data, exit_url: '', https: false } },
      '/casino/startDemo'
    ).then(({ url, ...rest }) => ({ url, ...rest }));
  }

  getFavoritesAndRecents() {
    return this.send<{ favorites: IGame[]; recent: IGame[] }>(
      {
        method: 'get',
      },
      '/games'
    ).then(({ favorites, recent }) => ({ favorites, recent }));
  }

  addToFavorite(gameId: number) {
    return this.send(
      {
        method: 'put',
        data: {
          gameIds: [gameId],
        },
      },
      '/player/favorites'
    ).then((games) => games);
  }

  removeFromFavorites(gameId: number) {
    return this.send(
      {
        method: 'delete',
      },
      `/player/favorites/${gameId}`
    ).then((games) => games);
  }

  getGamesByURI(uri: string) {
    return this.send<IGame[]>({ method: 'get', params: { uri } }, '/casino/games');
  }

  getGamesTags(category) {
    return this.send<IGamesTag[]>({ method: 'get', params: { category } }, '/tags');
  }
}

export default new CasinoService();
