export default {
  paymentMethod: ({ selected }) => ({
    py: 2,
    px: 2,
    height: 80,
    width: '100%',
    textAlign: 'left',
    borderRadius: '10px',
    color: 'text.primary',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '&, &:hover, &:active': {
      bgcolor: selected ? 'primary.main' : 'secondary.dark',
    },
  }),
  icon: {
    color: 'primary.main',
  },
  paymentMethodText: { fontSize: 12, '& > span': { opacity: 0.5, mr: 1 } },
  paymentMethodDivider: {
    mx: '2px',
    width: 2,
    height: 2,
    opacity: 0.5,
  },
};
