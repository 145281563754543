import useMount from 'hooks/useMount';

declare global {
  interface Window {
    onTelegramAuth: (user) => void;
  }
}

const containerId = 'telegram-login-container';

const TelegramLogin = ({ botName, onSuccess }: { botName: string; onSuccess: (user) => void }) => {
  useMount(() => {
    window.onTelegramAuth = onSuccess;

    const script = document.createElement('script');
    script.src = `https://telegram.org/js/telegram-widget.js?container=${containerId}`;
    script.setAttribute('data-telegram-login', botName);
    script.setAttribute('data-size', 'medium');
    script.setAttribute('data-userpic', 'false');
    script.setAttribute('data-onauth', 'onTelegramAuth(user)');
    script.setAttribute('data-request-access', 'write');
    script.async = true;
    document.getElementById(containerId).appendChild(script);
  });

  return <div id={containerId} style={{ display: 'flex', justifyContent: 'center' }} />;
};

export default TelegramLogin;
