import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Sport: FC<SvgIconProps> = (props) => (
  <SvgIcon width="100%" height="100%" viewBox="0 0 128 128" {...props}>
    <g>
      <path d="m13.755 71.727a49.932 49.932 0 1 0 89.094-30.921l2.96-2.96a5.381 5.381 0 0 0 6.858-8.238l-6.043-6.042a5.381 5.381 0 0 0 -8.239 6.855l-2.867 2.867a49.7 49.7 0 0 0 -26.578-11.214v-4.146a4.742 4.742 0 0 0 4.407-4.716v-2.912a4.756 4.756 0 0 0 -4.75-4.75h-9.819a4.756 4.756 0 0 0 -4.75 4.75v2.909a4.741 4.741 0 0 0 4.412 4.716v4.145a50 50 0 0 0 -44.685 49.657zm87.726-45.686a1.884 1.884 0 0 1 2.668 0l6.043 6.042a1.888 1.888 0 0 1 0 2.668 1.933 1.933 0 0 1 -2.668 0l-6.043-6.043a1.889 1.889 0 0 1 0-2.667zm-.692 6.925 2.476 2.475-2.681 2.68c-.786-.863-1.612-1.689-2.456-2.5zm-43.261-19.754v-2.912a1.252 1.252 0 0 1 1.25-1.25h9.822a1.252 1.252 0 0 1 1.25 1.25v2.909a1.251 1.251 0 0 1 -1.25 1.25h-9.822a1.251 1.251 0 0 1 -1.25-1.247zm4.412 4.75h3.5v3.878a34.335 34.335 0 0 0 -3.5 0zm1.747 7.338a46.432 46.432 0 1 1 -46.432 46.427 46.485 46.485 0 0 1 46.432-46.427z" />
      <path d="m63.687 78.568a6.837 6.837 0 0 0 1.753-13.446v-16.122a1.75 1.75 0 0 0 -3.5 0v16.121a6.837 6.837 0 0 0 1.747 13.447zm-.017-10.18h.02.018a3.342 3.342 0 1 1 -.038 0z" />
      <path d="m23.756 71.727a39.932 39.932 0 1 0 79.863 0 39.932 39.932 0 1 0 -79.863 0zm7.2-1.75h-3.656a36.294 36.294 0 0 1 9.43-22.732l2.579 2.578a1.75 1.75 0 0 0 2.474-2.475l-2.578-2.578a36.292 36.292 0 0 1 22.732-9.43v3.66a1.75 1.75 0 0 0 3.5 0v-3.66a36.294 36.294 0 0 1 22.733 9.43l-2.578 2.578a1.75 1.75 0 0 0 2.474 2.475l2.578-2.578a36.287 36.287 0 0 1 9.431 22.732h-3.661a1.75 1.75 0 0 0 0 3.5h3.661a36.29 36.29 0 0 1 -9.431 22.733l-2.578-2.579a1.75 1.75 0 0 0 -2.474 2.475l2.578 2.579a36.294 36.294 0 0 1 -22.733 9.43v-3.66a1.75 1.75 0 0 0 -3.5 0v3.66a36.287 36.287 0 0 1 -22.732-9.431l2.578-2.578a1.75 1.75 0 0 0 -2.474-2.475l-2.579 2.578a36.292 36.292 0 0 1 -9.43-22.732h3.66a1.75 1.75 0 1 0 0-3.5z" />
    </g>
  </SvgIcon>
);

export default Sport;
