import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState, Status } from 'store/types';

import { CasinoService, IGame } from 'api/casino';
import { LOGOUT_ACTION } from 'store/constants';

export type FavoritesAndRecentsStateType = {
  data: {
    favorites: IGame[];
    recent: IGame[];
  };
  status: Status;
};

type FavoritesActionsType = {
  read: () => void;
  isFavorite: (id: number) => boolean;
  toggleFavorite: (item: IGame) => void;
};

const initialState: FavoritesAndRecentsStateType = {
  data: {
    favorites: [],
    recent: [],
  },
  status: 'idle',
};

const readFavoritesGames = createAsyncThunk('favoritesAndRecents/read', async () =>
  CasinoService.getFavoritesAndRecents()
);

const favoritesSlice = createSlice({
  initialState,
  name: 'favorites',
  extraReducers: (builder) =>
    builder
      .addCase(readFavoritesGames.pending.type, (state): void => {
        state.status = 'loading';
      })
      .addCase(
        readFavoritesGames.fulfilled.type,
        (
          state,
          action: PayloadAction<{
            favorites: IGame[];
            recent: IGame[];
          }>
        ): void => {
          const data = action.payload || initialState.data;

          state.data = data;
          state.status = 'success';
        }
      )
      .addCase(readFavoritesGames.rejected.type, (state): void => {
        state.status = 'failed';
        state.data = initialState.data;
      })
      .addCase(LOGOUT_ACTION, (): FavoritesAndRecentsStateType => initialState),
  reducers: {
    toggle: (state, action) => {
      const item = action.payload;
      const index = state.data.favorites.findIndex((i) => item.id === i.id);

      if (index > -1) {
        CasinoService.removeFromFavorites(item.id);
        state.data.favorites = state.data.favorites.filter((i) => i.id !== item.id);
      } else {
        CasinoService.addToFavorite(item.id);
        state.data.favorites = [item, ...state.data.favorites];
      }

      return state;
    },
  },
});

const useFavoritesAndRecents = (): [FavoritesAndRecentsStateType, FavoritesActionsType] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.favorites);

  const actions = {
    toggleFavorite: (item) => {
      dispatch(favoritesSlice.actions.toggle(item));
    },
    isFavorite: (id) => {
      return state?.data?.favorites.findIndex((i) => id === i.id) > -1;
    },
    read: () => {
      dispatch(readFavoritesGames());
    },
  };

  return [state, actions];
};

export { favoritesSlice };
export default useFavoritesAndRecents;
