import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Stack, Button } from '@mui/material';

import CloseIcon from 'components/common/svg/close';
import FormInput from 'components/common/Form/Input';

import { SettingsService } from 'api/settings';

import useToaster from 'hooks/useToaster';
import useTranslates from 'utils/translate';
import { sendForgotPassword } from 'config/resolvers';

interface ForgotPasswordProps {
  open: boolean;
  onClose: () => void;
}

const ForgotPassword: FC<ForgotPasswordProps> = ({ open, onClose }) => {
  const { translate } = useTranslates();

  const notify = useToaster();
  const { control, reset, handleSubmit } = useForm({
    resolver: sendForgotPassword,
  });

  useEffect(() => {
    reset({});
  }, [open]);

  const onForgotPassword = (data) => {
    SettingsService.forgotPassword(data).then(() => {
      onClose();
      notify({
        message: translate('change_password_url_successfully_send'),
      });
    });
  };

  return (
    <Dialog keepMounted open={open} onClose={() => onClose()}>
      <DialogTitle>
        <Typography fontSize={18} fontWeight={600}>
          {translate('forgot_password_title')}
        </Typography>
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack gap={3} key={open ? 1 : 2}>
          <Typography>{translate('enter_provide_email_address')}</Typography>
          <FormInput control={control} name="email" placeholder={translate('email_address')} />
          <Button fullWidth variant="contained" onClick={handleSubmit(onForgotPassword)}>
            {translate('recover_password')}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ForgotPassword;
