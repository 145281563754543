import { FC, Fragment } from 'react';

import useCategories from 'store/categories';
import useSettings, { IWebsiteConfigs } from 'store/settings';
import useTranslates from 'utils/translate';

import MenuItem from '../MenuItem';

interface ICategoryLinksProps {
  onItemClick: () => void;
}

const CategoryLinks: FC<ICategoryLinksProps> = ({ onItemClick }) => {
  const [categories] = useCategories();
  const { translate } = useTranslates();
  const [
    {
      data: { meta = {} as IWebsiteConfigs },
    },
  ] = useSettings();
  return (
    <Fragment>
      {meta.showGamesInTopNavigation && (
        <MenuItem key="/games" to="/games" onClick={onItemClick}>
          {translate('games')}
        </MenuItem>
      )}
      {categories?.data?.map((item) => {
        const isDirectGameId = !Number.isNaN(Number(item.uri))
        const path = isDirectGameId ? `/game/${item.uri}` : `/games/${item.uri}`;

        return (
          <MenuItem key={item.key} to={path} onClick={onItemClick}>
            {item.name}
          </MenuItem>
        );
      })}
    </Fragment>
  );
};

export default CategoryLinks;
