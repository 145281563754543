export default {
  menuPaper: {
    sx: ({ palette }) => ({
      width: 360,
      boxShadow: 'none',
      borderRadius: '10px',
      bgcolor: 'background.paper',
      border: `2px solid ${palette.divider}`,
    }),
  },
  menuList: {
    sx: ({ palette }) => ({
      p: 0,
      '& li': {
        height: 67,
      },
      '& li:not(:last-child)': {
        borderBottom: `2px solid ${palette.divider}`,
      },
    }),
  },
};
