export default {
  textField: ({ palette }) => ({
    height: 42,
    maxWidth: '100%',
    '& .MuiInputBase-root': {
      borderRadius: '6px !important',
    },
    '&:hover fieldset': {
      borderColor: `${palette.secondary.light} !important`,
    },
    '&:not(.Mui-focused):not(.Mui-error) fieldset': {
      borderColor: `${palette.secondary.light} !important`,
    },
    '&:not(.Mui-focused):not(.Mui-error):hover fieldset': {
      borderColor: `${palette.secondary.light} !important`,
    },
  }),
  input:
    ({ isMobile }) =>
    ({ palette }) => ({
      background: 'transparent',
      '& input': {
        height: 40,
        background: 'transparent',
        color: `${palette.text.primary} !important`,
        '&::placeholder': {
          textOverflow: !isMobile && 'ellipsis !important',
          color: !isMobile && `${palette.text.primary} !important`,
        },
      },
    }),
  messageRoot: ({ isMy }) => ({
    my: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: isMy ? 'flex-end' : 'flex-start',
  }),
  messageItem: ({ isMy }) => ({
    p: 1,
    mt: 0.5,
    color: isMy ? 'text.inherit' : 'text.primary',
    bgcolor: isMy ? 'primary.main' : 'secondary.dark',
    borderRadius: isMy ? '10px 0 10px 10px' : '0 10px 10px 10px',
  }),
  requestsList: ({ isMobile }) => ({
    minWidth: isMobile ? '100%' : '240px',
    overflow: 'auto',
    height: '100%',
    bgcolor: 'secondary.dark',
  }),
  requestItem:
    ({ selected }) =>
    ({ palette }) => ({
      px: 2,
      py: 2,
      width: '100%',
      cursor: 'pointer',
      borderBottom: `1px solid ${palette.divider}`,
      bgcolor: selected && 'secondary.light',
    }),
  messengerContent: { width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' },
};
