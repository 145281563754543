import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Stack, Button } from '@mui/material';

import CloseIcon from 'components/common/svg/close';
import FormPasswordField from 'components/common/Form/Password';

import { SettingsService } from 'api/settings';

import useToaster from 'hooks/useToaster';
import useTranslates from 'utils/translate';
import { resetPasswordResolver } from 'config/resolvers';

interface ResetPasswordProps {
  open: boolean;
  onClose: () => void;
}

const ResetPassword: FC<ResetPasswordProps> = ({ open, onClose }) => {
  const { translate } = useTranslates();

  const notify = useToaster();
  const navigation = useNavigate();
  const [params] = useSearchParams();
  const { control, reset, handleSubmit } = useForm({
    resolver: resetPasswordResolver,
  });

  useEffect(() => {
    reset({});
  }, [open]);

  const onUpdatePassword = (data) => {
    SettingsService.resetPassword({
      ...data,
      hash: params.get('token'),
      email: params.get('email'),
    }).then(() => {
      onClose();
      navigation('/');
      notify({
        message: translate('password_successfully_changed'),
      });
    });
  };

  return (
    <Dialog keepMounted open={open} onClose={() => onClose()}>
      <DialogTitle>
        <Typography fontSize={18} fontWeight={600}>
          {translate('new_password')}
        </Typography>
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack gap={2} mb={3} key={open ? 1 : 2}>
          <Typography>{translate('password_must_contain')}</Typography>
          <FormPasswordField control={control} name="password" label={translate('enter_password')} />
          <FormPasswordField control={control} name="confirm_password" label={translate('repeat_password')} />
        </Stack>
        <Button fullWidth variant="contained" onClick={handleSubmit(onUpdatePassword)}>
          {translate('save_changes')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ResetPassword;
