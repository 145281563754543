import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState, Status } from 'store/types';

import { DomainService } from 'api/domain';

type DomainStateType = {
  data: {
    telegramBotName?: string;
  };
  status: Status;
};

type DomainActionsType = {
  read: () => void;
};

const initialState: DomainStateType = {
  data: {},
  status: 'idle',
};

const readDomainSettingsThunk = createAsyncThunk('domain-settings/read', async () => DomainService.getSettings());

const domainSlice = createSlice({
  initialState,
  name: 'domain',
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(readDomainSettingsThunk.pending.type, (state: DomainStateType): void => {
        state.status = 'loading';
      })
      .addCase(
        readDomainSettingsThunk.fulfilled.type,
        (state: DomainStateType, action: PayloadAction<DomainStateType['data']>): void => {
          state.status = 'success';
          state.data = action.payload || initialState.data;
        }
      )
      .addCase(readDomainSettingsThunk.rejected.type, (state: DomainStateType): void => {
        state.status = 'failed';
        state.data = initialState.data;
      }),
});

const useDomainSettings = (): [DomainStateType, DomainActionsType] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.domain);

  const actions = {
    read: () => {
      dispatch(readDomainSettingsThunk());
    },
  };

  return [state, actions];
};

export { domainSlice };
export default useDomainSettings;
