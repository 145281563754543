import type { BuiltInCreditCardType, CardCollection } from '../types';
import ArcaIcon from './icons/arca.svg';
import VisaIcon from './icons/visa.svg';
import AmexIcon from './icons/amex.svg';
import MaestroIcon from './icons/maestro.svg';
import MastercardIcon from './icons/mastercard.svg';

const cardTypes: CardCollection = {
  arca: {
    type: 'arca',
    niceType: 'Armenian Card',
    patterns: [9],
    gaps: [4, 8, 12],
    lengths: [16, 18, 19],
    icon: ArcaIcon,
    code: {
      name: 'CVV',
      size: 3,
    },
  } as BuiltInCreditCardType,
  visa: {
    type: 'visa',
    patterns: [4],
    icon: VisaIcon,
    niceType: 'Visa',
    gaps: [4, 8, 12],
    lengths: [16, 18, 19],
    code: {
      name: 'CVV',
      size: 3,
    },
  } as BuiltInCreditCardType,
  mastercard: {
    type: 'mastercard',
    icon: MastercardIcon,
    niceType: 'Mastercard',
    patterns: [[51, 55], [2221, 2229], [223, 229], [23, 26], [270, 271], 2720],
    gaps: [4, 8, 12],
    lengths: [16],
    code: {
      name: 'CVC',
      size: 3,
    },
  } as BuiltInCreditCardType,
  'american-express': {
    lengths: [15],
    gaps: [4, 10],
    icon: AmexIcon,
    patterns: [34, 37],
    type: 'american-express',
    niceType: 'American Express',
    code: {
      name: 'CID',
      size: 4,
    },
  } as BuiltInCreditCardType,
  'diners-club': {
    niceType: 'Diners Club',
    type: 'diners-club',
    patterns: [[300, 305], 36, 38, 39],
    gaps: [4, 10],
    lengths: [14, 16, 19],
    code: {
      name: 'CVV',
      size: 3,
    },
  } as BuiltInCreditCardType,
  discover: {
    niceType: 'Discover',
    type: 'discover',
    patterns: [6011, [644, 649], 65],
    gaps: [4, 8, 12],
    lengths: [16, 19],
    code: {
      name: 'CID',
      size: 3,
    },
  } as BuiltInCreditCardType,
  unionpay: {
    niceType: 'UnionPay',
    type: 'unionpay',
    patterns: [
      620,
      [62100, 62182],
      [62184, 62187],
      [62185, 62197],
      [62200, 62205],
      [622010, 622999],
      622018,
      [62207, 62209],
      [623, 626],
      6270,
      6272,
      6276,
      [627700, 627779],
      [627781, 627799],
      [6282, 6289],
      6291,
      6292,
      810,
      [8110, 8131],
      [8132, 8151],
      [8152, 8163],
      [8164, 8171],
    ],
    gaps: [4, 8, 12],
    lengths: [14, 15, 16, 17, 18, 19],
    code: {
      name: 'CVN',
      size: 3,
    },
  } as BuiltInCreditCardType,
  maestro: {
    icon: MaestroIcon,
    niceType: 'Maestro',
    type: 'maestro',
    patterns: [493698, [500000, 504174], [504176, 506698], [506779, 508999], [56, 59], 63, 67, 6],
    gaps: [4, 8, 12],
    lengths: [12, 13, 14, 15, 16, 17, 18, 19],
    code: {
      name: 'CVC',
      size: 3,
    },
  } as BuiltInCreditCardType,
  mir: {
    niceType: 'Mir',
    type: 'mir',
    patterns: [[2200, 2204]],
    gaps: [4, 8, 12],
    lengths: [16, 17, 18, 19],
    code: {
      name: 'CVP2',
      size: 3,
    },
  } as BuiltInCreditCardType,
  hiper: {
    niceType: 'Hiper',
    type: 'hiper',
    patterns: [637095, 63737423, 63743358, 637568, 637599, 637609, 637612],
    gaps: [4, 8, 12],
    lengths: [16],
    code: {
      name: 'CVC',
      size: 3,
    },
  } as BuiltInCreditCardType,
  hipercard: {
    niceType: 'Hipercard',
    type: 'hipercard',
    patterns: [606282],
    gaps: [4, 8, 12],
    lengths: [16],
    code: {
      name: 'CVC',
      size: 3,
    },
  } as BuiltInCreditCardType,
};

export default cardTypes;
