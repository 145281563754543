import { createContext, useCallback, useState } from 'react';

import noop from 'lodash/noop';

import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertColor, AlertTitle, IconButton, Stack, Typography } from '@mui/material';

import useTranslates from 'utils/translate';
import sx from './styles';

interface ToasterProviderProps {
  children?: JSX.Element | JSX.Element[];
}

type NotificationData = {
  id?: number;
  type?: AlertColor;
  title?: string;
  message?: string;
};

interface ToasterProps {
  notification?: NotificationData;
  onClose: (id?: number) => void;
}

const ToasterContext = createContext<[NotificationData[], (not?: NotificationData) => void]>([[], noop]);

const TIMEOUT = 3600;
const SHOW_COUNT = 5;

const Toaster = ({ notification, onClose }: ToasterProps): JSX.Element => {
  const { translate } = useTranslates();

  return (
    <Alert
      severity={notification?.type}
      action={
        <IconButton aria-label="close" color="inherit" size="small" onClick={() => onClose(notification?.id)}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      <AlertTitle>{translate(notification?.title || notification?.type)}</AlertTitle>
      <Typography>{notification?.message}</Typography>
    </Alert>
  );
};

const ToasterProvider = ({ children }: ToasterProviderProps): JSX.Element => {
  const [notifications, setNotifications] = useState<NotificationData[]>([]);

  const remove = useCallback((notId?: number) => {
    setNotifications((prev) => prev.filter(({ id }) => id !== notId));
  }, []);

  const setter = useCallback(
    (not) => {
      const notId = Date.now();
      setNotifications((prev) => {
        if (not.message && prev[prev?.length - 1]?.message !== not.message) {
          const next = [
            ...prev,
            {
              id: notId,
              type: 'success',
              ...not,
            },
          ];
          if (next.length >= SHOW_COUNT) {
            next.pop();
          }
          return next;
        }
        return prev;
      });
      setTimeout(() => remove(notId), not.deleteDelay || TIMEOUT);
    },
    [remove]
  );

  return (
    <ToasterContext.Provider value={[notifications, setter]}>
      <Stack sx={sx.notificationsList} gap={2}>
        {notifications?.length > 0 &&
          notifications.map((not) => <Toaster key={not.id} notification={not} onClose={remove} />)}
      </Stack>
      {children}
    </ToasterContext.Provider>
  );
};

export { ToasterContext };
export default ToasterProvider;
