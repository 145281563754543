import { useState } from 'react';

import { Drawer, MenuItem, ListItemText, Menu, Box, Typography, IconButton, Badge } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import NotificationsIcon from 'components/common/icons/Notification';

import useScreen from 'hooks/useScreen';
import useTranslates from 'utils/translate';

import sx from './styles';
import useSettings, { IWebsiteConfigs } from 'store/settings';

const Notifications = () => {
  const { isMobile } = useScreen();
  const { translate } = useTranslates();

  const [anchorEl, setAnchorEl] = useState(null);
  const [
    {
      data: { meta = {} as IWebsiteConfigs },
    },
  ] = useSettings();

  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const content = (
    <Box>
      <Box p={2} display="flex" alignItems="center" justifyContent="space-between" bgcolor="background.default">
        <Typography fontWeight={700} color="text.primary" fontSize={isMobile ? 18 : 22}>
          {translate('notifications')}
        </Typography>
        {isMobile && (
          <IconButton color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      <MenuItem sx={{ height: '90px !important' }}>
        <ListItemText
          primary={
            <Typography fontSize={14} fontWeight={600} mb={2}>
              Notification Title
            </Typography>
          }
          secondary={
            <Typography fontSize={12} fontWeight={400}>
              Subtitle
            </Typography>
          }
        />
      </MenuItem>
    </Box>
  );

  if (!meta.showNoticiations) return null;

  return (
    <Box>
      <IconButton color="inherit" onClick={handleOpen}>
        <Badge badgeContent={4} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      {isMobile ? (
        <Drawer open={open} anchor="top" onClose={handleClose} sx={{ zIndex: 1300 }} PaperProps={sx.menuList}>
          {content}
        </Drawer>
      ) : (
        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          PaperProps={sx.menuPaper}
          MenuListProps={sx.menuList}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {content}
        </Menu>
      )}
    </Box>
  );
};

export default Notifications;
