import { FC, ForwardRefExoticComponent, ReactNode, Ref, RefAttributes, forwardRef } from 'react';

import { Box, SxProps } from '@mui/material';

import useScreen from 'hooks/useScreen';

const dividers = {
  '4:3': '75%',
  '8:5': '62.5%',
  '3:2': '66.66%',
  '16:9': '56.25%',
  '3:4': '125%',
  '9:16': '156.25%',
};

const widthPercents = {
  8: '30%',
  4: '61.3%',
  6: '40.2%',
  12: '19.8%',
};

const mobileWidthPercents = {
  8: '30%',
  4: '61.3%',
  6: '40.2%',
  12: '40%',
};

interface AspectRatioProps {
  size?: number;
  divide?: string;
  children: ReactNode;
  ref?: Ref<HTMLDivElement>;
  spacing?: number | string;
  widthPercent?: number | string;
}

const AspectRatio: ForwardRefExoticComponent<Omit<AspectRatioProps, 'ref'> & RefAttributes<HTMLDivElement>> =
  forwardRef(({ spacing = 1, widthPercent, divide = '4:3', size = 1, children }, ref) => {
    const { isMobile } = useScreen();

    const _widthPercent = isMobile ? mobileWidthPercents[widthPercent] : widthPercents[widthPercent];

    return (
      <Box
        ref={ref}
        sx={{
          flexBasis: '100%',
          position: 'relative',
          maxWidth: `calc(100% / ${size})`,
          paddingTop: `calc(${_widthPercent || dividers[divide] || dividers['4:3']} / ${size})`,
        }}
      >
        <Box
          sx={{
            p: spacing,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          {children}
        </Box>
      </Box>
    );
  });

interface AspectRatioWrapperProps {
  sx?: SxProps;
  children: ReactNode;
  spacing?: number;
}

const AspectRatioWrapper: FC<AspectRatioWrapperProps> = ({ spacing = -1, children, sx }) => (
  <Box display="flex" flexWrap="wrap" m={spacing} sx={sx}>
    {children}
  </Box>
);

export { AspectRatioWrapper };
export default AspectRatio;
