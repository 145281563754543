import { ReactElement } from 'react';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import useCustomTheme from './customTheme';

interface ThemeProviderProps {
  children: JSX.Element | JSX.Element[];
}

const ThemeProvider = ({ children }: ThemeProviderProps): ReactElement => {
  const theme = useCustomTheme();

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
};

export default ThemeProvider;
