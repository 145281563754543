import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import pick from 'lodash/pick';

import { Box, Dialog, DialogTitle, DialogContent, IconButton, Typography, Stack, Button } from '@mui/material';

import CloseIcon from 'components/common/svg/close';
import Form from 'components/common/Form/Form';
import FormInput from 'components/common/Form/Input';
import FormSelect from 'components/common/Form/Select';
import FormPhoneInput from 'components/common/Form/PhoneInput';

import AccountIcon from 'components/common/icons/Account';

import VerifiedIcon from 'components/common/svg/Verified';
import AwaitingIcon from 'components/common/svg/AwaitingIcon';
import FormDatePicker from 'components/common/Form/DatePicker';

import useCountries from 'store/countries';
import useCurrentUser from 'store/currentUser';
import useSelectedLanguage from 'store/language';

import { ProfileService } from 'api/profile';

import useToaster from 'hooks/useToaster';
import useTranslates from 'utils/translate';

import { updateUserResolver } from 'config/resolvers';

import sx from './styles';
import { VerificationStatus } from 'types/auth';

interface AccountSettingsPopupProps {
  open: boolean;
  onClose: () => void;
}

const AccountSettingsPopup: FC<AccountSettingsPopupProps> = ({ open, onClose }) => {
  const notify = useToaster();
  const { translate } = useTranslates();
  const [{ isRtl }] = useSelectedLanguage();

  const [countries] = useCountries();
  const [currentUser, currentUserActions] = useCurrentUser();

  const { control, reset, handleSubmit } = useForm({
    resolver: updateUserResolver,
  });

  useEffect(() => {
    reset({
      ...currentUser?.data,
    });
  }, [currentUser, open]);

  const onUpdateUserData = (data) => {
    ProfileService.update(pick(data, ['firstName', 'lastName', 'birthDate', 'country', 'city', 'addressOne'])).then(
      (res) => {
        if (res?.id) {
          onClose();
          currentUserActions.set(res);
          notify({
            message: translate('profile_successfully_updated'),
          });
        }
      }
    );
  };

  const verification_status = {
    [VerificationStatus.VERIFIED]: <VerifiedIcon />,
    [VerificationStatus.AWAITING_VERIFICATION]: <AwaitingIcon />,
  }[currentUser?.data?.verificationStatus];

  return (
    <Dialog keepMounted open={open} onClose={onClose}>
      <DialogTitle>
        <Typography fontSize={18} fontWeight={600}>
          {translate('personal_settings')}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box bgcolor="background.paper">
        <Box py={1} pl={!isRtl && 15} pr={isRtl && 15} position="relative" bgcolor="background.default">
          <Box sx={sx.head({ isRtl })}>
            <AccountIcon />
          </Box>
          <Stack direction="row" gap={1} alignItems="center">
            {verification_status && (
              <Box display="flex" alignItems="center" mr={1}>
                {verification_status}
              </Box>
            )}
            <Typography fontSize={14} fontWeight={600} color="primary.main">
              {currentUser.data?.username}
            </Typography>
          </Stack>
        </Box>
        <Box sx={sx.userInfo({ isRtl })}>
          <Typography fontSize={18} fontWeight={600} noWrap>
            {currentUser.data?.firstName} <br />
            {currentUser.data?.lastName}
          </Typography>
        </Box>
      </Box>
      <DialogContent>
        {open && (
          <Form onSubmit={handleSubmit(onUpdateUserData)}>
            <Stack gap={2}>
              <FormInput control={control} name="firstName" placeholder={translate('name')} />
              <FormInput control={control} name="lastName" placeholder={translate('surname')} />
              <FormInput control={control} disabled name="email" placeholder={translate('email_address')} />
              <FormPhoneInput disabled name="phoneNumber" control={control} />
              <FormDatePicker disabled control={control} name="birthDate" />
              <FormSelect
                control={control}
                name="country"
                placeholder={translate('country')}
                options={countries?.data?.map((item) => ({
                  id: item.id,
                  name: item.name,
                  icon: item.flag,
                }))}
              />
              <FormInput control={control} name="city" placeholder={translate('city')} />
              <FormInput control={control} name="addressOne" placeholder={translate('address')} />
              <Button variant="contained" type="submit">
                {translate('save_changes')}
              </Button>
            </Stack>
          </Form>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AccountSettingsPopup;
