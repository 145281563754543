import { FC, useCallback, useMemo } from 'react';
import { Control, FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { PaymentMethod, PaymentMethodExecutionTypes } from 'types';
import AutomaticDeposit from './AutomaticDeposit';
import AutomaticWithdraw from './AutomaticWithdraw';
import ManualDeposit from './ManualDeposit';
import ManualWithdraw from './ManualWithdraw';

enum TicketTransactionTypeEnum {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
}

const InHouse: FC<{
  isDeposit: boolean;
  onClose: () => void;
  getValues: () => FieldValues;
  paymentMethod: PaymentMethod;
  control: Control<FieldValues, unknown>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  changeAmountFieldVisibility: (isVisible: boolean) => void;
  changeActionButtonVisibility: (isVisible: boolean) => void;
  changeActionButtonAvailability: (isAvailability: boolean) => void;
}> = ({
  watch,
  control,
  onClose,
  setValue,
  isDeposit,
  getValues,
  paymentMethod,
  changeAmountFieldVisibility,
  changeActionButtonVisibility,
  changeActionButtonAvailability,
}) => {
  const isAutomatic = paymentMethod.method.executionType === PaymentMethodExecutionTypes.automatic;
  const isAutomaticWithdraw = useMemo(() => isAutomatic && !isDeposit, [isAutomatic, isDeposit]);
  const isManualDeposit = useMemo(() => !isAutomatic && isDeposit, [isAutomatic, isDeposit]);
  const isManualWithdraw = useMemo(() => !isAutomatic && !isDeposit, [isAutomatic, isDeposit]);
  const isAutomaticDeposit = useMemo(() => isAutomatic && isDeposit, [isAutomatic, isDeposit]);

  const getInfoProperties = useCallback(
    () =>
      paymentMethod.meta.properties.reduce((values, property) => {
        const needToCaptureKey = isDeposit
          ? property.type === TicketTransactionTypeEnum.DEPOSIT
          : property.type === TicketTransactionTypeEnum.WITHDRAW;

        if (needToCaptureKey) {
          values[property.key] = getValues()[property.key];
        }
        return values;
      }, {}),
    [paymentMethod, watch]
  );

  setValue('infoProperties', getInfoProperties());

  if (isAutomaticWithdraw) {
    return (
      <AutomaticWithdraw
        control={control}
        watch={watch}
        changeAmountFieldVisibility={changeAmountFieldVisibility}
        changeActionButtonAvailability={changeActionButtonAvailability}
        changeActionButtonVisibility={changeActionButtonVisibility}
      />
    );
  }

  if (isManualDeposit) {
    return (
      <ManualDeposit
        watch={watch}
        control={control}
        setValue={setValue}
        onClose={onClose}
        paymentMethod={paymentMethod}
        getInfoProperties={getInfoProperties}
        changeAmountFieldVisibility={changeAmountFieldVisibility}
        changeActionButtonVisibility={changeActionButtonVisibility}
        changeActionButtonAvailability={changeActionButtonAvailability}
      />
    );
  }

  if (isManualWithdraw) {
    return (
      <ManualWithdraw
        watch={watch}
        control={control}
        setValue={setValue}
        paymentMethod={paymentMethod}
        getInfoProperties={getInfoProperties}
        changeAmountFieldVisibility={changeAmountFieldVisibility}
        changeActionButtonVisibility={changeActionButtonVisibility}
        changeActionButtonAvailability={changeActionButtonAvailability}
      />
    );
  }

  if (isAutomaticDeposit) {
    return (
      <AutomaticDeposit
        watch={watch}
        control={control}
        getValues={getValues}
        setValue={setValue}
        onClose={onClose}
        paymentMethod={paymentMethod}
        getInfoProperties={getInfoProperties}
        changeAmountFieldVisibility={changeAmountFieldVisibility}
        changeActionButtonVisibility={changeActionButtonVisibility}
      />
    );
  }

  return null;
};

export default InHouse;
