import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState, Status } from 'store/types';

import TournamentsService from 'api/tournaments';
import { LOGOUT_ACTION } from 'store/constants';

type TournamentStateType = {
  data: [];
  status: Status;
};

type TournamentActionsType = {
  read: () => void;
};

const initialState: TournamentStateType = {
  data: [],
  status: 'idle',
};

const readTournamentsThunk = createAsyncThunk('tournaments/read', async () => TournamentsService.getTournaments());

const tournamentsSlice = createSlice({
  initialState,
  name: 'tournaments',
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(readTournamentsThunk.pending.type, (state: TournamentStateType): void => {
        state.status = 'loading';
      })
      .addCase(readTournamentsThunk.fulfilled.type, (state: TournamentStateType, action: PayloadAction<[]>): void => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(readTournamentsThunk.rejected.type, (state: TournamentStateType): void => {
        state.status = 'failed';
        state.data = initialState.data;
      })
      .addCase(LOGOUT_ACTION, (): TournamentStateType => initialState),
});

const useTournaments = (): [TournamentStateType, TournamentActionsType] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.tournaments);

  const actions = {
    read: () => {
      dispatch(readTournamentsThunk());
    },
  };

  return [state, actions];
};

export { tournamentsSlice };
export default useTournaments;
