import _Request from '../Request';

import {
  IPromotionFilters,
  IPopupsFilters,
  ISettingsFilters,
  IMenusFilters,
  IPromotion,
  IMeta,
  IBannerItem,
} from './types';

class WebsiteService extends _Request {
  constructor() {
    super('/website');
  }

  getMenus(params: IMenusFilters) {
    return this.send({ params, method: 'get' }, '/menus');
  }

  getMetas() {
    return this.send<IMeta[]>({ method: 'get' }, '/metas');
  }

  getTranslations() {
    return this.send({ method: 'get' }, '/translations');
  }

  getSettings(params?: ISettingsFilters) {
    return this.send({ params, method: 'get' }, '/configuration');
  }

  getPopups(params: IPopupsFilters) {
    return this.send({ params, method: 'get' }, '/popups');
  }

  getBanners() {
    return this.send<IBannerItem[]>({ method: 'get' }, '/banners').then((data) => data || {});
  }

  getPromotions(params: Partial<IPromotionFilters>) {
    return this.send<IPromotion[]>({ params, method: 'get' }, '/promotions').then((data) => (data[0] ? data : []));
  }
}

export default new WebsiteService();
