export default {
  successIcon: {
    width: '18px !important',
    color: '#4CAF50 !important',
  },
  errorIcon: {
    color: '#F44336 !important',
    width: '18px !important',
    marginRight: '20px',
  },
  popperContent: {
    maxWidth: '431px',
    padding: '18px 24px',
    background: '#FFFFFF',
    boxShadow: '0px 3px 3px #0820321A',
    border: '1px solid #D9DDE0',
    borderRadius: '4px',
    marginLeft: '16px',
    position: 'relative',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      width: 10,
      height: 10,
      left: 0,
      top: 20,
      background: '#FFFFFF',
      transformOrigin: '0 0',
      transform: 'rotate(45deg)',
    },
    '&:before': {
      zIndex: 10,
      left: 1,
      border: '1px solid transparent',
    },
    '&:after': {
      zIndex: -10,
      boxShadow: '0px 3px 3px #0820321A',
      border: '1px solid #D9DDE0',
    },
  },
  helperText: {
    color: '#838F98',
    fontSize: '14px',
    lineHeight: '20px',
  },
};
