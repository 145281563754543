import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LocationsIcon = (props?: SvgIconProps) => (
  <SvgIcon width="100%" height="100%" viewBox="0 0 24 24" {...props}>
    <g id="Layer_2" data-name="Layer 2">
      <path d="m16 17.83a15.46 15.46 0 0 1 -1.08 1.25c1.17.29 1.82.7 1.84.92s-1.76 1.25-4.76 1.25-4.71-.89-4.75-1.25.67-.63 1.84-.92a15.46 15.46 0 0 1 -1.09-1.25c-1.32.44-2.25 1.17-2.25 2.17 0 1.89 3.24 2.75 6.25 2.75s6.25-.86 6.25-2.75c0-1-.93-1.73-2.25-2.17z" />
      <path d="m12 1.25a7.37 7.37 0 0 0 -7.36 7.36c0 3.64 5.67 11.39 7.36 11.39s7.36-7.75 7.36-11.39a7.37 7.37 0 0 0 -7.36-7.36zm0 10.86a3.5 3.5 0 1 1 3.5-3.5 3.5 3.5 0 0 1 -3.5 3.5z" />
    </g>
  </SvgIcon>
);

export default LocationsIcon;
