import { userStorage } from 'store/storage';
import { IPlayer } from 'types/auth';

import _Request from '../Request';

import { IUserUpdateProps, ICasinoHistoryFilters, ICasinoHistory, IUserDocumentData } from './types';

class ProfileService extends _Request {
  constructor() {
    super('/');
  }

  get() {
    return this.send<IPlayer>({ method: 'get' }, '/profile');
  }

  update(data: Partial<IUserUpdateProps>) {
    const playerId = userStorage.get()?.id;

    return this.send<IPlayer>({ data, method: 'PUT' }, `/players/${playerId}`).then((user) => {
      userStorage.set(user);
      return user;
    });
  }

  getCasinoHistory(params: Partial<ICasinoHistoryFilters>) {
    return this.send<ICasinoHistory>({ params, method: 'get' }, '/profile/history/casino');
  }

  setDocuments(data: IUserDocumentData) {
    return this.send<{ id: number }>({ data, method: 'post' }, '/profile/documents');
  }
}

export default new ProfileService();
