import { Grid } from '@mui/material';
import FormInput from 'components/common/Form/Input';
import { useEffect } from 'react';
import useTranslates from 'utils/translate';
import useMount from 'hooks/useMount';

const Withdraw = ({
  control,
  watch,
  changeActionButtonAvailability,
  changeActionButtonVisibility,
  changeAmountFieldVisibility,
}) => {
  const { translate } = useTranslates();
  const walletAddress = watch('walletAddress');
  const amount = watch('amount');

  useEffect(() => {
    changeActionButtonAvailability(!!walletAddress && !!amount);
  }, [walletAddress, amount]);

  useMount(() => {
    changeActionButtonVisibility(true);
    changeAmountFieldVisibility(true);
  });

  return (
    <Grid item xs={12}>
      <FormInput label={translate('wallet_address')} name="walletAddress" required control={control} />
    </Grid>
  );
};

export default Withdraw;
