import _Request from '../Request';

class WebsiteSportService extends _Request {
  constructor() {
    super('/');
  }

  getWebsiteSports() {
    return this.send({ method: 'get' }, '/available-sports');
  }
}

export default new WebsiteSportService();
