import useScreen from 'hooks/useScreen';

import MobileNavigation from './Mobile';
import DesktopNavigation from './Desktop';

const Navigation = (props) => {
  const { isMobile } = useScreen();

  if (isMobile) {
    return <MobileNavigation {...props} />;
  }

  return <DesktopNavigation {...props} />;
};

export default Navigation;
