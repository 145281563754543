import _Request from '../Request';

class PaymentMethodsService extends _Request {
  constructor() {
    super('/');
  }

  getPaymentMethods = (type: 'deposit' | 'withdraw') => {
    return this.send({ method: 'get' }, `/website/payment/methods?type=${type}`);
  };

  getPaymentMethodExtraInfo = <T>(data): Promise<T> => {
    return this.send({ method: 'post', data }, '/payment/provider/extra-info');
  };
  withdraw = (data): Promise<void> => {
    return this.send({ method: 'post', data }, '/payment/provider/withdraw');
  };
  deposit = (data): Promise<string> => {
    return this.send({ method: 'post', data }, `/payment/provider/deposit`);
  };
}

export default new PaymentMethodsService();
