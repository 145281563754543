import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LOGOUT_ACTION } from 'store/constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState, Status } from 'store/types';

import { SportService } from 'api/sport';
import { IKeepAliveSportSession } from 'api/sport/SportService';

export type SportEntity = {
  brandId?: string;
  themeName?: string;
  username?: string;
  token?: string;
  lng: string;
  cid?: string;
  lang?: string;
  sign?: string;
  timerId?: null | number;
  url?: string;
};

type SportStateType = {
  data: Record<string, SportEntity>;
  status: Status;
};

type SportActionsType = {
  read: (sportId: number) => void;
  keepAlive: (sportId: number) => void;
};

const initialState: SportStateType = {
  data: {},
  status: 'idle',
};

const readSportApplyThunk = createAsyncThunk('sport/read', async (sportId: number) =>
  SportService.getSportUrl(sportId)
);

const readSportKeepAliveThunk = createAsyncThunk('sport/keepAlive', async (options: IKeepAliveSportSession) =>
  SportService.keepAliveSession(options)
);

const sportSlice = createSlice({
  initialState,
  name: 'sport',
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(readSportApplyThunk.pending.type, (state: SportStateType): void => {
        state.status = 'loading';
      })
      .addCase(
        readSportApplyThunk.fulfilled.type,
        (state: SportStateType, action: PayloadAction<Record<string, SportEntity>>): void => {
          state.status = 'success';
          state.data = action.payload || initialState.data;
        }
      )
      .addCase(readSportApplyThunk.rejected.type, (state: SportStateType): void => {
        state.status = 'failed';
        state.data = initialState.data;
      })
      .addCase(readSportKeepAliveThunk.fulfilled.type, (state: SportStateType): void => {
        state.status = 'success';
      })
      .addCase(readSportKeepAliveThunk.rejected.type, (state: SportStateType): void => {
        state.status = 'failed';
      })
      .addCase(LOGOUT_ACTION, (): SportStateType => initialState),
});

const useSport = (): [SportStateType, SportActionsType] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.sport);

  const actions = {
    read: (sportId: number) => {
      dispatch(readSportApplyThunk(sportId));
    },
    keepAlive: (sportId: number) => {
      dispatch(
        readSportKeepAliveThunk({
          sportId,
          token: state.data[sportId]?.token || '',
          timerId: state.data[sportId]?.timerId || null,
        })
      );
    },
  };

  return [state, actions];
};

export { sportSlice };
export default useSport;
