import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState, Status } from 'store/types';

import { PaymentMethodsService } from 'api/paymentMethods';
import { LOGOUT_ACTION } from 'store/constants';
import { PaymentMethod } from 'types';

type PaymentMethodsType = {
  status: Status;
  methods: PaymentMethod[];
};

type RealBalanceActionsType = {
  read: (type: 'deposit' | 'withdraw') => void;
};

const initialState: PaymentMethodsType = {
  methods: [],
  status: 'idle',
};

const readPaymentMethodsThunk = createAsyncThunk('payment-methods/read', async (type: 'deposit' | 'withdraw') =>
  PaymentMethodsService.getPaymentMethods(type)
);

const paymentMethodsSlice = createSlice({
  initialState,
  name: 'paymentMethods',
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(readPaymentMethodsThunk.pending.type, (state: PaymentMethodsType): void => {
        state.status = 'loading';
      })
      .addCase(
        readPaymentMethodsThunk.fulfilled.type,
        (state: PaymentMethodsType, action: PayloadAction<{ data: PaymentMethod[]; count: number }>): void => {
          state.status = 'success';
          state.methods = action.payload.data;
        }
      )
      .addCase(readPaymentMethodsThunk.rejected.type, (state: PaymentMethodsType): void => {
        state.status = 'failed';
      })
      .addCase(LOGOUT_ACTION, (): PaymentMethodsType => initialState),
});

const usePaymentMethods = (): [PaymentMethodsType, RealBalanceActionsType] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.paymentMethods);

  const actions = {
    read: (type: 'deposit' | 'withdraw') => {
      dispatch(readPaymentMethodsThunk(type));
    },
  };

  return [state, actions];
};

export { paymentMethodsSlice };
export default usePaymentMethods;
