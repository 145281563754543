import useDialog from 'store/dialog';

import SignInPopup from './SignIn';
import SignUpPopup from './SignUp';
import MessagesDialog from './Messages';
import ChangePasswordPopup from './ChangePassword';
import AccountSettingsPopup from './AccountSettings';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import EmailConfirmation from './EmailVerification';
import PaymentDialog from './Payment';
import VerificationDialog from './Verification';
import BalanceExceptions from './BalanceExceptions';
import VerifyEmail from './VerifyEmail';

const DialogsManagement = () => {
  const [dialog, dialogActions] = useDialog();

  const handleClose = () => {
    dialogActions.set('');
  };

  switch (dialog) {
    case 'signIn':
      return <SignInPopup open onClose={handleClose} />;
    case 'signUp':
      return <SignUpPopup open onClose={handleClose} />;
    case 'accountSettings':
      return <AccountSettingsPopup open onClose={handleClose} />;
    case 'changePassword':
      return <ChangePasswordPopup open onClose={handleClose} />;
    case 'messages':
      return <MessagesDialog open onClose={handleClose} />;
    case 'forgotPassword':
      return <ForgotPassword open onClose={handleClose} />;
    case 'resetPassword':
      return <ResetPassword open onClose={handleClose} />;
    case 'emailConfirmation':
      return <EmailConfirmation open onClose={handleClose} />;
    case 'withdraw':
      return <PaymentDialog open onClose={handleClose} />;
    case 'deposit':
      return <PaymentDialog isDeposit open onClose={handleClose} />;
    case 'verification':
      return <VerificationDialog open onClose={handleClose} />;
    case 'balanceExceptions':
      return <BalanceExceptions open onClose={handleClose} />;
    case 'verifyEmail':
      return <VerifyEmail open onClose={handleClose} />;
    default:
      return null;
  }
};

export default DialogsManagement;
