import { useCallback } from 'react';
import { sendEventAdForm } from './adForm';
import { AnalyticsKey, AnalyticsPageViewEvents } from './enum';
import useGlobalSettings from 'store/globalSettings';
import { IGlobalSettingsMeta } from 'api/settings';

const useAnalytics = () => {
  const [
    {
      data: { meta = {} as IGlobalSettingsMeta },
    },
  ] = useGlobalSettings();

  const sendEvent = useCallback(
    async (params: Partial<Record<AnalyticsKey, string | AnalyticsPageViewEvents>>) => {
      if (meta.adFormAnalyticsKey) {
        sendEventAdForm({
          analyticKey: meta.adFormAnalyticsKey,
          params,
        });
      }
    },
    [meta.adFormAnalyticsKey]
  );

  return { sendEvent };
};

export default useAnalytics;
