import { useState, useMemo } from 'react';
import { Control, FieldValues, useController, UseFormClearErrors, UseFormSetError } from 'react-hook-form';

import { TextField, Box, TextFieldProps } from '@mui/material';

import getCreditCardType from 'utils/CreditCard';

import useTranslate from 'utils/translate';

interface FormCardInputProps {
  label?: string;
  control: Control;
  success?: boolean;
  withPopper?: boolean;
  setError?: UseFormSetError<FieldValues>;
  clearErrors?: UseFormClearErrors<FieldValues>;
}

const FormCardInput = ({
  name,
  label,
  control,
  withPopper,
  placeholder,
  ...props
}: FormCardInputProps & TextFieldProps): JSX.Element => {
  const {
    fieldState: { error },
    field: { value = '', onChange, ...field },
  } = useController({
    name,
    control,
  });

  const { translate } = useTranslate();
  const [icon, setIcon] = useState('');

  const formatCardNum = ({ target: { value } }) => {
    const number = value.replace(/\s|[^0-9]+/g, '');

    setIcon(number.length >= 4 ? getCreditCardType(number)[0]?.icon : '');
    onChange(number.match(/.{1,4}/g)?.join(' ') ?? '');
  };

  if (!name || !control) {
    return null;
  }

  const fieldPlaceholder = useMemo(
    () => (placeholder ? translate(placeholder) : label ? translate(`Type ${label}`) : ''),
    [placeholder, label]
  );

  return (
    <TextField
      {...props}
      {...field}
      value={value}
      error={!!error}
      inputMode="numeric"
      style={{ width: '100%' }}
      onChange={formatCardNum}
      label={label && translate(label)}
      placeholder={fieldPlaceholder}
      inputProps={{
        maxLength: 19,
        pattern: '[0-9]*',
        inputMode: 'numeric',
        autoComplete: 'payment',
      }}
      InputProps={{
        endAdornment: icon && (
          <Box mr={2}>
            <img width={30} src={icon} />
          </Box>
        ),
      }}
      helperText={!withPopper && !!error?.message && translate(error.message)}
    />
  );
};

export default FormCardInput;
