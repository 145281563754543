import { useEffect } from 'react';

import { axiosInstance } from 'api/Request';

import AuthService from 'api/auth/AuthService';

import useToaster from 'hooks/useToaster';

import { API_STATUS_CODES } from 'config';
import useTranslates from 'utils/translate';
import useSelectedLanguage from 'store/language';

interface ErrorHandlerProps {
  children: JSX.Element;
}

const ErrorHandler = ({ children }: ErrorHandlerProps): JSX.Element => {
  const notify = useToaster();
  const { translate, translateState } = useTranslates();
  const [language] = useSelectedLanguage();

  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (errors: { response }) => {
        const { response } = errors;

        const isNotLogout = response.config.url !== '/logout';
        if (isNotLogout) {
          if (response?.status === API_STATUS_CODES.UNAUTHORIZED) {
            AuthService.signOut();
          } else if (response?.data?.text) {
            notify({
              type: 'error',
              message: translate(response?.data?.text),
            });
          }
        }
        throw errors;
      }
    );

    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
    };
  }, [translateState, language]);

  return children;
};

export default ErrorHandler;
