import { configureStore } from '@reduxjs/toolkit';

import { bannersSlice } from './banners';
import { bonusBalanceSlice } from './bonusBalance';
import { categoriesSlice } from './categories';
import { countriesSlice } from './countries';
import { currentUserSlice } from './currentUser';
import { dialogSlice } from './dialog';
import { domainSlice } from './domains';
import { favoritesSlice } from './favorites';
import { globalSettingsSlice } from './globalSettings';
import { hideBalanceSlice } from './hideBalance';
import { selectedLanguageSlice } from './language';
import { languagesListSlice } from './languagesList';
import { messagesSlice } from './messages';
import { metasSlice } from './metas';
import { paymentMethodsSlice } from './paymentMethods';
import { realBalanceSlice } from './realBalance';
import { settingsSlice } from './settings';
import { sportSlice } from './sport';
import { selectedThemeSlice } from './theme';
import { websiteContentSlice } from './websiteContent';
import { websiteSportSlice } from './websiteSports';
import { tournamentsSlice } from './tournaments';

export const store = configureStore({
  reducer: {
    sport: sportSlice.reducer,
    dialog: dialogSlice.reducer,
    metas: metasSlice.reducer,
    domain: domainSlice.reducer,
    banners: bannersSlice.reducer,
    settings: settingsSlice.reducer,
    favorites: favoritesSlice.reducer,
    countries: countriesSlice.reducer,
    messages: messagesSlice.reducer,
    categories: categoriesSlice.reducer,
    realBalance: realBalanceSlice.reducer,
    currentUser: currentUserSlice.reducer,
    hideBalance: hideBalanceSlice.reducer,
    tournaments: tournamentsSlice.reducer,
    websiteSport: websiteSportSlice.reducer,
    bonusBalance: bonusBalanceSlice.reducer,
    languagesList: languagesListSlice.reducer,
    globalSettings: globalSettingsSlice.reducer,
    selectedTheme: selectedThemeSlice.reducer,
    websiteContent: websiteContentSlice.reducer,
    paymentMethods: paymentMethodsSlice.reducer,
    selectedLanguage: selectedLanguageSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
