import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import * as yup from 'yup';

import { createPhoneNumberValidation } from 'utils/phone';

const passwordValidation = yup
  .string()
  .required('validation_password_required')
  .min(8, 'validation_password_conditions')
  .matches(/[a-zA-Z]/, 'validation_password_conditions');

export const signInSchema = yupResolver(
  yup.object({
    email: yup.string().required('validation_email_required'),
    password: yup.string().required('validation_password_required'),
  })
);

function isMinimumAgeValid(dateOfBirth, minimumAge = 18) {
  const today = dayjs();
  const birthDate = dayjs(dateOfBirth);

  return today.diff(birthDate, 'year', true) >= minimumAge;
}

export const signUpSchema = ({ playerMinAge, isPhoneNumberRequired }) =>
  yupResolver(
    yup.object({
      username: yup.string().required('validation_username_required'),
      firstName: yup.string().required('validation_firstName_required'),
      lastName: yup.string().required('validation_lastName_required'),
      email: yup.string().email('validation_invalid_email_address').required('validation_email_address_required'),
      birthDate: yup
        .string()
        .test('minAge', 'validation_invalid_min_age', (value) => {
          return isMinimumAgeValid(value, playerMinAge);
        })
        .required('validation_birthDate_required'),
      currencyId: yup.number().required('validation_currencyId_required'),
      password: passwordValidation,
      confirm_password: yup
        .string()
        .oneOf([yup.ref('password'), null], 'validation_password_no_match')
        .required('validation_confirm_password_required'),
      phoneNumber: yup
        .string()
        .test('custom', 'validation_not_correct_phone_number', createPhoneNumberValidation(isPhoneNumberRequired))
        .when('currencyId', (_, schema) => {
          return isPhoneNumberRequired ? schema.required('validation_phoneNumber_required') : schema.notRequired();
        }),
    })
  );

export const telegramSignupSchema = yupResolver(
  yup.object({
    currencyId: yup.number().required('validation_currencyId_required'),
  })
);

export const updateUserResolver = yupResolver(
  yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    birthDate: yup.string().required(),
    email: yup.string().email('validation_invalid_email_address').required('validation_email_address_required'),
    country: yup.string().required(),
    city: yup.string(),
    address_1: yup.string(),
  })
);

export const changePasswordResolver = yupResolver(
  yup.object({
    oldPassword: yup.string().required('validation_old_password_required'),
    newPassword: passwordValidation,
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], 'validation_password_not_match')
      .required('validation_confirm_password_required'),
  })
);

export const resetPasswordResolver = yupResolver(
  yup.object({
    password: passwordValidation,
    confirm_password: yup
      .string()
      .oneOf([yup.ref('password'), null], 'validation_password_not_match')
      .required('validation_confirm_password_required'),
  })
);

export const sendForgotPassword = yupResolver(
  yup.object({
    email: yup.string().email('validation_invalid_email_address').required('validation_email_address_required'),
  })
);

export const verificationResolver = yupResolver(
  yup.object({
    url: yup.string().required('validation_document_required'),
    serialId: yup.string().required('validation_document_number_required'),
  })
);

export const paymentResolver = yupResolver(
  yup.object({
    amount: yup.number().required('validation_amount_required'),
  })
);
