import { FC } from 'react';
import useSettings, { IWebsiteConfigs } from 'store/settings';
import CategorizedMenu from './Categorized';
import DefaultMobileMenu from './Default';

interface IMobileMenuProps {
  onMenuClose: () => void;
}

const MobileMenu: FC<IMobileMenuProps> = ({ onMenuClose }) => {
  const [
    {
      data: { meta = {} as IWebsiteConfigs },
    },
  ] = useSettings();

  if (meta.mobileMenuType === 'default') return <DefaultMobileMenu onMenuClose={onMenuClose} />;

  if (meta.mobileMenuType === 'categorized') return <CategorizedMenu onMenuClose={onMenuClose} />;

  return null;
};

export default MobileMenu;
