import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState, Status } from 'store/types';

import { WebsiteService } from 'api/website';
import { settingsStorage } from 'store/storage';

export interface IWebsiteConfigs {
  logoUrl: string;
  bgColor?: string;
  showTournaments?: boolean;
  liveChatKey: string;
  androidAppUrl?: string;
  showPWAInstall?: boolean;
  preferredSportId?: number;
  customColors?: {
    signInBg?: string;
    signIn?: string;
    signUp?: string;
    signUpBg?: string;
  };
  footerContactInformation?: string[];
  turboStarsStyles?: Record<string, string>;
  autoVerifyEmail: boolean;
  lockMobileFooter?: boolean;
  showSignup?: boolean;
  showDeposit?: boolean;
  showDepositIconOnMobileHeader?: boolean;
  affiliateUrl?: string;
  showLicense?: boolean;
  showMessagesSection: boolean;
  isPhoneNumberRequired: boolean;
  showSignupPromoCode?: boolean;
  transferWalletDelay?: number;
  zendeskKey?: string;
  gameSectionSlider?: {
    mobile?: {
      categoryCount: number;
      gameCount: number;
    };
    desktop?: {
      categoryCount: number;
      gameCount: number;
    };
  };
  showWithdraw?: boolean;
  showNoticiations?: boolean;
  isHomepageActive?: boolean;
  showCasinoLoadMore?: boolean;
  showAgreementSignature?: boolean;
  showMobilePopularGames?: boolean;
  showMobileHomeNavigation?: boolean;
  showMobileCasinoProviders?: boolean;
  showGamesInTopNavigation?: boolean;
  mobileMenuType?: 'default' | 'categorized';
}

export type SettingsType = {
  id?: number;
  websiteId?: number;
  favicon?: string;
  mainColor?: string;
  actionColor?: string;
  fontColor?: string;
  mainColorDark?: string;
  actionColorDark?: string;
  fontColorDark?: string;
  logo?: string;
  logoDark?: string;
  address?: string;
  phoneNumber?: string;
  email?: string;
  facebook?: string;
  youtube?: string;
  instagram?: string;
  viber?: string;
  telegram?: string;
  twitter?: string;
  meta?: IWebsiteConfigs;
  paymentLogos?: string[];
  providerLogos?: string[];
  paymentLogosDark?: string[];
  providerLogosDark?: string[];
  sponsorshipLogos?: string[];
  sponsorshipLogosDark?: string[];
};

export type SettingsStateType = {
  data: SettingsType;
  status: Status;
};

type SettingsActionsType = {
  read: () => Promise<PayloadAction<SettingsType>>;
};

const initialState: SettingsStateType = {
  data: settingsStorage.get() || {},
  status: 'idle',
};

const readWebsiteSettings = createAsyncThunk('settings/read', () => WebsiteService.getSettings());

const settingsSlice = createSlice({
  initialState,
  name: 'settings',
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(readWebsiteSettings.pending.type, (state): void => {
        state.status = 'loading';
      })
      .addCase(readWebsiteSettings.fulfilled.type, (state, action: PayloadAction<SettingsType>): void => {
        const data = action.payload || initialState.data;

        state.data = data;
        settingsStorage.set(data);
        state.status = 'success';
      })
      .addCase(readWebsiteSettings.rejected.type, (state): void => {
        state.status = 'failed';
        state.data = initialState.data;
      }),
});

const useSettings = (): [SettingsStateType, SettingsActionsType] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.settings);

  const actions = {
    read: (): Promise<PayloadAction<SettingsType>> => {
      return dispatch(readWebsiteSettings());
    },
  };

  return [state, actions];
};

export { settingsSlice };
export default useSettings;
