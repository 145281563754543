import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState, Status } from 'store/types';

import { IBannerGroup, WebsiteService } from 'api/website';

type BannersStateType = {
  data: IBannerGroup[];
  status: Status;
};

type BannersActionsType = {
  read: () => void;
};

const initialState: BannersStateType = {
  data: [],
  status: 'idle',
};

const readBannersApplyThunk = createAsyncThunk('banners/read', async () => WebsiteService.getBanners());

const bannersSlice = createSlice({
  initialState,
  name: 'banners',
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(readBannersApplyThunk.pending.type, (state: BannersStateType): void => {
        state.status = 'loading';
      })
      .addCase(
        readBannersApplyThunk.fulfilled.type,
        (state: BannersStateType, action: PayloadAction<IBannerGroup[]>): void => {
          state.status = 'success';
          state.data = (action.payload || []).filter((bannerGroup) =>
            (bannerGroup.banners || []).some((banner) => (banner.bannerItems || []).length > 0)
          );
        }
      )
      .addCase(readBannersApplyThunk.rejected.type, (state: BannersStateType): void => {
        state.status = 'failed';
        state.data = initialState.data;
      }),
});

const useBanners = (): [BannersStateType, BannersActionsType] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.banners);

  const actions = {
    read: () => {
      dispatch(readBannersApplyThunk());
    },
  };

  return [state, actions];
};

export { bannersSlice };
export default useBanners;
