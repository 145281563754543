import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState, Status } from 'store/types';

import { ICategory, SettingsService } from 'api/settings';

type CategoriesStateType = {
  data: ICategory[];
  status: Status;
};

type CategoriesActionsType = {
  read: () => void;
};

const initialState: CategoriesStateType = {
  data: [],
  status: 'idle',
};

const readCategoriesApplyThunk = createAsyncThunk('categories/read', async () => SettingsService.getCategories());

const categoriesSlice = createSlice({
  initialState,
  name: 'categories',
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(readCategoriesApplyThunk.pending.type, (state: CategoriesStateType): void => {
        state.status = 'loading';
      })
      .addCase(
        readCategoriesApplyThunk.fulfilled.type,
        (state: CategoriesStateType, action: PayloadAction<ICategory[]>): void => {
          state.status = 'success';
          state.data = action.payload || initialState.data;
        }
      )
      .addCase(readCategoriesApplyThunk.rejected.type, (state: CategoriesStateType): void => {
        state.status = 'failed';
        state.data = initialState.data;
      }),
});

const useCategories = (): [CategoriesStateType, CategoriesActionsType] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.categories);

  const actions = {
    read: () => {
      dispatch(readCategoriesApplyThunk());
    },
  };

  return [state, actions];
};

export { categoriesSlice };
export default useCategories;
