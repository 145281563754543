import { FC, useRef } from 'react';
import dayjs from 'dayjs';
import { Control, useController } from 'react-hook-form';

import { Box, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import useSettings from 'store/globalSettings';
import useTranslates from 'utils/translate';

type CalendarPickerView = Array<'day' | 'month' | 'year'>;

interface FormDatePickerProps {
  name: string;
  label?: string;
  control: Control;
  success?: boolean;
  disabled?: boolean;
  inputFormat?: string;
  views?: CalendarPickerView;
  variant?: 'standard' | 'filled' | 'outlined';
}

const FormDatePicker: FC<FormDatePickerProps> = ({ name, views, label, variant, control, disabled, inputFormat }) => {
  const rootRef = useRef();

  const [settings] = useSettings();
  const { translate } = useTranslates();
  const {
    fieldState: { error: errors },
    field: { onChange },
  } = useController({
    name,
    control,
  });

  const format = settings?.data?.dateFormat || inputFormat || 'DD/MM/YYYY';

  const _onChange = (fieldValue, fieldErrors) => {
    if (!fieldErrors.validationError) {
      onChange(fieldValue ? dayjs(fieldValue, format).toString() : '');
    }
  };

  return (
    <Box ref={rootRef}>
      <DesktopDatePicker
        label={label}
        views={views}
        format={format}
        disabled={disabled}
        onChange={_onChange}
        slotProps={{
          popper: {
            anchorEl: () => rootRef.current,
            sx: {
              '& .MuiIconButton-root, & .MuiDayCalendar-weekDayLabel, & .MuiPickersYear-root button': {
                color: 'inherit',
              },
              '& .MuiDayCalendar-weekDayLabel': {
                opacity: 0.5,
              },
            },
          },
        }}
        slots={{
          textField: (props) => (
            <TextField
              fullWidth
              variant={variant}
              {...props}
              error={!!errors?.message || props.error}
              helperText={props?.helperText || (errors?.message && translate(errors?.message))}
            />
          ),
        }}
      />
    </Box>
  );
};

export default FormDatePicker;
