import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const New: FC<SvgIconProps> = (props) => (
  <SvgIcon width="100%" height="100%" viewBox="0 0 512 512" {...props}>
    <path
      className="st0"
      d="M510,248.5l-30.3-52.4v-60.6c0-5.4-2.9-10.3-7.5-13l-52.4-30.3l-30.3-52.4c-2.7-4.6-7.6-7.5-13-7.5h-60.6
	L263.5,2c-4.6-2.7-10.4-2.7-15,0l-52.4,30.3h-60.6c-5.4,0-10.3,2.9-13,7.5L92.2,92.2l-52.4,30.3c-4.6,2.7-7.5,7.6-7.5,13v60.6
	L2,248.5c-2.7,4.6-2.7,10.4,0,15l30.3,52.4v60.6c0,5.4,2.9,10.3,7.5,13l52.4,30.3l30.3,52.4c2.7,4.6,7.6,7.5,13,7.5h60.6l52.4,30.3
	c2.3,1.3,4.9,2,7.5,2s5.2-0.7,7.5-2l52.4-30.3h60.6c5.4,0,10.3-2.9,13-7.5l30.3-52.4l52.4-30.3c4.6-2.7,7.5-7.6,7.5-13v-60.6
	l30.3-52.4C512.7,258.9,512.7,253.1,510,248.5z M182.6,304.2c0,6.6-4.3,12.4-10.7,14.4c-6.4,1.9-13.2-0.6-16.8-6l-36.8-55.2v46.9
	c0,8.3-6.7,15-15,15s-15-6.7-15-15v-96.4c0-6.6,4.3-12.4,10.7-14.4c6.3-1.9,13.2,0.5,16.8,6l36.8,55.2v-46.9c0-8.3,6.7-15,15-15
	s15,6.7,15,15V304.2z M248,241c8.3,0,15,6.7,15,15c0,8.3-6.7,15-15,15h-17.1v18.2H264c8.3,0,15,6.7,15,15s-6.7,15-15,15h-48.2
	c-8.3,0-15-6.7-15-15v-96.4c0-8.3,6.7-15,15-15H264c8.3,0,15,6.7,15,15s-6.7,15-15,15h-33.2V241H248z M431.2,211.4l-24.1,96.4
	c-1.6,6.5-7.3,11.1-14,11.4c-0.2,0-0.4,0-0.6,0c-6.4,0-12.2-4.1-14.2-10.3l-17.9-53.7l-17.9,53.7c-2.1,6.3-8.1,10.5-14.8,10.2
	c-6.7-0.3-12.4-4.9-14-11.4l-24.1-96.4c-2-8,2.9-16.2,10.9-18.2s16.2,2.9,18.2,10.9l11.6,46.4l15.8-47.5c2-6.1,7.8-10.3,14.2-10.3
	s12.2,4.1,14.2,10.3l15.8,47.5l11.6-46.4c2-8,10.2-12.9,18.2-10.9C428.3,195.3,433.2,203.4,431.2,211.4L431.2,211.4z"
    />
  </SvgIcon>
);

export default New;
