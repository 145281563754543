import { useMemo } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

const useScreen = () => {
  const matchesMobileSize = useMediaQuery('(max-width:760px)');
  const matchesTabletSize = useMediaQuery('(max-width:1124px)');
  const matches13InchSize = useMediaQuery('(max-width:1536px)');

  return useMemo(
    () => ({
      isMobile: matchesMobileSize,
      isTablet: matchesTabletSize,
      is13Inch: matches13InchSize,
    }),
    [matchesMobileSize, matchesTabletSize, matches13InchSize]
  );
};

export default useScreen;
