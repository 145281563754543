export default {
  head: ({ isRtl }) => ({
    top: 0,
    left: isRtl ? 'auto' : 24,
    right: isRtl ? 24 : 'auto',
    width: 80,
    height: 80,
    borderRadius: '5px',
    bgcolor: 'primary.main',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > svg': {
      width: '40px',
      height: '40px',
      fill: '#fff',
    },
  }),
  userInfo: ({ isRtl }) => ({
    pt: 1,
    pb: 2,
    pl: isRtl ? 3 : 15,
    pr: isRtl ? 15 : 3,
    borderRadius: '0 0 10px 10px',
    bgcolor: 'secondary.light',
  }),
};
