import { createContext, useContext, useEffect, useState } from 'react';

import { WebsiteService } from 'api/website';
import useSelectedLanguage from 'store/language';
import { translatesStorage } from 'store/storage';

type TranslatesGetters = {
  translate?: (key: string) => string;
  translateState?: Record<string, string>;
};

const TranslatesContext = createContext<TranslatesGetters>({});

const TranslatesProvider = ({ children }) => {
  const [state, setState] = useState(translatesStorage.get() || {});
  const [selectedLanguage] = useSelectedLanguage();

  useEffect(() => {
    WebsiteService.getTranslations().then((res) => {
      setState(res);
      translatesStorage.set(res);
    });
  }, [selectedLanguage?.id]);

  const getters: TranslatesGetters = {
    translateState: state,
    translate: (key) => state?.[key] || key,
  };

  return <TranslatesContext.Provider value={getters}>{children}</TranslatesContext.Provider>;
};

const useTranslates = (): TranslatesGetters => useContext(TranslatesContext);

export { TranslatesContext, TranslatesProvider, useTranslates };

export default useTranslates;
