import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState, Status } from 'store/types';

import { IMeta, WebsiteService } from 'api/website';

type MetasStateType = {
  data: IMeta[];
  status: Status;
};

type MetasActionsType = {
  read: () => void;
};

const initialState: MetasStateType = {
  data: [],
  status: 'idle',
};

const readCurrenciesApplyThunk = createAsyncThunk('metas/read', async () => WebsiteService.getMetas());

const metasSlice = createSlice({
  initialState,
  name: 'metas',
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(readCurrenciesApplyThunk.pending.type, (state: MetasStateType): void => {
        state.status = 'loading';
      })
      .addCase(
        readCurrenciesApplyThunk.fulfilled.type,
        (state: MetasStateType, action: PayloadAction<IMeta[]>): void => {
          state.status = 'success';
          state.data = action.payload || initialState.data;
        }
      )
      .addCase(readCurrenciesApplyThunk.rejected.type, (state: MetasStateType): void => {
        state.status = 'failed';
        state.data = initialState.data;
      }),
});

const useWebsiteMetas = (): [MetasStateType, MetasActionsType] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.metas);

  const actions = {
    read: () => {
      dispatch(readCurrenciesApplyThunk());
    },
  };

  return [state, actions];
};

export { metasSlice };
export default useWebsiteMetas;
