import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const VoucherIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon width={24} height={24} viewBox="0 0 512 512" {...props}>
    <path
      clipRule="evenodd"
      d="m140.843 333.687c0 4.596-3.727 8.326-8.326 8.326-4.602 0-8.326-3.73-8.326-8.326v-33.302c0-4.597 3.725-8.326 8.326-8.326 4.6 0 8.326 3.729 8.326 8.326zm0 83.257c0 4.597-3.727 8.326-8.326 8.326-4.602 0-8.326-3.729-8.326-8.326v-33.303c0-4.597 3.725-8.325 8.326-8.325 4.6 0 8.326 3.728 8.326 8.325zm74.524-248.07c-2.823 3.625-8.058 4.278-11.683 1.451-3.627-2.827-4.278-8.06-1.449-11.686l20.462-26.271c2.83-3.624 8.063-4.277 11.69-1.449 3.623 2.827 4.276 8.06 1.446 11.686zm231.639 23.641-155.604-121.233-24.863 31.914c-2.829 3.628-8.059 4.278-11.689 1.451-3.625-2.826-4.277-8.059-1.448-11.684l24.864-31.917-58.6-45.653c-18.955-14.769-46.547-11.343-61.317 7.615l-25.118 32.24c27.774 21.639 32.745 61.694 11.105 89.465-21.638 27.772-61.695 32.746-89.465 11.109l-25.12 32.244c-2.54 3.262-4.545 6.782-6.022 10.446 7.928-3.842 16.811-5.997 26.177-5.997zm-397.101 16.649h74.285v41.267c0 4.598 3.725 8.326 8.326 8.326 4.6 0 8.326-3.728 8.326-8.326v-41.267h321.251c24.032 0 43.693 19.661 43.693 43.693v40.869c-35.206 0-63.749 28.542-63.749 63.75 0 35.205 28.543 63.747 63.749 63.747v40.875c0 24.029-19.661 43.688-43.693 43.688h-321.25v-38.884c0-4.597-3.727-8.325-8.326-8.325-4.602 0-8.326 3.728-8.326 8.325v38.884h-74.286c-24.033 0-43.692-19.659-43.692-43.688v-40.875c35.205 0 63.748-28.542 63.748-63.747 0-35.207-28.543-63.75-63.748-63.75v-40.869c0-24.032 19.659-43.693 43.692-43.693z"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default VoucherIcon;
