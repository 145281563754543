import { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';

type PhoneNumberParams = {
  intl?: string;
  number?: string;
  operator?: string;
  dialCode?: string;
  formatted?: string;
};

export const getPhoneNumberParams = (number: string): PhoneNumberParams => {
  if (number) {
    const formatted = formatPhoneNumberIntl(number);
    const [dialCode, operator, intl] = formatted.split(' ');

    return {
      operator,
      dialCode,
      formatted,
      intl: `${operator}${intl}`,
      number: number.replace('+', ''),
    };
  }

  return {};
};

export const phoneNumberValidation = (value?: string): boolean => {
  return !!(value && isValidPhoneNumber(value));
};

export const createPhoneNumberValidation =
  (isPhoneNumberRequired: boolean) =>
  (value?: string): boolean => {
    if (!value && !isPhoneNumberRequired) return true;

    return phoneNumberValidation(value);
  };

export { formatPhoneNumberIntl };
