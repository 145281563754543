import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState, Status } from 'store/types';

import { ProfileService } from 'api/profile';
import { LOGOUT_ACTION } from 'store/constants';
import { accessStorage, userStorage } from 'store/storage';
import { IPlayer } from 'types/auth';

export type CurrentUserType = Partial<IPlayer>;

export type CurrentUserStateType = {
  data: CurrentUserType;
  status: Status;
};

type CurrentUserActionsType = {
  read: () => void;
  clear: () => void;
  set: (data: CurrentUserType) => void;
};

const initialState: CurrentUserStateType = {
  data: userStorage.get() || {},
  status: 'idle',
};

const readCurrentUser = createAsyncThunk('currentUser/read', async () => ProfileService.get());

const currentUserSlice = createSlice({
  initialState,
  name: 'currentUser',
  extraReducers: (builder) =>
    builder
      .addCase(readCurrentUser.pending.type, (state: CurrentUserStateType): void => {
        state.status = 'loading';
      })
      .addCase(readCurrentUser.fulfilled.type, (state: CurrentUserStateType, action: PayloadAction<IPlayer>): void => {
        const data = action.payload || initialState.data;
        userStorage.set(data);
        state.data = data;
        state.status = 'success';
      })
      .addCase(readCurrentUser.rejected.type, (state: CurrentUserStateType): void => {
        state.status = 'failed';
        state.data = initialState.data;
      })
      .addCase(LOGOUT_ACTION, (): CurrentUserStateType => initialState),
  reducers: {
    set: (state, action) => {
      state.data = action.payload;
      userStorage.set(action.payload);

      return state;
    },
    clear: (state) => {
      state.data = {};
      userStorage.set({});

      return state;
    },
  },
});

const useCurrentUser = (): [CurrentUserStateType, CurrentUserActionsType] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.currentUser);

  const actions = {
    read: () => {
      if (accessStorage.get()) {
        dispatch(readCurrentUser());
      }
    },
    set: (data: CurrentUserType) => {
      dispatch(currentUserSlice.actions.set(data));
    },
    clear: () => {
      dispatch(currentUserSlice.actions.clear());
    },
  };

  return [state, actions];
};

export { currentUserSlice };
export default useCurrentUser;
