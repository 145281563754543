import { useMemo, useState, KeyboardEvent, useEffect } from 'react';
import { Control, FieldValues, useController, UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import noop from 'lodash/noop';

import { TextField, TextFieldProps } from '@mui/material';

import useTranslate from 'utils/translate';

interface FormNumberFieldProps {
  label?: string;
  control: Control;
  minNumber?: number;
  maxNumber?: number;
  digitAfterDot?: number;
  maxNumberError?: string;
  minNumberError?: string;
  withoutDecimal?: boolean;
  maskFormat?: string | boolean;
  onBlur?: () => void;
  setError?: UseFormSetError<FieldValues>;
  clearErrors?: UseFormClearErrors<FieldValues>;
}

const FormNumberField = ({
  name,
  label,
  control,
  onBlur,
  required,
  minNumber,
  maxNumber,
  maskFormat,
  helperText,
  placeholder,
  digitAfterDot,
  withoutDecimal,
  maxNumberError,
  minNumberError,
  setError = noop,
  clearErrors = noop,
  ...props
}: FormNumberFieldProps & TextFieldProps): JSX.Element => {
  const { translate } = useTranslate();
  const [isFocused, setIsFocused] = useState(false);

  const {
    fieldState: { error },
    field: { ref, onChange, value = '', ...field },
  } = useController({
    name,
    control,
  });

  if (!name || !control) {
    return null;
  }

  const fieldPlaceholder = useMemo(
    () => (placeholder ? translate(placeholder) : label ? translate(`Type ${label}`) : ''),
    [placeholder, label]
  );

  const transMuteValue = (value: number | string): string => {
    if (value) {
      const maskedValue = (String(value).match(/[\d|.\+]+/g) || []) // eslint-disable-line
        .join('')
        .slice(0, 15);
      const parsedValue = parseFloat(maskedValue);

      if (!Number.isNaN(parsedValue) && typeof parsedValue === 'number') {
        return maskedValue;
      } else {
        return undefined;
      }
    } else {
      return String(value);
    }
  };

  useEffect(() => {
    const parsed = value && transMuteValue(value);

    if ((maxNumber || minNumber) && String(parsed)?.length) {
      if (maxNumber && +parsed > maxNumber) {
        setError(name, {
          type: 'number-max',
          message: maxNumberError || 'MaxNumberError',
        });
      } else if (minNumber && +parsed === minNumber) {
        setError(name, {
          type: 'number-min',
          message: minNumberError || 'MinNumberError',
        });
      } else {
        clearErrors(name);
      }
    }
  }, [maxNumber, minNumber, minNumberError, minNumberError, value]);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    const { value, selectionStart, selectionEnd } = event.target as HTMLInputElement;

    if (event.metaKey || event.ctrlKey) {
      return;
    }

    // Conditions for writing clean numbers
    const isNotNaN = !isNaN(+event.key);
    const isDecimal = !withoutDecimal && event.key === '.' && value?.length >= 1 && !value.includes('.');
    const isAllowedKey = ['Tab', 'Backspace', 'ArrowRight', 'ArrowLeft', 'Delete', 'Shift'].includes(event.key);

    if (!event.cancelable) return;
    if (
      event.code === 'Space' ||
      (selectionStart === 0 && event.key === '.') ||
      (maskFormat && selectionStart === 0 && value.length && event.key === '0') ||
      (maskFormat && !isAllowedKey && value === '0' && event.key !== '.')
    ) {
      event.preventDefault();
      return;
    }

    if (selectionStart === selectionEnd && digitAfterDot && value.split('.')[1]?.length > digitAfterDot - 1) {
      !isAllowedKey && event.preventDefault();
    }

    if (!(isDecimal || isAllowedKey || isNotNaN)) {
      event.preventDefault();
    }
  };

  const handleWheel = (event) => {
    event.target.blur();
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    onBlur?.();
    setIsFocused(false);
  };

  const handleChange = (event) => {
    const value = event.target.value;

    if (event.nativeEvent.inputType === 'insertText' && event.nativeEvent.data === '. ') {
      return;
    }

    onChange(transMuteValue(value));
  };

  const formattedNumber = useMemo(() => {
    const numValue = parseFloat(value);

    if (maskFormat && !isFocused && numValue && !Number.isNaN(numValue)) {
      return new Intl.NumberFormat(typeof maskFormat === 'string' ? maskFormat : 'en-US').format(numValue);
    }

    return value;
  }, [isFocused, value, maskFormat]);

  return (
    <TextField
      fullWidth
      {...props}
      {...field}
      ref={ref}
      error={!!error}
      required={required}
      inputMode="numeric"
      inputProps={{
        pattern: '[0-9]*',
        inputMode: 'numeric',
      }}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onWheel={handleWheel}
      value={formattedNumber}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      label={label && translate(label)}
      placeholder={fieldPlaceholder}
      helperText={(error?.message && translate(error?.message)) || helperText}
    />
  );
};

export default FormNumberField;
