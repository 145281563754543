import { FormEvent, FormEventHandler, useCallback } from 'react';

interface FormProps {
  onSubmit?: FormEventHandler;
  children?: JSX.Element | JSX.Element[] | boolean | string;
}

const Form = ({ onSubmit, ...props }: FormProps): JSX.Element => {
  const handleSubmit = useCallback(
    (event: FormEvent): void => {
      if (!event.cancelable) return;

      event.preventDefault();
      event.stopPropagation();
      onSubmit && onSubmit(event);
    },
    [onSubmit]
  );

  return <form style={{ width: '100%' }} noValidate onSubmit={handleSubmit} {...props} />;
};

export default Form;
