import { FC } from 'react';

import { Button, Stack } from '@mui/material';

import useDialog from 'store/dialog';
import useCurrentUser from 'store/currentUser';

import Wallet from './Wallet';
import UserProfile from './UserProfile';
import Notifications from './Notifications';
import useTranslates from 'utils/translate';
import useSettings, { IWebsiteConfigs } from 'store/settings';

const sx = {
  depositNowButton: {
    minWidth: 150,
  },
};

const UserSection: FC = () => {
  const { translate } = useTranslates();

  const [, dialogActions] = useDialog();
  const [currentUser] = useCurrentUser();
  const [
    {
      data: { meta = {} as IWebsiteConfigs },
    },
  ] = useSettings();

  if (currentUser?.data?.id) {
    return (
      <Stack direction="row" gap={2} alignItems="center">
        <Wallet />
        <Notifications />
        <UserProfile user={currentUser?.data} />
      </Stack>
    );
  }

  return (
    <Stack direction="row" gap={2} alignItems="center">
      {meta.showDeposit && (
        <Button
          variant="contained"
          color="success"
          sx={sx.depositNowButton}
          onClick={() => dialogActions.set('signIn')}
        >
          {translate('deposit_now')}
        </Button>
      )}
      <Button
        variant="contained"
        style={{
          color: meta.customColors?.signIn || 'text.main',
          backgroundColor: meta.customColors?.signInBg || 'primary.main',
        }}
        onClick={() => dialogActions.set('signIn')}
      >
        {translate('sign_in')}
      </Button>
      {meta.showSignup && (
        <Button
          variant="contained"
          style={{
            color: meta.customColors?.signIn || 'text.main',
            backgroundColor: meta.customColors?.signInBg || 'primary.main',
          }}
          onClick={() => dialogActions.set('signUp')}
        >
          {translate('sign_up')}
        </Button>
      )}
    </Stack>
  );
};

export default UserSection;
