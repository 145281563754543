import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Messages: FC<SvgIconProps> = (props) => (
  <SvgIcon width="100%" height="100%" viewBox="0 0 512 512" {...props}>
    <g>
      <g>
        <path
          className="st0"
          d="M331.8,277.3l-42.9,43c-17.4,17.5-48,17.8-65.8,0l-42.9-43l-154,154.5C32,434.4,38.3,436,45,436h422
			c6.7,0,13-1.6,18.8-4.2L331.8,277.3z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          className="st0"
          d="M467,76H45c-6.7,0-13,1.6-18.8,4.2l164.6,165.1l0,0l0,0l53.5,53.7c5.7,5.7,17.6,5.7,23.3,0l53.5-53.7l0,0l0,0
			L485.8,80.2C480,77.6,473.7,76,467,76z"
        />
      </g>
    </g>
    <g>
      <g>
        <path className="st0" d="M4.8,101.2c-3,6-4.8,12.7-4.8,19.8v270c0,7.1,1.8,13.8,4.8,19.8L159.1,256L4.8,101.2z" />
      </g>
    </g>
    <g>
      <g>
        <path
          className="st0"
          d="M507.2,101.2L352.9,256l154.3,154.8c3-6,4.8-12.7,4.8-19.8V121C512,113.9,510.2,107.2,507.2,101.2z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default Messages;
