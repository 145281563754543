import _Request from '../Request';

class DomainService extends _Request {
  constructor() {
    super('/');
  }

  getSettings() {
    return this.send({ method: 'get' }, '/domain/settings');
  }
}

export default new DomainService();
