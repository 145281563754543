import { FC, Fragment, useState } from 'react';

import AffiliateIcon from 'components/common/icons/Affiliate';
import HomeIcon from 'components/common/icons/Home';
import HotIcon from 'components/common/icons/Hot';
import NewIcon from 'components/common/icons/New';
import OtherIcon from 'components/common/icons/Other';
import PopularIcon from 'components/common/icons/Popular';
import PromotionsIcon from 'components/common/icons/Promotions';
import SlotsCasinoIcon from 'components/common/icons/SlotsCasino';
import SportIcon from 'components/common/icons/Sport';
import SportLiveIcon from 'components/common/icons/SportLive';
import TopIcon from 'components/common/icons/Top';
import AndroidIcon from 'components/common/icons/Android';

import useTranslates from 'utils/translate';

import { Stack } from '@mui/material';
import useSelectedTheme from 'store/theme';
import useWebsiteContent from 'store/websiteContent';
import CategoryLinks from '../CategoryLinks';
import MenuItem, { MenuItemWithoutBox, MenuItemWithoutLink } from '../MenuItem';
import SportLinks from '../SportLinks';
import NavigationActions from 'components/sections/Navigation/Actions';
import useSettings, { IWebsiteConfigs } from 'store/settings';

interface ICategorizedMenuProps {
  onMenuClose: () => void;
}

const categories = [
  {
    name: 'top',
    key: 'top',
    icon: <TopIcon />,
  },
  {
    name: 'sport',
    key: 'sport',
    icon: <SportIcon />,
  },
  {
    name: 'casino',
    key: 'casino',
    icon: <SlotsCasinoIcon />,
  },
  {
    name: 'other',
    key: 'other',
    icon: <OtherIcon />,
  },
];

const CategorizedMenu: FC<ICategorizedMenuProps> = ({ onMenuClose }) => {
  const { translate } = useTranslates();

  const [websiteContents] = useWebsiteContent();
  const [{ isDark }] = useSelectedTheme();
  const [
    {
      data: { meta = {} as IWebsiteConfigs },
    },
  ] = useSettings();

  const [activeCategory, setActiveCategory] = useState(categories[0].key);

  return (
    <Stack width="100%" gap={1} px={2}>
      <Stack width="100%" direction="row" gap={1} justifyContent="center">
        {categories.map((category) => (
          <MenuItemWithoutBox
            key={category.key}
            title={category.name}
            icon={category.icon}
            selected={activeCategory === category.key}
            onClick={() => setActiveCategory(category.key)}
          />
        ))}
      </Stack>
      {activeCategory === 'top' && (
        <Fragment>
          <MenuItem
            to="/popular-games"
            icon={<PopularIcon color="primary" style={{ color: '#e4c94c' }} />}
            onClick={onMenuClose}
          >
            {translate('popular_games')}
          </MenuItem>
          <MenuItem to={`/games?is_new=true`} icon={<NewIcon style={{ color: '#379634' }} />} onClick={onMenuClose}>
            {translate('new')}
          </MenuItem>
          <MenuItem to={`/games?is_popular=true`} icon={<HotIcon style={{ color: '#FF7700' }} />} onClick={onMenuClose}>
            {translate('hot')}
          </MenuItem>
          <MenuItem to={`/sport?tab=results`} icon={<SportLiveIcon color="primary" />} onClick={onMenuClose}>
            {translate('sport')}
          </MenuItem>
          <MenuItem to={`/sport?tab=results`} icon={<SportIcon color="primary" />} onClick={onMenuClose}>
            {translate('live')}
          </MenuItem>
          <MenuItem to={`/`} icon={<HomeIcon color="primary" />} onClick={onMenuClose}>
            {translate('home')}
          </MenuItem>
        </Fragment>
      )}
      {activeCategory === 'sport' && <SportLinks onItemClick={onMenuClose} />}
      {activeCategory === 'casino' && <CategoryLinks onItemClick={onMenuClose} />}
      {activeCategory === 'other' && (
        <Fragment>
          <MenuItem to="/promotions" icon={<PromotionsIcon color="primary" />} onClick={onMenuClose}>
            {translate('promotions')}
          </MenuItem>
          {!!meta.affiliateUrl && (
            <MenuItem to={meta.affiliateUrl} icon={<AffiliateIcon color="primary" />} onClick={onMenuClose}>
              {translate('affiliate')}
            </MenuItem>
          )}
          {websiteContents.data.map((content) => (
            <MenuItem
              key={content.url}
              to={`/info/${content.url}`}
              icon={
                isDark
                  ? content.translations[0].imgDark || content.translations[0].img
                  : content.translations[0].img || content.translations[0].imgDark
              }
              onClick={onMenuClose}
            >
              {content.translations[0].title}
            </MenuItem>
          ))}
          {!!meta.androidAppUrl && (
            <MenuItemWithoutLink icon={<AndroidIcon color="primary" />} onClick={onMenuClose}>
              <a href={meta.androidAppUrl} download={window.location.host}>
                {translate('android_application')}
              </a>
            </MenuItemWithoutLink>
          )}
        </Fragment>
      )}
      <Stack sx={{ m: '24px auto !important' }} direction="row" gap={3}>
        <NavigationActions />
      </Stack>
    </Stack>
  );
};

export default CategorizedMenu;
