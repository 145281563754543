const DoneIcon = () => (
  <svg width={62} height={62} viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M38.027 22.7073L26.9445 33.8157L22.682 29.5532C22.4504 29.2827 22.1654 29.0631 21.8449 28.908C21.5244 28.753 21.1753 28.6659 20.8196 28.6521C20.4638 28.6384 20.109 28.6983 19.7775 28.8282C19.446 28.9581 19.1449 29.1551 18.8932 29.4068C18.6414 29.6586 18.4444 29.9597 18.3145 30.2912C18.1846 30.6227 18.1247 30.9775 18.1384 31.3332C18.1522 31.689 18.2393 32.0381 18.3944 32.3586C18.5494 32.6791 18.7691 32.9641 19.0395 33.1957L25.1103 39.2923C25.3517 39.5318 25.638 39.7212 25.9527 39.8497C26.2675 39.9783 26.6045 40.0435 26.9445 40.0415C27.6222 40.0387 28.2717 39.7696 28.7528 39.2923L41.6695 26.3757C41.9116 26.1355 42.1038 25.8498 42.235 25.535C42.3661 25.2202 42.4337 24.8825 42.4337 24.5415C42.4337 24.2005 42.3661 23.8628 42.235 23.548C42.1038 23.2332 41.9116 22.9475 41.6695 22.7073C41.1855 22.2262 40.5307 21.9561 39.8483 21.9561C39.1658 21.9561 38.511 22.2262 38.027 22.7073ZM31.0003 5.1665C25.891 5.1665 20.8964 6.6816 16.6481 9.52021C12.3998 12.3588 9.08871 16.3934 7.13345 21.1138C5.17819 25.8343 4.6666 31.0285 5.66338 36.0397C6.66017 41.0508 9.12056 45.6539 12.7334 49.2668C16.3463 52.8796 20.9493 55.34 25.9605 56.3368C30.9717 57.3336 36.1659 56.822 40.8863 54.8667C45.6068 52.9115 49.6414 49.6003 52.48 45.3521C55.3186 41.1038 56.8337 36.1092 56.8337 30.9998C56.8337 27.6074 56.1655 24.2481 54.8672 21.1138C53.569 17.9796 51.6661 15.1318 49.2673 12.7329C46.8684 10.3341 44.0206 8.4312 40.8863 7.13295C37.7521 5.8347 34.3928 5.1665 31.0003 5.1665ZM31.0003 51.6665C26.9129 51.6665 22.9172 50.4544 19.5186 48.1835C16.1199 45.9127 13.471 42.685 11.9068 38.9086C10.3426 35.1323 9.93335 30.9769 10.7308 26.968C11.5282 22.959 13.4965 19.2766 16.3868 16.3863C19.2771 13.496 22.9595 11.5277 26.9685 10.7303C30.9774 9.93285 35.1328 10.3421 38.9091 11.9063C42.6855 13.4705 45.9132 16.1194 48.184 19.5181C50.4549 22.9167 51.667 26.9124 51.667 30.9998C51.667 36.481 49.4896 41.7376 45.6139 45.6134C41.7381 49.4891 36.4815 51.6665 31.0003 51.6665Z"
      fill="#2DBE79"
    />
  </svg>
);

export default DoneIcon;
