import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import useSettings from 'store/settings';
import useWebsiteMetas from 'store/metas';

import useTranslates from 'utils/translate';

import { PAGE_META_KEYS, DEFAULT_META_KEY, APPLICATION_NAME } from 'config';
import useMount from 'hooks/useMount';

const Metatags = () => {
  const location = useLocation();
  const { translate } = useTranslates();

  const [settings] = useSettings();
  const [state, actions] = useWebsiteMetas();

  useMount(() => {
    actions.read();
  });

  const selected = state?.data?.find(
    (item) => item.page === location?.pathname || PAGE_META_KEYS[location?.pathname] === item.page
  );

  const defaultSelected = state?.data?.find((item) => item.page === DEFAULT_META_KEY);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href={settings.data?.favicon} />
        <title>
          {state?.status === 'loading'
            ? translate('meta_loading')
            : selected?.metaTitle || defaultSelected?.metaTitle || APPLICATION_NAME}
        </title>
        <meta name="description" content={selected?.metaDescription || defaultSelected?.metaDescription}></meta>
        <meta name="keywords" content={selected?.metaKeywords || defaultSelected?.metaKeywords}></meta>
        <meta
          name="application-name"
          content={selected?.metaTitle || defaultSelected?.metaTitle || APPLICATION_NAME}
        ></meta>
      </Helmet>
    </>
  );
};

export default Metatags;
