import ReactDOM from 'react-dom/client';

import App from './App';
import { getDocumentDirection } from 'utils/getDocumentDirection';

document.querySelector('html').setAttribute('dir', getDocumentDirection());

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
