import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Box, Dialog, DialogTitle, DialogContent, IconButton, Typography } from '@mui/material';

import CloseIcon from 'components/common/svg/close';

import useTranslates from 'utils/translate';

interface VerifyEmailProps {
  open: boolean;
  onClose: () => void;
}

const VerifyEmail: FC<VerifyEmailProps> = ({ open, onClose }) => {
  const navigation = useNavigate();

  const { translate } = useTranslates();

  const handleClose = () => {
    navigation('/');
    onClose();
  };

  return (
    <Dialog keepMounted open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography fontSize={18} fontWeight={600}>
          {translate('email_verification')}
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box width="100%" height="100px" display="flex" alignItems="center" justifyContent="center" mb={2}>
          <PriorityHighIcon color="warning" />
        </Box>
        <Typography textAlign="center" color="#FFCD42">
          {translate('verify_your_email')}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default VerifyEmail;
