import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState, Status } from 'store/types';

import { BonusBalanceService } from 'api/bonusBalance';
import { LOGOUT_ACTION } from 'store/constants';

type BonusBalanceType = {
  amount: number;
  status: Status;
};

type BonusBalanceActionsType = {
  read: () => void;
  updateBonusBalance: (amount: number) => void;
};

const initialState: BonusBalanceType = {
  amount: 0,
  status: 'idle',
};

const readBonusBalanceThunk = createAsyncThunk('bonus-balance/read', async () => BonusBalanceService.getBonusBalance());

const bonusBalanceSlice = createSlice({
  initialState,
  name: 'bonusBalance',
  reducers: {
    updateBonusBalance: (state, action) => {
      state.amount = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(readBonusBalanceThunk.pending.type, (state: BonusBalanceType): void => {
        state.status = 'loading';
      })
      .addCase(readBonusBalanceThunk.fulfilled.type, (state: BonusBalanceType, action: PayloadAction<number>): void => {
        state.status = 'success';
        state.amount = action.payload;
      })
      .addCase(readBonusBalanceThunk.rejected.type, (state: BonusBalanceType): void => {
        state.status = 'failed';
        state.amount = initialState.amount;
      })
      .addCase(LOGOUT_ACTION, (): BonusBalanceType => initialState),
});

const useBonusBalance = (): [BonusBalanceType, BonusBalanceActionsType] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.bonusBalance);

  const actions = {
    read: () => {
      dispatch(readBonusBalanceThunk());
    },
    updateBonusBalance: (amount: number) => {
      dispatch(bonusBalanceSlice.actions.updateBonusBalance(amount));
    },
  };

  return [state, actions];
};

export { bonusBalanceSlice };
export default useBonusBalance;
