import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import AccountIcon from 'components/common/icons/Account';
import BonusIcon from 'components/common/icons/Bonus';
import ChangePasswordIcon from 'components/common/icons/ChangePassword';
import MessagesIcon from 'components/common/icons/Messages';

import AwaitingIcon from 'components/common/svg/AwaitingIcon';
import CloseIcon from 'components/common/svg/close';

import BonusHistoryIcon from 'components/common/svg/BonusHistory';
import HistoryIcon from 'components/common/svg/History';
import LogOutIcon from 'components/common/svg/LogOut';
import VerifiedIcon from 'components/common/svg/Verified';

import AuthService from 'api/auth/AuthService';
import useCurrentUser, { CurrentUserType } from 'store/currentUser';
import useDialog from 'store/dialog';

import useTranslates from 'utils/translate';
import useScreen from 'hooks/useScreen';

import { store } from 'store';
import { LOGOUT_ACTION } from 'store/constants';
import useSelectedLanguage from 'store/language';
import useSettings, { IWebsiteConfigs } from 'store/settings';
import sx from './styles';
import { VerificationStatus } from 'types/auth';

interface UserProfileProps {
  user: CurrentUserType;
  children?: JSX.Element;
}

const UserProfile: FC<UserProfileProps> = ({ user, children }) => {
  const [{ isRtl }] = useSelectedLanguage();
  const { isMobile } = useScreen();
  const navigation = useNavigate();
  const { translate } = useTranslates();
  const [, dialogActions] = useDialog();
  const [, currentUserActions] = useCurrentUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const [
    {
      data: { meta = {} as IWebsiteConfigs },
    },
  ] = useSettings();

  const open = Boolean(anchorEl);

  const handleSignOut = () => {
    AuthService.signOut().then(() => {
      currentUserActions.set({});
      store.dispatch({ type: LOGOUT_ACTION });
      navigation('/');
    });
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDialog = (name) => {
    dialogActions.set(name);
    !isMobile && handleClose();
  };

  const verificationStatuses = {
    [VerificationStatus.VERIFIED]: (
      <Stack direction="row" gap={1} alignItems="center">
        <VerifiedIcon />
        <Typography fontSize={14} fontWeight={600} color="text.primary">
          {translate('verified')}
        </Typography>
      </Stack>
    ),
    [VerificationStatus.NOT_VERIFIED]: (
      <Button variant="contained" color="secondary" onClick={() => openDialog('verification')}>
        {translate('verify_now')}
      </Button>
    ),
    [VerificationStatus.AWAITING_VERIFICATION]: (
      <Stack direction="row" gap={1} alignItems="center">
        <AwaitingIcon />
        <Typography fontSize={14} fontWeight={600} color="text.primary">
          {translate('awaiting_verification')}
        </Typography>
      </Stack>
    ),
  };

  const content = (
    <Box>
      <Box sx={{ px: 2, py: 2, bgcolor: 'background.default' }}>
        <Box flex={1} width="100%">
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography noWrap width="100%" fontWeight={700} color="text.primary" fontSize={isMobile ? 18 : 22}>
              {user.firstName} {user.lastName}
            </Typography>
            {isMobile && (
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
          <Box mt={1} display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              <Typography fontSize={14} fontWeight={600} color="primary.main">
                {user?.username}
              </Typography>
              <Typography fontSize={isMobile ? 14 : 16} fontWeight={600} color="text.primary">
                ID: {user.id}
              </Typography>
            </Box>
            {verificationStatuses?.[user.verificationStatus]}
          </Box>
        </Box>
      </Box>
      <MenuItem onClick={() => openDialog('accountSettings')}>
        <ListItemIcon>
          <AccountIcon />
        </ListItemIcon>
        <ListItemText>{translate('account_settings')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => openDialog('changePassword')}>
        <ListItemIcon>
          <ChangePasswordIcon />
        </ListItemIcon>
        <ListItemText>{translate('change_password')}</ListItemText>
      </MenuItem>
      {!!meta.showMessagesSection && (
        <MenuItem onClick={() => openDialog('messages')}>
          <ListItemIcon>
            <MessagesIcon />
          </ListItemIcon>
          <ListItemText>{translate('messages')}</ListItemText>
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          handleClose();
          navigation('/bonuses');
        }}
      >
        <ListItemIcon>
          <BonusIcon />
        </ListItemIcon>
        <ListItemText>{translate('bonuses')}</ListItemText>
      </MenuItem>
      {meta.showTournaments && (
        <MenuItem
          onClick={() => {
            handleClose();
            navigation('/tournaments');
          }}
        >
          <ListItemIcon>
            <EmojiEventsIcon />
          </ListItemIcon>
          <ListItemText>{translate('tournaments')}</ListItemText>
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          handleClose();
          navigation('/bonuses-history');
        }}
      >
        <ListItemIcon>
          <BonusHistoryIcon />
        </ListItemIcon>
        <ListItemText>{translate('bonus_history')}</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleClose();
          navigation('/game-history');
        }}
      >
        <ListItemIcon>
          <HistoryIcon />
        </ListItemIcon>
        <ListItemText>{translate('game_history')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={handleSignOut}>
        <ListItemIcon>
          <LogOutIcon />
        </ListItemIcon>
        <ListItemText>{translate('log_out')}</ListItemText>
      </MenuItem>
    </Box>
  );

  return (
    <>
      {children ? (
        <Box onClick={handleOpen}>{children}</Box>
      ) : (
        <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }} onClick={handleOpen}>
          <Box mr={!isRtl && 2} ml={isRtl && 2}>
            <Typography fontSize={14} fontWeight={600} textAlign="right">
              {user.firstName} <br />
              {user.lastName}
            </Typography>
          </Box>
          <Avatar variant="rounded" sx={sx.avatar}>
            <AccountIcon />
          </Avatar>
        </Box>
      )}

      {isMobile ? (
        <Drawer open={open} anchor="bottom" onClose={handleClose} sx={{ zIndex: 1300 }} PaperProps={sx.menuList}>
          {content}
        </Drawer>
      ) : (
        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          PaperProps={sx.menuPaper}
          MenuListProps={sx.menuList}
        >
          {content}
        </Menu>
      )}
    </>
  );
};

export default UserProfile;
