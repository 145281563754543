import { darken } from '@mui/material';

export default {
  footer:
    ({ isMobile }) =>
    ({ palette }) => ({
      py: 2,
      bottom: 0,
      pb: isMobile && '70px',
      zIndex: isMobile ? 10 : 1200,
      position: isMobile ? 'relative' : 'sticky',
      bgcolor: 'background.default',
      borderTop: `1px solid ${palette.secondary.light}`,
    }),
  expandableIcon:
    ({ expanded }) =>
    ({ palette }) => ({
      width: 35,
      height: 35,
      margin: 0,
      position: 'absolute',
      top: '-10px',
      left: '50%',
      transform: `translate(-50%, -10px) rotate(${expanded ? 0 : 180}deg)`,
      color: palette.text.primary,
      '&, &:hover': {
        bgcolor: palette.background.paper,
      },
    }),
  text: {
    fontSize: 12,
    fontWeight: 600,
  },
  socIcon: {
    width: 44,
    height: 44,
    '& > img': {
      width: '100%',
    },
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '20px',
  },
  copyright: ({ palette }) => ({
    maxWidth: '50%',
    textAlign: 'center',
    fontSize: 12,
    color: darken(palette.text.primary, 0.5),
  }),
};
