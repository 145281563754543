import { createSlice } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

type DialogStateType =
  | 'signIn'
  | 'signUp'
  | 'accountSettings'
  | 'changePassword'
  | 'messages'
  | 'forgotPassword'
  | 'resetPassword'
  | 'emailConfirmation'
  | 'withdraw'
  | 'deposit'
  | 'verification'
  | 'balanceExceptions'
  | 'verifyEmail'
  | '';

type DialogActionsType = {
  set: (type: DialogStateType) => void;
};

const initialState: DialogStateType = '';

const dialogSlice = createSlice({
  initialState,
  name: 'dialog',
  reducers: {
    set: (state, action) => {
      state = action.payload;

      return state;
    },
  },
});

const useDialog = (): [DialogStateType, DialogActionsType] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.dialog);

  const actions = {
    set: (data: DialogStateType) => {
      dispatch(dialogSlice.actions.set(data));
    },
  };

  return [state, actions];
};

export { dialogSlice };
export default useDialog;
