import { lazy } from 'react';

export default [
  {
    path: '/',
    component: lazy(() => import('./Main')),
  },
  {
    path: '/games/:id?',
    component: lazy(() => import('./Games')),
  },
  {
    path: '/game/:id',
    component: lazy(() => import('components/sections/GameIframe')),
  },
  {
    path: '/promotions',
    component: lazy(() => import('./Promotions')),
  },
  {
    path: '/game-history',
    component: lazy(() => import('./GameHistory')),
  },
  {
    path: '/bonuses-history',
    component: lazy(() => import('./BonusesHistory')),
  },
  {
    path: '/bonuses',
    component: lazy(() => import('./Bonuses')),
  },
  {
    path: '/tournaments',
    component: lazy(() => import('./Tournaments')),
  },
  {
    path: '/sport/*',
    component: lazy(() => import('./Sport')),
  },
  {
    path: '/wallet-history',
    component: lazy(() => import('./WalletHistory')),
  },
  {
    path: '/popular-games',
    component: lazy(() => import('./Games/PopularGames')),
  },
  {
    path: '/payment/deposit/:providerId',
    component: lazy(() => import('./PaymentDepositRedirect')),
  },
  {
    path: '*',
    component: lazy(() => import('./Redirect')),
  },
];
