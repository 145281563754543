import { Box } from '@mui/material';

import useSettings, { IWebsiteConfigs } from 'store/settings';

const AppLoading = (): JSX.Element => {
  const [
    {
      data: { meta = {} as IWebsiteConfigs },
    },
  ] = useSettings();

  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        zIndex: 10000,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        position: 'fixed',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: meta.bgColor || '#000c24',
      }}
    >
      {meta.logoUrl && <img src={meta.logoUrl} alt="LogoImage" />}
    </Box>
  );
};

export default AppLoading;
