import React, { useState, useMemo, useCallback, useEffect } from 'react';
import noop from 'lodash/noop';
import { useController, Control } from 'react-hook-form';

import {
  Select,
  Tooltip,
  MenuItem,
  Checkbox,
  FormControl,
  SelectProps,
  ListItemText,
  FormHelperText,
  Typography,
  SelectChangeEvent,
  useScrollTrigger,
} from '@mui/material';

import sx from './styles';
import useScreen from 'hooks/useScreen';
import useTranslates from 'utils/translate';

type DataItemType = { id: string | number; name: string; icon?: string; color?: string };

type SelectValueType = string | number | (string | number)[];
type SelectChangeEventType = SelectChangeEvent<SelectValueType>;

interface FormSelectProps {
  name: string;
  label?: string;
  control: Control;
  helperText?: string;
  autoPrefilled?: boolean;
  options?: DataItemType[];
  onChange?: (event: SelectChangeEventType, child: React.ReactNode) => void;
}

// @mui issue https://github.com/mui-org/material-ui/issues/17001
// [Select] getting slow when loading 2000 options
const FormSelect = ({
  name,
  control,
  options,
  multiple,
  disabled,
  helperText,
  placeholder,
  autoPrefilled,
  variant = 'standard',
  onChange: onChangeParent = noop,
  ...props
}: FormSelectProps & SelectProps): JSX.Element => {
  const { translate } = useTranslates();
  const { isMobile } = useScreen();
  const [isOpen, setIsOpen] = useState(false);

  const scrollTrigger = useScrollTrigger();

  const {
    field: { value, onChange, ...field },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  });

  const isValidOptions = useMemo(
    () => Array.isArray(options) && options?.length > (autoPrefilled ? 1 : 0),
    [autoPrefilled, options]
  );

  const renderValue = useCallback(
    (value?: SelectValueType) => {
      const isValid = multiple ? Array.isArray(value) && value.length : value;

      if (options?.length && isValid) {
        if (Array.isArray(value) && value.length) {
          if (value.length > 3) {
            return `${value.length} ${translate('Selected')}`;
          }
          return options
            .filter((item) => value.includes(item.id))
            .map((item) => item.name)
            .join(', ');
        }
        const item = options.find((item) => item.id === value);

        return (
          <Typography
            fontSize={14}
            noWrap
            color={item?.color || variant === 'standard' ? 'text.secondary' : 'text.primary'}
          >
            {item?.icon} {item?.name}
          </Typography>
        );
      }

      return (
        <Typography fontSize={14} noWrap color={variant === 'standard' ? 'text.secondary' : 'text.primary'}>
          {placeholder}
        </Typography>
      );
    },
    [multiple, options, placeholder, translate]
  );

  const selectedValue = useMemo(
    () => (isValidOptions && value && Array.isArray(value) ? (multiple ? value : '') : multiple ? [] : value || ''),
    [isValidOptions, multiple, value]
  );

  const handleChange = useCallback(
    (event: SelectChangeEventType, child: React.ReactNode): void => {
      onChange(event);
      onChangeParent(event, child);
    },
    [onChangeParent, onChange]
  );

  useEffect(() => {
    if (autoPrefilled && options?.length === 1) {
      onChange({ target: { value: options[0].id } });
    }
  }, [onChange, autoPrefilled, options]);

  useEffect(() => {
    setIsOpen(false);
  }, [scrollTrigger]);

  return (
    <FormControl fullWidth>
      <Select
        {...props}
        {...field}
        displayEmpty
        variant={variant}
        error={invalid}
        multiple={multiple}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        value={selectedValue}
        onChange={handleChange}
        renderValue={renderValue}
        defaultValue={selectedValue}
        disabled={disabled || !isValidOptions}
        MenuProps={{
          BackdropProps: {
            sx: sx.backdropProps({ isMobile }),
          },
          PaperProps: {
            sx: sx.menuPaper({ isMobile }),
          },
          MenuListProps: {
            sx: sx.menuList({ isMobile }),
          },
        }}
      >
        {!disabled &&
          isValidOptions &&
          options?.map((item) => (
            <MenuItem key={item.id} value={item.id} sx={sx.option}>
              {item.icon && <Typography fontSize={20}>{item.icon}</Typography>}
              {Array.isArray(selectedValue) && multiple && (
                <Checkbox checked={selectedValue.indexOf(item.id) > -1} sx={sx.checkbox} />
              )}
              <Tooltip title={item.name} disableHoverListener={item.name.length < 50}>
                <ListItemText
                  primary={<Typography color={item.color}>{item.name}</Typography>}
                  sx={{
                    primary: sx.optionTitle,
                  }}
                />
              </Tooltip>
            </MenuItem>
          ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {error?.message && <FormHelperText error>{translate(error?.message)}</FormHelperText>}
    </FormControl>
  );
};

export default FormSelect;
