import { InputLabel } from '@mui/material';

const FormLabel = ({ required = true, children }) => {
  return (
    <InputLabel style={{ color: 'white', fontWeight: 700 }}>
      {children}
      {required ? ' *' : ''}
    </InputLabel>
  );
};

export default FormLabel;
