export interface IGame {
  category: number;
  category_id: number;
  game_id: string;
  game_tags: string[];
  id: number;
  image_lg_url: string;
  image_md_url: string;
  image_sm_url: string;
  name: string;
  play_count: number;
  position_x: number;
  position_y: number;
  provider_id: number;
  sub_category: string;
  thumb_url: string;
  weight: number;
  has_demo?: boolean;
  thumbnail_img_url?: string;
  is_new?: boolean;
  is_popular?: boolean;
}

export interface TVBetGame {
  clientId: number;
  url: string;
  gameId: string;
  tagId: string;
  token: string;
  isFrameIntegration: boolean;
  aggregator: {
    name: string;
  };
}

export interface BetGamesTVGame {
  url: string;
  token: string;
  wsUrl: string;
  apiUrl: string;
  gameId: string;
  clientId: string;
  isFrameIntegration: boolean;
  aggregator: {
    name: string;
  };
}

export interface ICasinoCategory {
  id: number;
  is_deletable?: boolean;
  order_id?: number;
  name?: string;
  logo_url?: string;
  websiteId?: number;
}

export interface ICasinoGameFilter {
  tags?: number[];
  category?: number;
  name?: string;
  provider?: number;
  is_new?: boolean;
  is_popular?: boolean;
  favorites?: number;
  size?: number;
  page?: number;
  orderKey?: string;
  orderDirection?: 'asc' | 'desc';
}

export interface ICasinoProvider {
  provider_id: number;
  name: string;
  order_number: number;
  is_available?: boolean;
  is_enabled?: boolean;
  logo?: string;
  gameCount?: number;
  id?: number;
  order: number;
}

export interface IStartGameProps {
  casino_game_id: number;
  demo: boolean;
  exit_url?: string;
  https?: boolean;
}

export interface ICasinoTag {
  name: string;
  id: number;
  logo: string;
}

export enum ICasinoBetStatus {
  ACTIVE = 1,
  WON = 2,
  LOST = 3,
  BET_REVERSE = 4,
}

export interface IGameDetails {
  id: number;
  has_lobby: number;
  provider_id: number;
  category_id: number;
  aggregator_id: number;
  name: string;
  thumbnail_img_url: string;
  background_img: string;
  ratio: number;
  has_demo: boolean;
  game_tags: string[];
  channel_id: number;
  is_new: boolean;
  is_popular: boolean;
}

export interface IGamesTag {
  icon: string;
  icon_dark: string;
  id: number;
  tag: string;
  order: number;
}
