import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const Other: FC<SvgIconProps> = (props) => (
  <SvgIcon width="100%" height="100%" viewBox="0 0 24 24" {...props}>
    <g id="Layer_2" data-name="Layer 2">
      <rect height="9.5" rx="1.75" width="9.5" x="13.25" y="1.25" />
      <path d="m22.7334 16.7627-3.4961-3.4961a1.7529 1.7529 0 0 0 -2.4746 0l-3.4961 3.4961a1.752 1.752 0 0 0 0 2.4746l3.4961 3.4961a1.75171 1.75171 0 0 0 2.4746 0l3.4961-3.4961a1.75112 1.75112 0 0 0 0-2.4746z" />
      <rect height="9.5" rx="1.75" width="9.5" x="1.25" y="1.25" />
      <rect height="9.5" rx="1.75" width="9.5" x="1.25" y="13.25" />
    </g>
  </SvgIcon>
);

export default Other;
