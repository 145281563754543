import { BrowserRouter as Router } from 'react-router-dom';

import AppLayout from 'pages/AppLayout';

import StoreProvider from 'store/StoreProvider';
import ThemeProvider from 'theme/ThemeProvider';
import ErrorHandler from 'errorHandling/ErrorHandler';
import ToasterProvider from 'components/common/Toaster';
import DatePickerProvider from 'components/common/Form/DatePicker/Provider';

import { TranslatesProvider } from 'utils/translate';
import { datadogRumInit } from 'utils/datadogRum';
import useMount from 'hooks/useMount';

import 'index.css';

const App = (): React.ReactElement => {
  useMount(() => {
    datadogRumInit();
  });

  return (
    <StoreProvider>
      <ThemeProvider>
        <TranslatesProvider>
          <ToasterProvider>
            <ErrorHandler>
              <DatePickerProvider>
                <Router>
                  <AppLayout />
                </Router>
              </DatePickerProvider>
            </ErrorHandler>
          </ToasterProvider>
        </TranslatesProvider>
      </ThemeProvider>
    </StoreProvider>
  );
};
export default App;
