import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Hot: FC<SvgIconProps> = (props) => (
  <SvgIcon width="100%" height="100%" viewBox="0 0 512 512" {...props}>
    <g>
      <g>
        <path
          className="st0"
          d="M369.2,159.8c-91.4-55.6-48.5-134.1-46.7-137.3c2.7-4.6,2.7-10.4,0-15s-7.6-7.5-13-7.5
			c-47,0-83.9,13.4-109.5,39.7c-43.8,45-41.7,115.2-40.9,145.1c0.1,3.3,0.2,6.2,0.2,8.3c0,22.2,3.6,42.7,6.7,60.9
			c2,11.7,3.8,21.7,4.1,29.7c0.3,8.5-1.2,10.4-1.3,10.5c-0.2,0.3-2.1,1.2-7,1.2c-5.7,0-9.9-1.9-13.6-6
			c-14.5-16.2-15.5-60.6-12.7-84.7c0.5-4.3-0.9-8.5-3.7-11.7s-6.9-5-11.2-5c-38.9,0-68,63.7-68,120.6c0,26.8,5.4,53,16,77.9
			c10.3,24.1,24.9,45.9,43.5,64.7C150.9,490.4,202,512,256,512c54.2,0,105.3-21.3,143.8-59.9c38.4-38.5,59.6-89.5,59.6-143.5
			C459.4,239.7,405.5,181.9,369.2,159.8z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default Hot;
