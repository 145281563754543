export default {
  label: {},
  backdropProps: ({ isMobile }) =>
    isMobile
      ? {}
      : {
          bgcolor: 'transparent',
        },
  menuPaper: ({ isMobile }) => ({
    marginTop: 2,
    ...(isMobile
      ? {
          width: '100%',
          maxWidth: '100% !important',
          bottom: '0 !important',
          left: '0 !important',
          transformOrigin: '0 !important',
        }
      : {}),
  }),
  menuList: ({ isMobile }) => ({
    maxHeight: isMobile ? 500 : 300,
  }),
  option: {
    gap: 2,
    height: 42,
    padding: '0px 12px',
  },
  checkbox: {
    paddingLeft: 0,
    color: '#939DA5 !important',
    '&.Mui-checked': {
      color: '#0000008A !important',
    },
  },
  optionTitle: {
    // color: '#838F98 !important',
    fontSize: 14,
    lineHeight: '24px',
  },
};
