import { Grid, Typography } from '@mui/material';
import { PaymentMethodsService } from 'api/paymentMethods';
import { useCallback, useEffect, useState } from 'react';
import useTranslates from 'utils/translate';
import useDebounce from 'hooks/useDebounce';
import useMount from 'hooks/useMount';
import { PAYMENT_INFO_DELAY } from './constants';
import Copy from 'components/common/Copy';

const AutomaticDeposit = ({ watch, paymentMethod, changeAmountFieldVisibility, changeActionButtonVisibility }) => {
  const amount = watch('amount');

  const [walletInfo, setWalletInfo] = useState('');
  const { translate } = useTranslates();
  const handler = useCallback(async (amount: number, sourceId: number) => {
    const result = await PaymentMethodsService.getPaymentMethodExtraInfo<[{ key: string; value: string }]>({
      amount,
      sourceId,
      type: 'deposit',
    });
    if (result[0]) {
      setWalletInfo(result[0].value);
    }
  }, []);

  const debouncedPaymentMethodExtraInfo = useDebounce(handler, PAYMENT_INFO_DELAY);

  useMount(() => {
    changeActionButtonVisibility(false);
    changeAmountFieldVisibility(true);
  });

  useEffect(() => {
    if (amount) {
      debouncedPaymentMethodExtraInfo(amount, paymentMethod.method.id);
    }
  }, [amount, paymentMethod.method.id, debouncedPaymentMethodExtraInfo]);

  return (
    <>
      {!!walletInfo && (
        <Grid item xs={12} display="flex" flexDirection="column" gap={2}>
          <Typography fontSize="16px" fontWeight={600}>
            {translate('wallet_info')}
          </Typography>
          <Copy text={walletInfo} />
        </Grid>
      )}
    </>
  );
};

export default AutomaticDeposit;
