export default {
  head: ({ opened, isSafari }) => ({
    px: 2,
    width: '100%',
    pt: '5px',
    height: 65,
    top: 0,
    bgcolor: opened ? 'background.default' : 'background.paper', // TODO - move to theme pallete
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 1200,
    position: isSafari ? '-webkit-sticky' : 'sticky',
  }),
  headButton: ({ palette }) => ({
    color: palette.text.primary,
  }),
  navBar: {
    py: 1,
    pb: '12px',
    width: '100%',
    height: '65px',
    bottom: '-5px',
    zIndex: 1300,
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    bgcolor: 'background.default',
  },
  navButton:
    ({ selected }) =>
    ({ palette }) => ({
      maxWidth: '18vw',
      minWidth: '18vw',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'column',
      position: 'relative',
      textAlign: 'center',
      color: selected ? palette.primary.main : palette.text.primary,
      '& svg': {
        flex: 1,
        fill: selected ? palette.primary.main : palette.text.primary,
      },
      '& p': {
        mt: 0.5,
        fontSize: 12,
        fontWeight: 600,
      },
    }),
  drawerPaper: {
    pt: '80px',
    pb: '120px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    bgcolor: 'background.default',
    overflowX: 'auto',
  },
  drawer: { zIndex: 1100, pt: '70px' },
  menuItems: {
    mt: 1,
    px: 1,
    flex: 1,
    width: '100%',
    justifyContent: 'space-between',
    overflowX: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': { display: 'none' },
  },
  button: ({ selected }) => ({
    px: 2,
    height: 38,
    fontSize: 12,
    padding: 0,
    minWidth: 'fit-content',
    whiteSpace: 'nowrap',
    fontWeight: selected ? 700 : 600,
    color: selected ? 'primary.main' : 'text.primary',
  }),
};
