export default {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  button: {
    minWidth: 150,
  },
  menuPaper: {
    sx: ({ palette }) => ({
      width: 360,
      boxShadow: 'none',
      borderRadius: '10px',
      bgcolor: 'background.paper',
      border: `2px solid ${palette.divider}`,
    }),
  },
  menuList: {
    sx: ({ palette }) => ({
      p: 0,
      '& li': {
        height: 67,
        textAlign: 'center',
      },
      '& li:not(:last-child)': {
        borderBottom: `2px solid ${palette.divider}`,
      },
    }),
  },
  menuInfo: {
    display: 'flex',
    '& svg': {
      width: 30,
      height: 30,
    },
  },
  menuHead: { px: 2, py: 2, display: 'flex', alignItems: 'center', bgcolor: 'background.default' },
};
