import { ThemeOptions } from 'theme/types';

export default {
  notificationsList: (theme: ThemeOptions) => ({
    top: 70,
    margin: 0,
    left: '50%',
    zIndex: 3000,
    width: '100%',
    maxWidth: 300,
    position: 'fixed',
    transform: 'translate(-50%, 0)',
    [theme.breakpoints.up('md')]: {
      top: 15,
      maxWidth: 400,
    },
  }),
};
