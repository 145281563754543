import { datadogRum } from '@datadog/browser-rum';

export const datadogRumInit = () => {
  datadogRum.init({
    applicationId: '26971cb3-a76f-4e0f-86a1-febe574ca32b',
    clientToken: 'pub03b7e1066c33bdedbbb4a2841bd8a0bb',
    site: 'datadoghq.eu',
    service: 'casino',
    env: 'prod',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
};

export default datadogRum;
