import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SlotCasino: FC<SvgIconProps> = (props) => (
  <SvgIcon
    width="100%"
    height="100%"
    enableBackground="new 0 0 488.308 488.308"
    viewBox="0 0 488.308 488.308"
    {...props}
  >
    <path d="m35.683 401.378h358.272c19.712.195 35.849-15.626 36.045-35.338.001-.068.001-.137.001-.205v-60.457h47.818c3.373-.043 6.106-2.75 6.182-6.123v-62.667c-.068-3.39-2.792-6.126-6.182-6.21h-17.818v-79.675c17.551-2.037 30.128-17.917 28.091-35.469s-17.917-30.128-35.469-28.091-30.128 17.917-28.091 35.469c1.511 13.02 10.811 23.801 23.469 27.206v80.56h-18v-75.036c-.047-19.875-16.171-35.962-36.046-35.964h-358.272c-19.738.031-35.714 16.058-35.683 35.796 0 .056 0 .113.001.169v210.493c-.09 19.541 15.679 35.454 35.219 35.544.154 0 .308 0 .463-.002zm400.636-282.457c0-11.046 8.954-20 20-20s20 8.954 20 20-8.954 20-20 20c-11.041-.012-19.988-8.959-20-20zm35.682 123.457v51h-42v-51zm-54 123c0 13.255-10.745 24-24 24h-358c-13.255 0-24-10.745-24-24v-210c0-13.255 10.745-24 24-24h358c13.255 0 24 10.745 24 24z" />
    <path d="m392.001 174.378c0-3.314-2.686-6-6-6h-342c-3.314 0-6 2.686-6 6v172c0 3.314 2.686 6 6 6h342c3.314 0 6-2.686 6-6zm-231 166v-160h108v160zm219 0h-99v-160h99zm-330-160h99v160h-99z" />
    <path d="m237.057 205.378h-46.112c-7.015 0-14.944 5.228-14.944 12.438v20.133c0 6.975 6.748 12.235 15.87 12.235s16.13-5.374 16.13-12.235v-2.571h12v1.352l-31.385 54.027c-1.122 1.909-1.728 4.078-1.759 6.292 0 9.727 9.705 16.261 18.812 16.261 6.5 0 9.551-3.211 11.011-5.949l30.414-58.145c1.816-3.4 4.907-9.9 4.907-15.164v-16.236c0-6.89-8.085-12.438-14.944-12.438zm2.944 28.674c0 1.468-1.136 5.072-3.531 9.562l-30.146 57.664c-.228.025-.458.037-.687.035-3.63 0-6.866-2.383-6.866-4.261-.011-.133.025-.266.1-.376l32.14-55.338c.583-.898.924-1.931.99-3v-9.092c0-3.313-3.061-5.868-6.375-5.868h-23.25c-3.314 0-6.375 2.555-6.375 5.868v7.862c0 .465-2.139 1.076-4.13 1.076-1.35-.006-2.678-.34-3.87-.973v-18.618c1-.438 1.768-1.215 2.944-1.215h46.112c.9 0 1.944.829 2.944 1.35z" />
    <path d="m119.724 205.378h-46.112c-7.011 0-14.611 5.228-14.611 12.438v20.133c0 6.975 6.748 12.235 15.87 12.235s16.13-5.374 16.13-12.235v-2.571h11v1.352l-31.052 54.027c-1.072 1.922-1.621 4.092-1.592 6.292 0 9.727 9.788 16.261 18.9 16.261 6.5 0 9.592-3.21 11.052-5.949l30.6-58.145c1.815-3.405 5.094-9.9 5.094-15.164v-16.236c-.002-6.89-8.42-12.438-15.279-12.438zm3.277 28.674c0 1.468-1.3 5.072-3.7 9.562l-30.226 57.664c-.243.025-.486.037-.73.035-3.629 0-6.887-2.383-6.887-4.261-.015-.132.018-.266.092-.376l31.8-55.335c.479-.924.704-1.96.651-3v-9.092c.124-3.116-2.3-5.742-5.416-5.867-.097-.004-.195-.005-.292-.004h-23.251c-3.272-.064-5.976 2.536-6.04 5.808 0 .02-.001.04-.001.06v7.862c-1.265.699-2.685 1.069-4.13 1.076-2.03 0-3.87-.585-3.87-.973v-18.618c0-.438 1.435-1.215 2.611-1.215h46.112c1.167.241 2.279.699 3.277 1.351z" />
    <path d="m305.371 250.184c9.118 0 16.63-5.374 16.63-12.235v-2.571h11v1.352l-31.218 54.027c-1.097 1.916-1.675 4.085-1.675 6.292 0 9.727 9.746 16.261 18.853 16.261 6.5 0 9.571-3.21 11.031-5.949l30.258-58.145c1.816-3.405 4.751-9.9 4.751-15.164v-16.236c0-6.89-7.752-12.438-14.611-12.438h-46.112c-7.015 0-15.277 5.228-15.277 12.438v20.133c0 6.975 7.248 12.235 16.37 12.235zm-4.37-31.591c1-.438 2.1-1.215 3.277-1.215h46.112c.9 0 2.611.829 2.611 1.351v15.323c0 1.468-.969 5.072-3.364 9.562l-30.062 57.664c-.215.026-.431.037-.647.035-3.629 0-6.845-2.383-6.845-4.261-.006-.134.034-.267.113-.376l31.977-55.335c.534-.91.82-1.945.828-3v-9.092c-.029-3.272-2.705-5.901-5.978-5.872-.021 0-.042 0-.063.001h-23.251c-3.118-.037-5.675 2.461-5.712 5.579-.001.096 0 .193.004.289v7.862c-2.829 1.397-6.14 1.434-9 .1z" />
  </SvgIcon>
);

export default SlotCasino;
