import { ReactNode } from 'react';

export interface IStore {
  info: string[];
}

export type noop = () => void;

export interface IFilter {
  label?: string;
  type: 'select' | 'date';
  value: unknown;
  name: string;
}

interface IOption {
  label: string;
  value: unknown;
}

export interface IFilterSelect extends IFilter {
  options: IOption[];
}

export type IFilterDate = IFilter;

export type SomeFilter = IFilterDate | IFilterSelect;

export type Currency = {
  name: string;
  sign: string;
  pref: boolean;
};

export interface IModalProps {
  title: string;
  body: ReactNode;
  onClose?: noop;
}

export interface IPaged<T> {
  data: T[];
  favorites?: T[];
  count: number;
}

export enum PaymentMethodTypes {
  both = 'both',
  deposit = 'deposit',
  withdraw = 'withdraw',
}

export enum PaymentMethodExecutionTypes {
  automatic = 'automatic',
  manual = 'manual',
}

export interface IPaymentMethodProperty {
  key: string;
  name: string;
  isRequired: boolean;
  type: 'DEPOSIT' | 'WITHDRAW';
}

interface IWebsitePaymentMethodCurrency {
  id: number;
  currencyId: number;
  minDeposit: number;
  maxDeposit: number;
  minWithdraw: number;
  maxWithdraw: number;
  minAutoDeposit: number;
  maxAutoDeposit: number;
  minAutoWithdraw: number;
  maxAutoWithdraw: number;
}

export type PaymentMethod = {
  id: number;
  method: {
    id: number;
    name: string;
    type: PaymentMethodTypes;
    executionType: PaymentMethodExecutionTypes;
    provider: {
      id: number;
      name: string;
      aggregator: {
        id: number;
      };
    };
  };
  currencies: IWebsitePaymentMethodCurrency[];
  meta: {
    properties: IPaymentMethodProperty[];
  };
  order: number;
  convertCurrencyId: number;
  description: string;
  executionTime: number;
  iconDark: string;
  iconLight: string;
  isAvailable: boolean;
  isEnabled: boolean;
  title: string;
};
