import axios, { AxiosRequestConfig } from 'axios';

import { accessStorage, languageStorage, clearStorage } from 'store/storage';
import { API_BASE_URL, API_STATUS_CODES } from 'config';

const axiosInstance = axios.create();

const DEFAULT_METHOD = 'POST';

type Response<T> = { data: T };

type RequestConfig = AxiosRequestConfig;

const SKIP_QUERY = ['/common/website/language'];

abstract class _Request {
  path: string;

  constructor(path: string) {
    this.path = path;
  }

  send<T>(config: RequestConfig, url = '') {
    const defaultHeaders = {
      Authorization: accessStorage.get(),
    };

    const requestConfig: RequestConfig = {
      method: DEFAULT_METHOD,
      baseURL: API_BASE_URL,
      ...config,
      url: `${this.path === '/' ? '' : this.path}${url}`,
      params: {
        ...config.params,
        ...(!SKIP_QUERY.includes(url) ? { langId: languageStorage.get() } : {}),
      },
      headers: { ...defaultHeaders, ...config.headers },
    };

    return axiosInstance
      .request<Response<T>>(requestConfig)
      .then(({ data: { data } }) => data)
      .catch((error) => {
        if (error?.response?.status === API_STATUS_CODES.UNAUTHORIZED) {
          clearStorage();
        }
        throw error;
      });
  }
}

export { axiosInstance };

export default _Request;
