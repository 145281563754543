import { FC } from 'react';

import { Box, Stack } from '@mui/material';
import NextArrow from 'components/common/Slider/NextArrow';
import PrevArrow from 'components/common/Slider/PrevArrow';

import Slider from 'components/common/Slider';
import useScreen from 'hooks/useScreen';

interface Props {
  images: string[];
}

const ImageSlider: FC<Props> = ({ images }) => {
  const { isMobile, is13Inch, isTablet } = useScreen();

  const count = isMobile ? 3 : isTablet ? 6 : is13Inch ? 8 : 10;

  const isSlider = images?.length >= count;

  if (!isSlider) {
    return (
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="center"
        my={1}
        gap={1}
        px={isMobile ? 0 : 2}
      >
        {images.map((image, index) => (
          <Box
            key={index}
            sx={{
              width: 95,
              height: 40,
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundImage: `url(${image})`,
              backgroundRepeat: 'no-repeat',
            }}
          />
        ))}
      </Stack>
    );
  }

  return (
    <Box width="100%" mt={2} px={isMobile ? 0 : 2}>
      <Slider
        slidesToShow={count}
        variableWidth={isMobile}
        nextArrow={<NextArrow sx={{ right: -40, left: 'auto', bottom: 'auto', top: 0 }} />}
        prevArrow={<PrevArrow sx={{ left: -40, right: 'auto', bottom: 'auto', top: 0 }} />}
      >
        {images?.map((image, index) => (
          <Box
            key={index}
            sx={{
              width: '95px !important',
              height: 40,
              mx: 1,
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundImage: `url(${image})`,
              backgroundRepeat: 'no-repeat',
            }}
          >
            {/* <img height="40px" src={image} /> */}
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default ImageSlider;
