export enum AnalyticsPageViewEvents {
  registrationStart = 'registration_start',
  registrationSuccess = 'registration_success',
  homePageView = 'home_page_view',
  firstTimeDeposit = 'first_time_deposit',
  deposit = 'deposit',
}

export enum AnalyticsKey {
  pageView = 'page_view',
  currency = 'currency',
  // need to be changed
  amount = 'sales',
}
