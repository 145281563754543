import { ReactElement } from 'react';
import { Provider } from 'react-redux';

import { store } from './index';

interface StoreProviderProps {
  children: JSX.Element | JSX.Element[];
}

const StoreProvider = ({ children }: StoreProviderProps): ReactElement => <Provider store={store}>{children}</Provider>;

export default StoreProvider;
