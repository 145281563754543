import useSettings, { IWebsiteConfigs } from 'store/settings';

const useChat = () => {
  const [
    {
      data: { meta = {} as IWebsiteConfigs },
    },
  ] = useSettings();

  const openChat = () => {
    if (meta.zendeskKey) return console.log('Zendesk chat open function is not implemented');

    if (meta.liveChatKey) return window.LC_API?.open_chat_window();
  };

  return { openChat };
};

export default useChat;
