import { createSlice } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

export type HideBalanceStateType = boolean;

type HideBalanceActionsType = {
  toggle: () => void;
};

const initialState: HideBalanceStateType = localStorage.getItem('hideBalance') === '1';

const hideBalanceSlice = createSlice({
  initialState,
  name: 'hideBalance',
  reducers: {
    toggle: (state) => {
      state = !state;
      localStorage.setItem('hideBalance', state ? '1' : '0');
      return state;
    },
  },
});

const useHideBalance = (): [HideBalanceStateType, HideBalanceActionsType] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.hideBalance);

  const actions = {
    toggle: () => {
      dispatch(hideBalanceSlice.actions.toggle());
    },
  };

  return [state, actions];
};

export { hideBalanceSlice };
export default useHideBalance;
