import cardTypes from './lib/card-types';
import { addMatchingCardsToResults } from './lib/addMatchingCard';
import { findBestMatch } from './lib/findMatch';

import { CreditCardType, CardCollection, CreditCardTypeCardBrandId } from './types';

const customCards = {} as CardCollection;
const isValidInputType = <T>(cardNumber: T): boolean => {
  return typeof cardNumber === 'string' || cardNumber instanceof String;
};

const cardNames: Record<string, CreditCardTypeCardBrandId> = {
  ARCA: 'arca',
  VISA: 'visa',
  MASTERCARD: 'mastercard',
  AMERICAN_EXPRESS: 'american-express',
  DINERS_CLUB: 'diners-club',
  DISCOVER: 'discover',
  UNIONPAY: 'unionpay',
  MAESTRO: 'maestro',
  MIR: 'mir',
  HIPER: 'hiper',
  HIPERCARD: 'hipercard',
};

const ORIGINAL_TEST_ORDER = [
  cardNames.ARCA,
  cardNames.VISA,
  cardNames.MASTERCARD,
  cardNames.AMERICAN_EXPRESS,
  cardNames.DINERS_CLUB,
  cardNames.DISCOVER,
  cardNames.UNIONPAY,
  cardNames.MAESTRO,
  cardNames.MIR,
  cardNames.HIPER,
  cardNames.HIPERCARD,
];

function findType(cardType: string | number): CreditCardType {
  return customCards[cardType] || cardTypes[cardType];
}

function getAllCardTypes(): CreditCardType[] {
  return ORIGINAL_TEST_ORDER.map((cardType) => findType(cardType) as CreditCardType);
}

function creditCardType(cardNumber: string): Array<CreditCardType> {
  const results = [] as CreditCardType[];

  if (!isValidInputType(cardNumber)) {
    return results;
  }

  if (cardNumber.length === 0) {
    return getAllCardTypes();
  }

  ORIGINAL_TEST_ORDER.forEach((cardType) => {
    const cardConfiguration = findType(cardType);

    addMatchingCardsToResults(cardNumber, cardConfiguration, results);
  });

  const bestMatch = findBestMatch(results) as CreditCardType;

  if (bestMatch) {
    return [bestMatch];
  }

  return results;
}

creditCardType.getTypeInfo = (cardType: string): CreditCardType => findType(cardType) as CreditCardType;

creditCardType.types = cardNames;

export default creditCardType;
