import { createSlice } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

import { themeStorage } from 'store/storage';

export type SelectedThemeStateType = string;

type SelectedThemeActionsType = {
  set: (type: string) => void;
};

const defaultType = themeStorage.get() || 'dark';

const initialState: SelectedThemeStateType = defaultType;

const selectedThemeSlice = createSlice({
  initialState,
  name: 'selectedTheme',
  reducers: {
    set: (state, action) => {
      state = action.payload;

      themeStorage.set(action.payload);

      return state;
    },
  },
});

const useSelectedTheme = (): [
  {
    type: SelectedThemeStateType;
    isDark: boolean;
  },
  SelectedThemeActionsType
] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.selectedTheme);

  const actions = {
    set: (data) => {
      dispatch(selectedThemeSlice.actions.set(data));
    },
  };

  return [
    {
      type: state,
      isDark: state === 'dark',
    },
    actions,
  ];
};

export { selectedThemeSlice };
export default useSelectedTheme;
