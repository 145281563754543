import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { isAndroid } from 'react-device-detect';

import CloseIcon from 'components/common/icons/Close';
import useTranslates from 'utils/translate';
import useSettings, { IWebsiteConfigs } from 'store/settings';
import useWebsiteMetas from 'store/metas';
import { DEFAULT_META_KEY } from 'config';
import useMount from 'hooks/useMount';

const HEIGHT = 64;
const ANIMATION_DURATION = 300;
const ANIMATION_START_TIME = 3000;

const pixelate = (value: number) => `${value}px`;

const ApplicationDialog = ({ forceHide }: { forceHide: boolean }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [margin, setMargin] = useState<number>(-HEIGHT);
  const { translate } = useTranslates();
  const [
    {
      data: { favicon, meta = {} as IWebsiteConfigs },
    },
  ] = useSettings();
  const [state] = useWebsiteMetas();

  useMount(() => {
    const timerId = setTimeout(() => {
      setMargin(0);
      clearTimeout(timerId);
    }, ANIMATION_START_TIME);
  });

  if (!isAndroid) return null;
  if (!isOpen) return null;
  if (!meta.androidAppUrl) return null;
  if (forceHide) return null;

  const onClick = () => {
    setMargin(-HEIGHT);
    const timerId = setTimeout(() => {
      setIsOpen(false);
      clearTimeout(timerId);
    }, ANIMATION_DURATION);
  };
  const defaultSelected = state?.data?.find((item) => item.page === DEFAULT_META_KEY);

  return (
    <Box
      display="flex"
      flexDirection="row"
      padding="16px 24px"
      justifyContent="space-between"
      bgcolor="text.primary"
      style={{
        transition: `margin-top ${ANIMATION_DURATION}ms`,
      }}
      height={pixelate(HEIGHT)}
      marginTop={pixelate(margin)}
    >
      <Box display="flex" gap="16px">
        <CloseIcon
          color="secondary"
          style={{ width: 12, height: 12, display: 'flex', alignSelf: 'center' }}
          onClick={onClick}
        />
        <Box display="flex" gap="8px">
          <img width={32} rel="icon" src={favicon} />
          <Typography color="background.default" display="flex" alignItems="center">
            {defaultSelected?.metaTitle || 'Application'}
          </Typography>
        </Box>
      </Box>
      <Typography fontWeight={600} color="background.default" display="flex" alignItems="center">
        <a href={meta.androidAppUrl} download={window.location.host}>
          {translate('download')}
        </a>
      </Typography>
    </Box>
  );
};

export default ApplicationDialog;
