import useMount from 'hooks/useMount';

interface LiveChatProps {
  license: number;
  asyncInit?: boolean;
}

declare global {
  interface Window {
    __lc: LiveChatProps;
    LiveChatWidget;
    LC_API: {
      open_chat_window: () => void;
    };
  }
}

const LiveChat = ({ secretKey }: { secretKey: string }) => {
  useMount(() => {
    window.__lc = window.__lc || ({} as LiveChatProps);
    window.__lc.license = Number(secretKey);

    const slice = [].slice;

    const intermediateObject = {
      _q: [],
      _h: null,
      _v: '2.0',
      on: function () {
        // eslint-disable-next-line prefer-rest-params
        add(['on', slice.call(arguments)]);
      },
      once: function () {
        // eslint-disable-next-line prefer-rest-params
        add(['once', slice.call(arguments)]);
      },
      off: function () {
        // eslint-disable-next-line prefer-rest-params
        add(['off', slice.call(arguments)]);
      },
      get: function () {
        if (!intermediateObject._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
        // eslint-disable-next-line prefer-rest-params
        return add(['get', slice.call(arguments)]);
      },
      call: function () {
        // eslint-disable-next-line prefer-rest-params
        add(['call', slice.call(arguments)]);
      },
      init: function () {
        const script = document.createElement('script');
        script.async = true;
        script.type = 'text/javascript';
        script.src = 'https://cdn.livechatinc.com/tracking.js';
        document.head.appendChild(script);
      },
    };

    function add(item) {
      return intermediateObject._h ? intermediateObject._h.apply(null, item) : intermediateObject._q.push(item);
    }

    !window.__lc.asyncInit && intermediateObject.init();
    window.LiveChatWidget = window.LiveChatWidget || intermediateObject;
  });

  return null;
};

export default LiveChat;
