import { Control, useController } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';

import InputComponent from './InputComponent';

interface PhoneNumberProps {
  name?: string;
  control: Control;
  disabled?: boolean;
  required?: boolean;
  autoFocus?: boolean;
}

const PhoneNumberInput = ({
  control,
  disabled,
  required = true,
  autoFocus = false,
  name = 'phone',
}: PhoneNumberProps): JSX.Element => {
  const {
    fieldState: { error },
    field: { onChange, value = '' },
  } = useController({
    name,
    control,
  });
  const handleFocus = () => {
    if (!value) {
      onChange('');
    }
  };

  return (
    <PhoneInput
      international
      value={value}
      error={error}
      onFocus={handleFocus}
      disabled={disabled}
      required={required}
      onChange={onChange}
      autoFocus={autoFocus}
      inputComponent={InputComponent}
    />
  );
};

export default PhoneNumberInput;
