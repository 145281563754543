import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState, Status } from 'store/types';

import { ILanguage, SettingsService } from 'api/settings';

type LanguagesListType = {
  data: ILanguage[];
  status: Status;
};

type LanguagesListActions = {
  read: () => void;
};

const initialState: LanguagesListType = {
  data: [],
  status: 'idle',
};

const readLanguagesList = createAsyncThunk('languagesList/read', async () => SettingsService.getLanguages());

const languagesListSlice = createSlice({
  initialState,
  name: 'languagesList',
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(readLanguagesList.pending.type, (state: LanguagesListType): void => {
        state.status = 'loading';
      })
      .addCase(
        readLanguagesList.fulfilled.type,
        (state: LanguagesListType, action: PayloadAction<ILanguage[]>): void => {
          state.status = 'success';
          state.data = action.payload || initialState.data;
        }
      )
      .addCase(readLanguagesList.rejected.type, (state: LanguagesListType): void => {
        state.status = 'failed';
        state.data = initialState.data;
      }),
});

const useLanguagesList = (): [LanguagesListType, LanguagesListActions] => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.languagesList);

  const actions = {
    read: () => {
      dispatch(readLanguagesList());
    },
  };

  return [state, actions];
};

export { languagesListSlice };
export default useLanguagesList;
