export default {
  root: {
    pb: 2,
    top: 0,
    zIndex: 1200,
    position: 'sticky',
    bgcolor: 'background.default',
  },
  menu: {
    display: 'flex',
    alignItems: 'center',
    height: 38,
    borderRadius: '8px',
    bgcolor: 'secondary.light',
    boxShadow: '0px 14px 24px rgba(0, 0, 0, 0.09)',
    position: 'relative',
    li: {
      listStyle: 'none',
    },
  },
  menuItems: {
    pl: 2,
    pr: 2,
    overflow: 'hidden',
    flex: 1,
    width: '100%',
    position: 'relative',
    alignItems: 'center',
  },
  button: ({ selected }) => ({
    px: 2,
    height: 38,
    fontSize: 14,
    minWidth: 'fit-content',
    whiteSpace: 'nowrap',
    fontWeight: selected ? 700 : 600,
    color: selected ? 'primary.main' : 'text.primary',
  }),
  moreButton: {
    px: 2,
    height: 38,
    fontSize: 14,
    fontWeight: 600,
    color: 'text.primary',
    minWidth: 'fit-content',
  },
  actions: {
    py: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};
