import { IconButton } from '@mui/material';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const NextArrow = (props) => {
  const { onClick, hideIfNotSlide, sx } = props;

  if (!onClick && hideIfNotSlide) {
    return null;
  }

  return (
    <IconButton
      sx={{
        zIndex: 10,
        borderRadius: '10px',
        position: 'absolute',
        bottom: 8,
        right: 8,
        '&, &:hover': {
          color: 'text.primary',
          bgcolor: 'background.paper',
        },
        ...sx,
      }}
      onClick={onClick}
    >
      <NavigateNextIcon />
    </IconButton>
  );
};

export default NextArrow;
