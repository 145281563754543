enum Gender {
  MALE = 1,
  FEMALE,
}

export enum VerificationStatus {
  VERIFIED = 1,
  NOT_VERIFIED = 2,
  AWAITING_VERIFICATION = 3,
}

export enum RegistrationInitiator {
  AGENT = 'AGENT',
  AFFILIATE = 'AFFILIATE',
  ORGANIC = 'ORGANIC',
}

export interface IPlayer {
  id: number;
  city: string;
  username: string;
  meta: string;
  email: string;
  status: number;
  gender: Gender;
  country: string;
  website: number;
  registrationInitiator: RegistrationInitiator;
  referalId: number;
  lastName: string;
  birthDate: number; //unix
  createdAt: number; //unix
  firstName: string;
  nationalId: string;
  riskGroup: number;
  addressOne: string;
  addressTwo: string;
  emailVerified: boolean;
  phoneNumber: string;
  language: {
    flag: string;
    id: number;
    iso_1: string;
    iso_2: string;
    name: string;
    native: string;
  };
  currency: {
    id: number;
    name: string;
    code: string;
  };
  verificationStatus: VerificationStatus;
  wallet: {
    id: number;
    balance: number;
    createdAt: number;
  };
}

export interface ICredentials {
  email: string;
  password: string;
}

export interface ICreatUserParams extends ICredentials {
  birth: string;
  first_name: string;
  last_name: string;
}
