import { forwardRef, useState } from 'react';
import { FieldError } from 'react-hook-form';

import { TextField } from '@mui/material';
import useTranslates from 'utils/translate';

interface InputComponentProps {
  value?: string;
  error?: FieldError;
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
}

const InputComponent = forwardRef<HTMLInputElement, InputComponentProps>(
  ({ error, onFocus, onBlur, value, disabled, required, autoFocus, ...props }, ref): JSX.Element => {
    const { translate } = useTranslates();
    const [focused, setFocused] = useState<boolean>(!!autoFocus);

    const handleFocus = (event: React.FocusEvent<HTMLElement>): void => {
      onFocus(event);
      setFocused(true);
    };

    const handleBlur = (event: React.FocusEvent<HTMLElement>): void => {
      onBlur(event);
      setFocused(false);
    };

    return (
      <>
        <input
          ref={ref}
          value={value}
          disabled={disabled}
          onBlur={handleBlur}
          onFocus={handleFocus}
          autoFocus={autoFocus}
          {...props}
          style={{ direction: 'ltr' }}
        />
        <TextField
          fullWidth
          focused={focused}
          disabled={disabled}
          required={required}
          value={value}
          error={!!error?.message}
          placeholder={translate('phone_number')}
          helperText={error?.message && translate(error?.message)}
          sx={{
            '& input': {
              padding: '16.5px 14px 16.5px 50px !important',
              direction: 'ltr',
            },
          }}
        />
      </>
    );
  }
);

export default InputComponent;
