import { PaymentMethodsService } from 'api/paymentMethods';
import useMount from 'hooks/useMount';
import { useState } from 'react';
import { BKASH_B } from './constants';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useTranslates from 'utils/translate';
import FormInput from 'components/common/Form/Input';
import useToaster from 'hooks/useToaster';

const Deposit = ({ watch, control, paymentMethod, changeAmountFieldVisibility, changeActionButtonVisibility }) => {
  const amount = watch('amount');
  const accountNumber = watch('account_number');
  const { translate } = useTranslates();
  const notify = useToaster();
  const [loading, setLoading] = useState(false);

  useMount(() => {
    changeAmountFieldVisibility(true);
    changeActionButtonVisibility(false);
  });

  const needsAccountNumber = paymentMethod.method.name === BKASH_B;

  const onClick = async () => {
    try {
      setLoading(true);
      const result = await PaymentMethodsService.getPaymentMethodExtraInfo<[{ key: string; value: string }]>({
        amount,
        sourceId: paymentMethod.method.id,
        type: 'deposit',
        ...(!!accountNumber && {
          infoProperties: {
            account_number: accountNumber,
          },
        }),
      });
      if (result[0]) {
        window.location.href = result[0].value;
      } else {
        notify({
          message: translate('payment_invalid_credentials'),
          type: 'error',
        });
        setLoading(false);
      }
    } catch (error) {
      notify({
        message: translate((error as Error).toString()),
        type: 'error',
      });
      setLoading(false);
    }
  };

  const areAllFieldsFilled = Boolean(amount) && (!needsAccountNumber || Boolean(accountNumber));

  return (
    <>
      {needsAccountNumber && (
        <Grid item xs={24}>
          <FormInput label={translate('account_number')} name="account_number" required control={control} />
        </Grid>
      )}
      <Grid item xs={12}>
        <LoadingButton
          disabled={!areAllFieldsFilled}
          variant="contained"
          color="success"
          loading={loading}
          onClick={onClick}
          sx={{ mt: 3 }}
        >
          {translate('submit_deposit')}
        </LoadingButton>
      </Grid>
    </>
  );
};

export default Deposit;
